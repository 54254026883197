/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { AgentFeedDto } from '../models/agent-feed-dto';
import { getAgentFeed } from '../fn/agent-feed/get-agent-feed';
import { GetAgentFeed$Params } from '../fn/agent-feed/get-agent-feed';
import { updateAgentFeed } from '../fn/agent-feed/update-agent-feed';
import { UpdateAgentFeed$Params } from '../fn/agent-feed/update-agent-feed';

@Injectable({ providedIn: 'root' })
export class AgentFeedMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateAgentFeed()` */
  static readonly UpdateAgentFeedPath = '/agent/Feed';

  /**
   * Update agent Feed properties.
   *
   * Permission: 'agents-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAgentFeed()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAgentFeed$Response(params: UpdateAgentFeed$Params, context?: HttpContext): Observable<StrictHttpResponse<AgentFeedDto>> {
    return updateAgentFeed(this.http, this.rootUrl, params, context);
  }

  /**
   * Update agent Feed properties.
   *
   * Permission: 'agents-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAgentFeed$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAgentFeed(params: UpdateAgentFeed$Params, context?: HttpContext): Observable<AgentFeedDto> {
    return this.updateAgentFeed$Response(params, context).pipe(
      map((r: StrictHttpResponse<AgentFeedDto>): AgentFeedDto => r.body)
    );
  }

  /** Path part for operation `getAgentFeed()` */
  static readonly GetAgentFeedPath = '/agent/Feed/{agentId}';

  /**
   * Get agent Feed properties.
   *
   * Permission: 'agents-info'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAgentFeed()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgentFeed$Response(params: GetAgentFeed$Params, context?: HttpContext): Observable<StrictHttpResponse<AgentFeedDto>> {
    return getAgentFeed(this.http, this.rootUrl, params, context);
  }

  /**
   * Get agent Feed properties.
   *
   * Permission: 'agents-info'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAgentFeed$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgentFeed(params: GetAgentFeed$Params, context?: HttpContext): Observable<AgentFeedDto> {
    return this.getAgentFeed$Response(params, context).pipe(
      map((r: StrictHttpResponse<AgentFeedDto>): AgentFeedDto => r.body)
    );
  }

}
