import { MessageType, WebSocketAsyncMessage } from './web-socket-async-message'

export class WebSocketAsyncMessageBuilder {
    private messageType: MessageType
    private messageId: number
    private messageName: string
    private headers: Map<string, string>
    private payload?: any

    public static builder(): WebSocketAsyncMessageBuilder {
        return new WebSocketAsyncMessageBuilder()
    }

    public static requestBuilder(messageName: string, payload: any): WebSocketAsyncMessageBuilder {
        return WebSocketAsyncMessageBuilder.builder()
            .setMessageType(MessageType.request)
            .setMessageName(messageName)
            .setPayload(payload)
    }

    public setMessageType(messageType: MessageType): WebSocketAsyncMessageBuilder {
        this.messageType = messageType
        return this
    }

    public setMessageId(messageId: number): WebSocketAsyncMessageBuilder {
        this.messageId = messageId
        return this
    }

    public setMessageName(messageName: string): WebSocketAsyncMessageBuilder {
        this.messageName = messageName
        return this
    }

    public setHeaders(headers: Map<string, string>): WebSocketAsyncMessageBuilder {
        this.headers = headers
        return this
    }

    public setHeaderValue(name: string, value: string): WebSocketAsyncMessageBuilder {
        this.checkCreateHeaders()
        this.headers.set(name, value)
        return this
    }

    public setPayload(payload: any): WebSocketAsyncMessageBuilder {
        this.payload = payload
        return this
    }

    public build(): WebSocketAsyncMessage {
        return {
            messageType: this.messageType,
            messageName: this.messageName,
            messageId: this.messageId,
            headers: this.headers ? this.headers : new Map<string, string>(),
            payload: this.payload
        }
    }

    private checkCreateHeaders() {
        if (this.headers == null) this.headers = new Map()
    }
}
