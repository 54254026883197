import { Observable, of, switchMap, take } from 'rxjs'
import { inject, Injectable } from '@angular/core'
import { CanDeactivateFn } from '@angular/router'
import { ConfirmationService } from 'primeng/api'
import { TranslocoService } from '@ngneat/transloco'

export interface DirtyComponent {
    isDirty$: Observable<boolean>
}

@Injectable({ providedIn: 'root' })
export class DirtyCheckGuard {
    constructor(
        private confirmationService: ConfirmationService,
        private transloco: TranslocoService
    ) {}

    canDeactivate(component: DirtyComponent): Observable<boolean> {
        return this.canProceed(component.isDirty$)
    }

    canProceed(isDirty$: Observable<boolean>): Observable<boolean> {
        return isDirty$.pipe(
            switchMap(dirty => {
                if (dirty === false) {
                    return of(true)
                }
                return new Observable<boolean>(subscriber => {
                    this.confirmationService.confirm({
                        header: this.transloco.translate('UNSAVED_CHANGES'),
                        message: this.transloco.translate('UNSAVED_CHANGES_QUERY'),
                        icon: 'pi pi-exclamation-triangle',
                        closeOnEscape: true,
                        accept: () => subscriber.next(true),
                        reject: () => subscriber.next(false)
                    })
                })
            }),
            take(1)
        )
    }
}

export const dirtyCheckGuard: CanDeactivateFn<DirtyComponent> = (component: DirtyComponent) => {
    return inject(DirtyCheckGuard).canDeactivate(component)
}
