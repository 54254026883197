/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { BofTmplOddsFactorDto } from '../../models/bof-tmpl-odds-factor-dto';

export interface UpdateOddsFactorTemplate$Params {
      body: BofTmplOddsFactorDto
}

export function updateOddsFactorTemplate(http: HttpClient, rootUrl: string, params: UpdateOddsFactorTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<BofTmplOddsFactorDto>> {
  const rb = new RequestBuilder(rootUrl, updateOddsFactorTemplate.PATH, 'put');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<BofTmplOddsFactorDto>;
    })
  );
}

updateOddsFactorTemplate.PATH = '/bof-tmpl/odds-factor';
