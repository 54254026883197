<!--<div class='layout-content'>-->
<!--Za debug boja pozadine za root div stranice, zajedno sa paddingom: surface-100 p-2-->
<div class='flex flex-column layout-content align-items-stretch xl:h-screen lg:h-screen md:h-auto sm:h-auto'>

    <app-navigation-bar></app-navigation-bar>


    <router-outlet></router-outlet>


    <p-toast [preventOpenDuplicates]='true'></p-toast>
    <p-confirmDialog header='Confirmation' icon='fa fa-question-circle' [style]="{ width: '70%' }" [baseZIndex]='10000'
                     #cd>
        <p-footer>
            <button type='button' class='green' pButton (click)='cd.accept()'
                    icon='pi pi-check fa-check'>{{ 'YES' | transloco }}
            </button>
            <button type='button' class='red' pButton (click)='cd.reject()'
                    icon='pi pi-times fa-close'>{{ 'NO' | transloco }}
            </button>
        </p-footer>
    </p-confirmDialog>
    <!--    <div class='flex-grow-0  bg-blue-500 p-2'>status bar...</div>-->
</div>
