import { Component } from '@angular/core'
import { TranslocoService } from '@ngneat/transloco'
import { MenuItem } from 'primeng/api'
import { LanguageService } from '../../services/language.service'

@Component({
    selector: 'app-select-lang',
    templateUrl: './select-lang.component.html',
    styleUrls: ['./select-lang.component.scss']
})
export class SelectLangComponent {
    languageOptions: MenuItem[]

    constructor(
        public languageService: LanguageService,
        private transloco: TranslocoService
    ) {
        this.languageOptions = transloco.getAvailableLangs().map(l => ({
            label: l.label,
            visible: true,
            command: () => this.languageService.setLang(l.id)
        }))
    }
}
