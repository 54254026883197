import { Component, OnInit } from '@angular/core'
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms'
import { TranslocoService } from '@ngneat/transloco'
import { MessageService } from 'primeng/api'

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { forkJoin } from 'rxjs'
import { AgentMwApiService, SbmediaImagesMwApiService, SbmediaSettingsMwApiService } from 'src/app/api/mw-api/MwApiServices'
import { ApiErrorService } from 'src/app/services/api-error.service'
import { DropdownItem, DropdownItemUtils } from 'src/app/utils/dropdown-utils'
import { EditAction } from 'src/app/utils/edit-action'

@Component({
    selector: 'app-page-add-or-edit',
    templateUrl: './page-add-or-edit.component.html',
    styleUrls: ['./page-add-or-edit.component.scss']
})
export class PageAddOrEditComponent implements OnInit {
    numberRegEx = /\-?\d*\.?\d{1,2}/
    loading = false
    operations: DropdownItem[] = []
    pageTypes: DropdownItem[] = []
    images: DropdownItem[] = []
    imagesCopy = null
    // mislim da je ovo mozda nepotrebno ali zbog vezbe i simulacije situacije
    // kada kontrola treba da ucita nesto kada se nesto drugo na formi promeni
    imagesLoading = false

    pageDialogForm: UntypedFormGroup = null

    constructor(
        private sbmediaSettingsService: SbmediaSettingsMwApiService,
        private sbmediaImagesApiService: SbmediaImagesMwApiService,
        private apiErrorService: ApiErrorService,
        private transloco: TranslocoService,
        private agentService: AgentMwApiService,
        public dialogRef: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private messageService: MessageService
    ) {}

    ngOnInit(): void {
        this.getInitialFormData()
    }

    getInitialFormData() {
        forkJoin({
            operations: this.agentService.getOperations(),
            pageTypes: this.sbmediaSettingsService.getPageTypes(),
            images: this.sbmediaImagesApiService.getImagesList()
        }).subscribe(({ operations, pageTypes, images }) => {
            this.imagesCopy = images
            const imagesForOperation = images.filter(img => img.operationId === this.config?.data?.page?.operationId)
            this.images = DropdownItemUtils.addItems(imagesForOperation, 'imageId')
            console.log(this.images)

            this.pageTypes = DropdownItemUtils.addItemsById(pageTypes)
            this.operations = DropdownItemUtils.addItems(operations)
            if (this.config.data.actionType === EditAction.add) {
                this.pageDialogForm = new UntypedFormGroup(
                    {
                        image: new UntypedFormControl(this.images[0]?.id),
                        pageType: new UntypedFormControl(this.pageTypes[0]?.id, [Validators.required]),
                        infoText: new UntypedFormControl(null),
                        subPage: new UntypedFormControl(null, [
                            Validators.required,
                            Validators.pattern(this.numberRegEx),
                            this.customSubPageValidator()
                        ]),
                        active: new UntypedFormControl(true),
                        operationId: new UntypedFormControl(this.operations[0]?.id)
                    },
                    { updateOn: 'change' }
                )
            } else {
                const imageForPage = images.find(i => i?.imageId === this.config?.data?.page?.imageId)
                this.pageDialogForm = new UntypedFormGroup(
                    {
                        image: new UntypedFormControl(imageForPage?.imageId),
                        pageType: new UntypedFormControl(this.config?.data?.page?.pageType, [Validators.required]),
                        infoText: new UntypedFormControl(this.config?.data?.page?.infoText),
                        subPage: new UntypedFormControl(this.config?.data?.page?.subPage, [
                            Validators.required,
                            Validators.pattern(this.numberRegEx)
                        ]),
                        active: new UntypedFormControl(this.config?.data?.page?.active),
                        operationId: new UntypedFormControl(this.config?.data?.page?.operationId)
                    },
                    { updateOn: 'change' }
                )
            }
        })
    }

    customSubPageValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.parent || !control.parent.get('pageType') || !control.parent.get('pageType')?.value) {
                return null
            }

            if (control.value === null) {
                return { invalidSupPage: true, errorMsg: 'ERROR_REQUIRED' }
            }

            const subPageNum = parseInt(control?.value, 10)
            const pageType = control.parent?.get('pageType')?.value
            const existingOperation = this.config?.data?.pageSettings.find(
                f => f?.operationId === control?.parent?.get('operationId')?.value
            )

            // Proveravamo da li vec postoji stranica koju korisnik pokusava da doda
            if (existingOperation && existingOperation?.pages?.some(f => f?.subPage === subPageNum && f?.pageType === pageType)) {
                return { invalidSupPage: true, errorMsg: 'ERROR_SAME_PAGE' }
            }

            // Proveravamo da li vec postoji master stranica
            const hasMainPage = existingOperation?.pages?.some(f => f?.subPage === 0 && f?.pageType === pageType)

            // Ako nemamo glavnu stranicu, a korisnik hoce da doda sub page, odbijamo
            if (!hasMainPage && subPageNum !== 0) {
                return { invalidSupPage: true, errorMsg: 'ERROR_MAIN_PAGE' }
            }

            return null
        }
    }

    getPageTypes() {
        this.sbmediaSettingsService.getPageTypes().subscribe(
            data => {
                //Drop down initial page type selection
                this.pageTypes = DropdownItemUtils.addItemsById(data)
                // this.pageDialogForm
            },
            err => {
                this.loading = false
                this.messageService.add({
                    severity: 'error',
                    closable: true,
                    summary: this.transloco.translate('ERROR'),
                    detail: this.apiErrorService.parse(err).displayError
                })
            }
        )
    }

    onSubmitForm() {
        this.loading = true
        const params = {
            body: {
                operationId: this.pageDialogForm?.value?.operationId,
                imageId: this.pageDialogForm?.value.image,
                infoText: this.pageDialogForm?.value?.infoText,
                active: this.pageDialogForm?.value?.active,
                pageType: this.pageDialogForm?.value?.pageType,
                subPage: this.pageDialogForm?.value?.subPage
            }
        }

        if (this.config.data.actionType === EditAction.edit) {
            this.sbmediaSettingsService.updatePageSettings(params).subscribe(
                submittedPage => {
                    this.loading = false
                    this.dialogRef.close(submittedPage)
                },
                err => {
                    this.loading = false
                    this.messageService.add({
                        severity: 'error',
                        sticky: true,
                        closable: true,
                        life: 10000,
                        summary: this.transloco.translate('ERROR'),
                        detail: this.apiErrorService.parse(err).displayError
                    })
                }
            )
        } else {
            this.sbmediaSettingsService.createPageSettings(params).subscribe(
                newPage => {
                    this.dialogRef.close(newPage)
                },
                err => {
                    this.loading = false
                    this.messageService.add({
                        severity: 'error',
                        sticky: true,
                        closable: true,
                        life: 10000,
                        summary: this.transloco.translate('ERROR'),
                        detail: this.apiErrorService.parse(err).displayError
                    })
                }
            )
        }
    }

    imagesChangeOnOperationChange(images: any) {
        if (images) {
            this.imagesLoading = true
            const imagesForOperation = images?.filter(i => i?.operationId === this.pageDialogForm?.get('operationId')?.value)
            this.images = DropdownItemUtils.addItems(imagesForOperation, 'imageId')
            this.pageDialogForm?.get('image').patchValue(this?.images[0]?.id)
            this.imagesLoading = false
        }
    }

    onCancelClick() {
        this.dialogRef.close()
    }

    // treba razmotriti da li ovo ubaciti u custom validator jer je ovo hack koji sa tajmerom osvezava validnost submit dugmeta
    private touchfield(controlName: string) {
        const field = this.pageDialogForm.get(controlName)

        field.markAsUntouched()
        field.updateValueAndValidity()
        this.pageDialogForm.updateValueAndValidity()
        field.markAsTouched()
    }
}
