/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { BofTmplsConfigDto } from '../models/bof-tmpls-config-dto';
import { getPlayerConfig } from '../fn/player-bof-tmpls-config/get-player-config';
import { GetPlayerConfig$Params } from '../fn/player-bof-tmpls-config/get-player-config';
import { updatePlayerConfig } from '../fn/player-bof-tmpls-config/update-player-config';
import { UpdatePlayerConfig$Params } from '../fn/player-bof-tmpls-config/update-player-config';

@Injectable({ providedIn: 'root' })
export class PlayerBofTmplsConfigMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getPlayerConfig()` */
  static readonly GetPlayerConfigPath = '/player/bof-tmpls/config/{playerId}';

  /**
   * Get bof template config.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlayerConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlayerConfig$Response(params: GetPlayerConfig$Params, context?: HttpContext): Observable<StrictHttpResponse<BofTmplsConfigDto>> {
    return getPlayerConfig(this.http, this.rootUrl, params, context);
  }

  /**
   * Get bof template config.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlayerConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlayerConfig(params: GetPlayerConfig$Params, context?: HttpContext): Observable<BofTmplsConfigDto> {
    return this.getPlayerConfig$Response(params, context).pipe(
      map((r: StrictHttpResponse<BofTmplsConfigDto>): BofTmplsConfigDto => r.body)
    );
  }

  /** Path part for operation `updatePlayerConfig()` */
  static readonly UpdatePlayerConfigPath = '/player/bof-tmpls/config/{playerId}';

  /**
   * Update player bof template configs.
   *
   * Permission: 'bof-tmpl-sorting-edit-config'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePlayerConfig()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePlayerConfig$Response(params: UpdatePlayerConfig$Params, context?: HttpContext): Observable<StrictHttpResponse<BofTmplsConfigDto>> {
    return updatePlayerConfig(this.http, this.rootUrl, params, context);
  }

  /**
   * Update player bof template configs.
   *
   * Permission: 'bof-tmpl-sorting-edit-config'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePlayerConfig$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePlayerConfig(params: UpdatePlayerConfig$Params, context?: HttpContext): Observable<BofTmplsConfigDto> {
    return this.updatePlayerConfig$Response(params, context).pipe(
      map((r: StrictHttpResponse<BofTmplsConfigDto>): BofTmplsConfigDto => r.body)
    );
  }

}
