/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PageDataUserDto } from '../../models/page-data-user-dto';
import { UserType } from '../../models/user-type';

export interface GetUsers$Params {
  agentId: number;
  userType: UserType;
  pageNumber: number;
  pageSize: number;
  usernamePattern?: string;
}

export function getUsers(http: HttpClient, rootUrl: string, params: GetUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<PageDataUserDto>> {
  const rb = new RequestBuilder(rootUrl, getUsers.PATH, 'get');
  if (params) {
    rb.path('agentId', params.agentId, {});
    rb.path('userType', params.userType, {});
    rb.path('pageNumber', params.pageNumber, {});
    rb.path('pageSize', params.pageSize, {});
    rb.query('usernamePattern', params.usernamePattern, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<PageDataUserDto>;
    })
  );
}

getUsers.PATH = '/user/all/{agentId}/{userType}/{pageNumber}/{pageSize}';
