/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { AgentPrintoutDto } from '../models/agent-printout-dto';
import { getAgentPrintout } from '../fn/agent-printout/get-agent-printout';
import { GetAgentPrintout$Params } from '../fn/agent-printout/get-agent-printout';
import { updateAgentPrintout } from '../fn/agent-printout/update-agent-printout';
import { UpdateAgentPrintout$Params } from '../fn/agent-printout/update-agent-printout';

@Injectable({ providedIn: 'root' })
export class AgentPrintoutMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateAgentPrintout()` */
  static readonly UpdateAgentPrintoutPath = '/agent/printout';

  /**
   * Update agent printout properties.
   *
   * Permission: 'agents-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAgentPrintout()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAgentPrintout$Response(params: UpdateAgentPrintout$Params, context?: HttpContext): Observable<StrictHttpResponse<AgentPrintoutDto>> {
    return updateAgentPrintout(this.http, this.rootUrl, params, context);
  }

  /**
   * Update agent printout properties.
   *
   * Permission: 'agents-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAgentPrintout$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAgentPrintout(params: UpdateAgentPrintout$Params, context?: HttpContext): Observable<AgentPrintoutDto> {
    return this.updateAgentPrintout$Response(params, context).pipe(
      map((r: StrictHttpResponse<AgentPrintoutDto>): AgentPrintoutDto => r.body)
    );
  }

  /** Path part for operation `getAgentPrintout()` */
  static readonly GetAgentPrintoutPath = '/agent/printout/{agentId}';

  /**
   * Get agent printout properties.
   *
   * Permission: 'agents-info'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAgentPrintout()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgentPrintout$Response(params: GetAgentPrintout$Params, context?: HttpContext): Observable<StrictHttpResponse<AgentPrintoutDto>> {
    return getAgentPrintout(this.http, this.rootUrl, params, context);
  }

  /**
   * Get agent printout properties.
   *
   * Permission: 'agents-info'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAgentPrintout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgentPrintout(params: GetAgentPrintout$Params, context?: HttpContext): Observable<AgentPrintoutDto> {
    return this.getAgentPrintout$Response(params, context).pipe(
      map((r: StrictHttpResponse<AgentPrintoutDto>): AgentPrintoutDto => r.body)
    );
  }

}
