<p-button
    type='button'
    icon='pi pi-language'

    [style]="{ width: '100%', overflow: 'visible' }"
    label='{{ languageService.activeLanguage | transloco }}'
    (click)='menu.toggle($event)'
></p-button>

<p-menu #menu [popup]='true' [model]='languageOptions'></p-menu>

<!--<button type="button" pButton icon="pi pi-globe" class="mobile pl-1" (click)="menu.toggle($event)"></button>-->
<!--<p-menu #menu [popup]='true' [model]='languageOptions'></p-menu>-->
