<div class='flex justify-content-center align-items-center xl:h-screen'>
    <p-card class='block w-30rem shadow-8' #loginCard>
        <p-blockUI [blocked]='loading' [target]='loginCard'>
            <p-progressSpinner styleClass='w-3rem h-3rem m-2' strokeWidth='5'
                               animationDuration='1s'></p-progressSpinner>
        </p-blockUI>
        <form *ngIf='!forgotPasswordMode' [formGroup]='loginForm' (ngSubmit)='login()'>

            <div class='text-center mb-5'>
                <!--        <img src='assets/images/blocks/logos/hyper.svg' alt='Image' height='50' class='mb-3'>-->
                <div class='text-900 text-3xl font-medium mb-3'>{{ "WELCOME_BACK" | transloco }}</div>
                <!--        <span class='text-600 font-medium line-height-3'>Don't have an account?</span>-->
                <!--        <a class='font-medium no-underline ml-2 text-blue-500 cursor-pointer'>Create today!</a>-->
            </div>

            <label for='username' class='block text-900 font-medium mb-2'>{{ "USERNAME" | transloco }}</label>
            <input formControlName='username' id='username' type='text'
                   autofocus
                   placeholder='{{ "USERNAME" | transloco }}'
                   pInputText class='w-full mb-3'>

            <label for='password' class='block text-900 font-medium mb-2'>{{ "PASSWORD" | transloco }}</label>
            <input formControlName='password' id='password' type='password'
                   placeholder='{{ "PASSWORD" | transloco }}'
                   pInputText class='w-full mb-3'>

            <div class='flex align-items-center justify-content-between mb-6'>
                <a tabindex='1'
                   (keydown.enter)='switchToForgotPassword()'
                   (click)='switchToForgotPassword()'
                   class='font-medium no-underline text-blue-500 text-right cursor-pointer'>{{ 'FORGOT_PASSWORD' | transloco }}</a>
            </div>

            <div class='flex flex-column'>
                <p-button type='submit' pRipple label='{{ "SIGN_IN" | transloco }}' iconPos='left'
                          icon='pi pi-user'
                          [style]="{ width: '100%', overflow: 'visible' }"
                          class='pb-1'
                          [disabled]='!loginForm.valid && loginAttempt===0'></p-button>
                <p-divider></p-divider>
                <app-select-lang></app-select-lang>
            </div>
        </form>

        <form *ngIf='forgotPasswordMode' [formGroup]='forgotPasswordForm' (ngSubmit)='forgotPassword()'>
            <div class='hidden-panel-container'>
                <div class='p-field w-full pr-2 pb-1'>
                    <label for='username'
                           class='block text-900 font-medium mb-2'>{{ "send_password_reset" | transloco }}</label>
                    <input pInputText type='text' formControlName='username' class='w-full mb-3'
                           placeholder="{{ 'EMAIL' | transloco }}" />
                </div>
                <div class='flex flex-column'>
                    <p-button type='submit' pRipple label='{{ "SEND" | transloco }}' iconPos='left'
                              icon='pi pi-user'
                              [style]="{ width: '100%', overflow: 'visible' }"
                              class='pb-1'
                              [disabled]='!forgotPasswordForm.valid'
                    ></p-button>
                </div>
            </div>
        </form>

    </p-card>
</div>
