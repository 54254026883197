/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { addOfferTemplate } from '../fn/bof-tmpl-offer/add-offer-template';
import { AddOfferTemplate$Params } from '../fn/bof-tmpl-offer/add-offer-template';
import { BofTmplDto } from '../models/bof-tmpl-dto';
import { deleteOfferTemplate } from '../fn/bof-tmpl-offer/delete-offer-template';
import { DeleteOfferTemplate$Params } from '../fn/bof-tmpl-offer/delete-offer-template';
import { getOfferTemplates } from '../fn/bof-tmpl-offer/get-offer-templates';
import { GetOfferTemplates$Params } from '../fn/bof-tmpl-offer/get-offer-templates';
import { renameOfferTemplate } from '../fn/bof-tmpl-offer/rename-offer-template';
import { RenameOfferTemplate$Params } from '../fn/bof-tmpl-offer/rename-offer-template';

@Injectable({ providedIn: 'root' })
export class BofTmplOfferMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `renameOfferTemplate()` */
  static readonly RenameOfferTemplatePath = '/bof-tmpl/offer';

  /**
   * Rename template.
   *
   * Permission: 'bof-tmpl-offer-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `renameOfferTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  renameOfferTemplate$Response(params: RenameOfferTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<BofTmplDto>> {
    return renameOfferTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Rename template.
   *
   * Permission: 'bof-tmpl-offer-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `renameOfferTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  renameOfferTemplate(params: RenameOfferTemplate$Params, context?: HttpContext): Observable<BofTmplDto> {
    return this.renameOfferTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<BofTmplDto>): BofTmplDto => r.body)
    );
  }

  /** Path part for operation `addOfferTemplate()` */
  static readonly AddOfferTemplatePath = '/bof-tmpl/offer';

  /**
   * Add new template.
   *
   * Permission: 'bof-tmpl-offer-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addOfferTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addOfferTemplate$Response(params: AddOfferTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<BofTmplDto>> {
    return addOfferTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Add new template.
   *
   * Permission: 'bof-tmpl-offer-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addOfferTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addOfferTemplate(params: AddOfferTemplate$Params, context?: HttpContext): Observable<BofTmplDto> {
    return this.addOfferTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<BofTmplDto>): BofTmplDto => r.body)
    );
  }

  /** Path part for operation `getOfferTemplates()` */
  static readonly GetOfferTemplatesPath = '/bof-tmpl/offer/all';

  /**
   * Get list of all templates.
   *
   * Permission: 'bof-tmpl-offer-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOfferTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOfferTemplates$Response(params?: GetOfferTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BofTmplDto>>> {
    return getOfferTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of all templates.
   *
   * Permission: 'bof-tmpl-offer-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOfferTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOfferTemplates(params?: GetOfferTemplates$Params, context?: HttpContext): Observable<Array<BofTmplDto>> {
    return this.getOfferTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BofTmplDto>>): Array<BofTmplDto> => r.body)
    );
  }

  /** Path part for operation `deleteOfferTemplate()` */
  static readonly DeleteOfferTemplatePath = '/bof-tmpl/offer/{id}';

  /**
   * Delete template.
   *
   * Permission: 'bof-tmpl-offer-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteOfferTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOfferTemplate$Response(params: DeleteOfferTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteOfferTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete template.
   *
   * Permission: 'bof-tmpl-offer-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteOfferTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOfferTemplate(params: DeleteOfferTemplate$Params, context?: HttpContext): Observable<void> {
    return this.deleteOfferTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
