/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PageDataExtMatchInfo } from '../../models/page-data-ext-match-info';

export interface GetExtMatches$Params {
  pageNumber: number;
  pageSize: number;
  extFeedId?: number;
  sportId?: number;
  fromDate?: string;
  toDate?: string;
  filterByText?: string;
  filterByLink?: boolean;
  liveCoverage?: boolean;
  live?: boolean;
}

export function getExtMatches(http: HttpClient, rootUrl: string, params: GetExtMatches$Params, context?: HttpContext): Observable<StrictHttpResponse<PageDataExtMatchInfo>> {
  const rb = new RequestBuilder(rootUrl, getExtMatches.PATH, 'get');
  if (params) {
    rb.query('pageNumber', params.pageNumber, {});
    rb.query('pageSize', params.pageSize, {});
    rb.query('extFeedId', params.extFeedId, {});
    rb.query('sportId', params.sportId, {});
    rb.query('fromDate', params.fromDate, {});
    rb.query('toDate', params.toDate, {});
    rb.query('filterByText', params.filterByText, {});
    rb.query('filterByLink', params.filterByLink, {});
    rb.query('liveCoverage', params.liveCoverage, {});
    rb.query('live', params.live, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<PageDataExtMatchInfo>;
    })
  );
}

getExtMatches.PATH = '/ext-feed/matches';
