import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { Injectable } from '@angular/core'
import { TimeConstants } from './time-constants'
import { ConnectionStatus } from './connection-status'

const RECONNECT_TIMEOUT = TimeConstants.SECOND * 3
const HEARTBEAT_INTERVAL = TimeConstants.SECOND * 15

/**
 * WebSocket client service
 * - Uses native browser WebSocket
 * - Automatically handles reconnects
 * - Has connected/disconnected subjects for reactive use
 */
@Injectable({ providedIn: 'root' })
export class SocketClient {
    private ws: WebSocket
    private heartbeatTimer: ReturnType<typeof setTimeout>
    private reconnectTimer: ReturnType<typeof setTimeout>
    private onConnect$: Subject<WebSocket> = new Subject()
    private onDisconnect$: Subject<void> = new Subject()
    private onConnectionStatus$: BehaviorSubject<ConnectionStatus> = new BehaviorSubject(ConnectionStatus.closed)
    private onMessage$: Subject<ConnectionStatus> = new Subject()

    private connected = false

    public readonly onConnect: Observable<WebSocket> = this.onConnect$.asObservable()
    public readonly onDisconnect: Observable<void> = this.onDisconnect$.asObservable()
    public readonly onConnectionStatus: Observable<ConnectionStatus> = this.onConnectionStatus$.asObservable()

    public readonly onMessage: Observable<any> = this.onMessage$.asObservable()

    constructor() {
        this.connect()
    }

    public isConnected(): boolean {
        return this.connected
    }

    public getWebSocket(): WebSocket {
        return this.ws
    }

    public connect() {
        try {
            this.ws = new WebSocket('/ws')
            this.ws.onopen = this.onSocketConnected.bind(this)

            //this.ws.on('ping', this.onHeartbeat.bind(this))
            this.ws.onclose = this.onSocketDisconnected.bind(this)
            this.ws.onerror = this.onSocketError.bind(this)
            this.ws.onmessage = this.onMessageReceived.bind(this)

            //this.messenger.onRequest('ping', this.onHeartbeat.bind(this))
            //this.messenger.setSocket(this.ws)
        } catch (e) {
            console.warn('Error while connecting to host', e)
            this.createReconnectTimer()
        }
    }

    public disconnect() {
        if (this.ws.readyState !== WebSocket.OPEN) {
            this.ws.close()
        }
    }

    /**
     * Wrapper around WebSocket send. It assures that ws instance is defined and
     * socket is open
     */
    public send(message: string) {
        if (this.ws?.readyState === WebSocket.OPEN) {
            this.ws.send(message)
        }
    }

    private async onSocketConnected(): Promise<void> {
        console.debug('Socket connected')
        this.clearReconnectTimer()
        // this.createHeartbeatTimer()
        this.connected = true
        this.onConnect$.next(this.ws)
        this.onConnectionStatus$.next(ConnectionStatus.open)
    }

    private onSocketDisconnected(event: CloseEvent) {
        console.debug(`Socket closed: ${JSON.stringify(event)}`)
        this.ws = undefined
        this.connected = false
        if (!this.reconnectTimer) this.createReconnectTimer()
        this.clearHeartBeatTimer()
        this.onDisconnect$.next()
        this.onConnectionStatus$.next(ConnectionStatus.closed)
    }

    private onMessageReceived(messageEvent: MessageEvent) {
        this.onMessage$.next(messageEvent.data)
    }

    /**
     * When we receive ping from the server, reset timer and register new one.
     * When we don't receive ping from server for more than 30sec connection will be
     * terminated manually
     * @private
     */
    private onHeartbeat() {
        // Use `WebSocket#terminate()`, which immediately destroys the connection,
        // instead of `WebSocket#close()`, which waits for the close timer.
        // Delay should be equal to the interval at which your server
        // sends out pings plus a conservative assumption of the latency.
        this.clearHeartBeatTimer()
        this.createHeartbeatTimer()
    }

    private onSocketError(error: ErrorEvent) {
        console.warn('Socket error', error?.message)
        if (this.ws?.readyState === WebSocket.CLOSED) {
            this.createReconnectTimer()
        }
    }

    private checkConnect() {
        this.clearReconnectTimer()
        if (this.ws?.readyState !== WebSocket.OPEN) {
            this.connect()
        } else {
            console.debug(`Check connect, reconnect timer created, readyState: ${this.ws.readyState}`)
            this.createReconnectTimer()
        }
    }

    private createReconnectTimer() {
        this.clearReconnectTimer()
        this.reconnectTimer = setTimeout(this.checkConnect.bind(this), RECONNECT_TIMEOUT)
    }

    private clearReconnectTimer() {
        if (this.reconnectTimer) {
            clearTimeout(this.reconnectTimer)
            this.reconnectTimer = null
        }
    }

    private createHeartbeatTimer() {
        this.heartbeatTimer = setTimeout(() => {
            const error = 'Socket terminating due to ping timeout'
            if (this.ws.readyState === WebSocket.OPEN) {
                // 1002 indicates that an endpoint is terminating the connection due to a protocol error.
                this.ws.close(1002, error)
            }
            console.info(error)
            this.createReconnectTimer()
        }, HEARTBEAT_INTERVAL)
    }

    private clearHeartBeatTimer() {
        if (this.heartbeatTimer) {
            clearTimeout(this.heartbeatTimer)
            this.heartbeatTimer = null
        }
    }
}
