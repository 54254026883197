import { Injectable } from '@angular/core'
import { RouterStateSnapshot, TitleStrategy } from '@angular/router'
import { Title } from '@angular/platform-browser'
import { TranslocoService } from '@ngneat/transloco'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy({ checkProperties: true })
@Injectable({
    providedIn: 'root'
})
export class CustomTitleStrategyService extends TitleStrategy {
    constructor(private title: Title, private transloco: TranslocoService) {
        super()
    }

    override updateTitle(snapshot: RouterStateSnapshot): void {
        this.transloco
            .selectTranslation()
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const title = this.buildTitle(snapshot)
                if (title) {
                    const translatedTitle = this.transloco.translate(title)
                    this.title.setTitle(translatedTitle)
                } else {
                    this.title.setTitle('SPORTAL_BACKOFFICE')
                }
            })
    }
}
