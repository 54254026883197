/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { getAll } from '../fn/odds-feed/get-all';
import { GetAll$Params } from '../fn/odds-feed/get-all';
import { OddsFeedDto } from '../models/odds-feed-dto';

@Injectable({ providedIn: 'root' })
export class OddsFeedMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAll()` */
  static readonly GetAllPath = '/odds-feed/getAll';

  /**
   * Get all odds feeds.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Response(params?: GetAll$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OddsFeedDto>>> {
    return getAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all odds feeds.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll(params?: GetAll$Params, context?: HttpContext): Observable<Array<OddsFeedDto>> {
    return this.getAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OddsFeedDto>>): Array<OddsFeedDto> => r.body)
    );
  }

}
