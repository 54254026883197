/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { getFullTree } from '../fn/fake-sports-tree/get-full-tree';
import { GetFullTree$Params } from '../fn/fake-sports-tree/get-full-tree';
import { getTopTournaments } from '../fn/fake-sports-tree/get-top-tournaments';
import { GetTopTournaments$Params } from '../fn/fake-sports-tree/get-top-tournaments';
import { SportsTreeDto } from '../models/sports-tree-dto';
import { TopTournamentsDto } from '../models/top-tournaments-dto';

@Injectable({ providedIn: 'root' })
export class FakeSportsTreeMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getFullTree()` */
  static readonly GetFullTreePath = '/sports-tree';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFullTree()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFullTree$Response(params?: GetFullTree$Params, context?: HttpContext): Observable<StrictHttpResponse<SportsTreeDto>> {
    return getFullTree(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFullTree$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFullTree(params?: GetFullTree$Params, context?: HttpContext): Observable<SportsTreeDto> {
    return this.getFullTree$Response(params, context).pipe(
      map((r: StrictHttpResponse<SportsTreeDto>): SportsTreeDto => r.body)
    );
  }

  /** Path part for operation `getTopTournaments()` */
  static readonly GetTopTournamentsPath = '/sports-tree/topTournaments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTopTournaments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTopTournaments$Response(params?: GetTopTournaments$Params, context?: HttpContext): Observable<StrictHttpResponse<TopTournamentsDto>> {
    return getTopTournaments(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTopTournaments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTopTournaments(params?: GetTopTournaments$Params, context?: HttpContext): Observable<TopTournamentsDto> {
    return this.getTopTournaments$Response(params, context).pipe(
      map((r: StrictHttpResponse<TopTournamentsDto>): TopTournamentsDto => r.body)
    );
  }

}
