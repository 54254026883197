/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { addMarketsTemplate } from '../fn/bof-tmpl-markets/add-markets-template';
import { AddMarketsTemplate$Params } from '../fn/bof-tmpl-markets/add-markets-template';
import { BofTmplDto } from '../models/bof-tmpl-dto';
import { deleteMarketsTemplate } from '../fn/bof-tmpl-markets/delete-markets-template';
import { DeleteMarketsTemplate$Params } from '../fn/bof-tmpl-markets/delete-markets-template';
import { getMarketsTemplates } from '../fn/bof-tmpl-markets/get-markets-templates';
import { GetMarketsTemplates$Params } from '../fn/bof-tmpl-markets/get-markets-templates';
import { renameMarketsTemplate } from '../fn/bof-tmpl-markets/rename-markets-template';
import { RenameMarketsTemplate$Params } from '../fn/bof-tmpl-markets/rename-markets-template';

@Injectable({ providedIn: 'root' })
export class BofTmplMarketsMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `renameMarketsTemplate()` */
  static readonly RenameMarketsTemplatePath = '/bof-tmpl/markets';

  /**
   * Rename template.
   *
   * Permission: 'bof-tmpl-markets-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `renameMarketsTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  renameMarketsTemplate$Response(params: RenameMarketsTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<BofTmplDto>> {
    return renameMarketsTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Rename template.
   *
   * Permission: 'bof-tmpl-markets-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `renameMarketsTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  renameMarketsTemplate(params: RenameMarketsTemplate$Params, context?: HttpContext): Observable<BofTmplDto> {
    return this.renameMarketsTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<BofTmplDto>): BofTmplDto => r.body)
    );
  }

  /** Path part for operation `addMarketsTemplate()` */
  static readonly AddMarketsTemplatePath = '/bof-tmpl/markets';

  /**
   * Add new template.
   *
   * Permission: 'bof-tmpl-markets-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addMarketsTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addMarketsTemplate$Response(params: AddMarketsTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<BofTmplDto>> {
    return addMarketsTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Add new template.
   *
   * Permission: 'bof-tmpl-markets-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addMarketsTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addMarketsTemplate(params: AddMarketsTemplate$Params, context?: HttpContext): Observable<BofTmplDto> {
    return this.addMarketsTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<BofTmplDto>): BofTmplDto => r.body)
    );
  }

  /** Path part for operation `getMarketsTemplates()` */
  static readonly GetMarketsTemplatesPath = '/bof-tmpl/markets/all';

  /**
   * Get list of all templates.
   *
   * Permission: 'bof-tmpl-markets-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMarketsTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketsTemplates$Response(params?: GetMarketsTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BofTmplDto>>> {
    return getMarketsTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of all templates.
   *
   * Permission: 'bof-tmpl-markets-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMarketsTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketsTemplates(params?: GetMarketsTemplates$Params, context?: HttpContext): Observable<Array<BofTmplDto>> {
    return this.getMarketsTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BofTmplDto>>): Array<BofTmplDto> => r.body)
    );
  }

  /** Path part for operation `deleteMarketsTemplate()` */
  static readonly DeleteMarketsTemplatePath = '/bof-tmpl/markets/{id}';

  /**
   * Delete template.
   *
   * Permission: 'bof-tmpl-markets-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMarketsTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMarketsTemplate$Response(params: DeleteMarketsTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteMarketsTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete template.
   *
   * Permission: 'bof-tmpl-markets-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteMarketsTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMarketsTemplate(params: DeleteMarketsTemplate$Params, context?: HttpContext): Observable<void> {
    return this.deleteMarketsTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
