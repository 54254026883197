/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { addOddsFactorTemplate } from '../fn/bof-tmpl-odds-factor/add-odds-factor-template';
import { AddOddsFactorTemplate$Params } from '../fn/bof-tmpl-odds-factor/add-odds-factor-template';
import { BofTmplOddsFactorDto } from '../models/bof-tmpl-odds-factor-dto';
import { deleteOddsFactorTemplate } from '../fn/bof-tmpl-odds-factor/delete-odds-factor-template';
import { DeleteOddsFactorTemplate$Params } from '../fn/bof-tmpl-odds-factor/delete-odds-factor-template';
import { getOddsFactorTemplates } from '../fn/bof-tmpl-odds-factor/get-odds-factor-templates';
import { GetOddsFactorTemplates$Params } from '../fn/bof-tmpl-odds-factor/get-odds-factor-templates';
import { updateOddsFactorTemplate } from '../fn/bof-tmpl-odds-factor/update-odds-factor-template';
import { UpdateOddsFactorTemplate$Params } from '../fn/bof-tmpl-odds-factor/update-odds-factor-template';

@Injectable({ providedIn: 'root' })
export class BofTmplOddsFactorMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateOddsFactorTemplate()` */
  static readonly UpdateOddsFactorTemplatePath = '/bof-tmpl/odds-factor';

  /**
   * Update template.
   *
   * Permission: 'bof-tmpl-odds-factor-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOddsFactorTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOddsFactorTemplate$Response(params: UpdateOddsFactorTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<BofTmplOddsFactorDto>> {
    return updateOddsFactorTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update template.
   *
   * Permission: 'bof-tmpl-odds-factor-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOddsFactorTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOddsFactorTemplate(params: UpdateOddsFactorTemplate$Params, context?: HttpContext): Observable<BofTmplOddsFactorDto> {
    return this.updateOddsFactorTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<BofTmplOddsFactorDto>): BofTmplOddsFactorDto => r.body)
    );
  }

  /** Path part for operation `addOddsFactorTemplate()` */
  static readonly AddOddsFactorTemplatePath = '/bof-tmpl/odds-factor';

  /**
   * Add new template.
   *
   * Permission: 'bof-tmpl-odds-factor-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addOddsFactorTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addOddsFactorTemplate$Response(params: AddOddsFactorTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<BofTmplOddsFactorDto>> {
    return addOddsFactorTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Add new template.
   *
   * Permission: 'bof-tmpl-odds-factor-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addOddsFactorTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addOddsFactorTemplate(params: AddOddsFactorTemplate$Params, context?: HttpContext): Observable<BofTmplOddsFactorDto> {
    return this.addOddsFactorTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<BofTmplOddsFactorDto>): BofTmplOddsFactorDto => r.body)
    );
  }

  /** Path part for operation `getOddsFactorTemplates()` */
  static readonly GetOddsFactorTemplatesPath = '/bof-tmpl/odds-factor/all';

  /**
   * Get list of all templates.
   *
   * Permission: 'bof-tmpl-odds-factor-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOddsFactorTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOddsFactorTemplates$Response(params?: GetOddsFactorTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BofTmplOddsFactorDto>>> {
    return getOddsFactorTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of all templates.
   *
   * Permission: 'bof-tmpl-odds-factor-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOddsFactorTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOddsFactorTemplates(params?: GetOddsFactorTemplates$Params, context?: HttpContext): Observable<Array<BofTmplOddsFactorDto>> {
    return this.getOddsFactorTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BofTmplOddsFactorDto>>): Array<BofTmplOddsFactorDto> => r.body)
    );
  }

  /** Path part for operation `deleteOddsFactorTemplate()` */
  static readonly DeleteOddsFactorTemplatePath = '/bof-tmpl/odds-factor/{id}';

  /**
   * Delete template.
   *
   * Permission: 'bof-tmpl-odds-factor-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteOddsFactorTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOddsFactorTemplate$Response(params: DeleteOddsFactorTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteOddsFactorTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete template.
   *
   * Permission: 'bof-tmpl-odds-factor-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteOddsFactorTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOddsFactorTemplate(params: DeleteOddsFactorTemplate$Params, context?: HttpContext): Observable<void> {
    return this.deleteOddsFactorTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
