/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { BofTmplsConfigDto } from '../models/bof-tmpls-config-dto';
import { getWebGroupConfig } from '../fn/web-group-bof-tmpls-config/get-web-group-config';
import { GetWebGroupConfig$Params } from '../fn/web-group-bof-tmpls-config/get-web-group-config';
import { updateWebGroupConfig } from '../fn/web-group-bof-tmpls-config/update-web-group-config';
import { UpdateWebGroupConfig$Params } from '../fn/web-group-bof-tmpls-config/update-web-group-config';

@Injectable({ providedIn: 'root' })
export class WebGroupBofTmplsConfigMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getWebGroupConfig()` */
  static readonly GetWebGroupConfigPath = '/web-group/bof-tmpls/config/{webGroupId}';

  /**
   * Get bof template config.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWebGroupConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWebGroupConfig$Response(params: GetWebGroupConfig$Params, context?: HttpContext): Observable<StrictHttpResponse<BofTmplsConfigDto>> {
    return getWebGroupConfig(this.http, this.rootUrl, params, context);
  }

  /**
   * Get bof template config.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWebGroupConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWebGroupConfig(params: GetWebGroupConfig$Params, context?: HttpContext): Observable<BofTmplsConfigDto> {
    return this.getWebGroupConfig$Response(params, context).pipe(
      map((r: StrictHttpResponse<BofTmplsConfigDto>): BofTmplsConfigDto => r.body)
    );
  }

  /** Path part for operation `updateWebGroupConfig()` */
  static readonly UpdateWebGroupConfigPath = '/web-group/bof-tmpls/config/{webGroupId}';

  /**
   * Update web group bof template configs.
   *
   * Permission: 'bof-tmpl-sorting-edit-config'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateWebGroupConfig()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWebGroupConfig$Response(params: UpdateWebGroupConfig$Params, context?: HttpContext): Observable<StrictHttpResponse<BofTmplsConfigDto>> {
    return updateWebGroupConfig(this.http, this.rootUrl, params, context);
  }

  /**
   * Update web group bof template configs.
   *
   * Permission: 'bof-tmpl-sorting-edit-config'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateWebGroupConfig$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWebGroupConfig(params: UpdateWebGroupConfig$Params, context?: HttpContext): Observable<BofTmplsConfigDto> {
    return this.updateWebGroupConfig$Response(params, context).pipe(
      map((r: StrictHttpResponse<BofTmplsConfigDto>): BofTmplsConfigDto => r.body)
    );
  }

}
