import { Component } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { TranslocoService } from '@ngneat/transloco'
import { MessageService } from 'primeng/api'
import { UserMwApiService } from 'src/app/api/mw-api/MwApiServices'
import { ApiErrorService } from 'src/app/services/api-error.service'

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
    public password: string
    changePasswordForm: UntypedFormGroup

    /* Password should be at least 8 characters long and should contain one number,one uppercase character and one special character.*/
    passwordRegEx = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-<>]).{8,}$/

    constructor(
        readonly router: Router,
        private apiErrorService: ApiErrorService,
        private transloco: TranslocoService,
        private messageService: MessageService,
        private userService: UserMwApiService
    ) {
        this.changePasswordForm = new UntypedFormGroup({
            oldPassword: new UntypedFormControl(null, [Validators.required]),
            newPassword: new UntypedFormControl(null, [Validators.required, Validators.pattern(this.passwordRegEx)]),
            confirmPassword: new UntypedFormControl(null, [Validators.required, Validators.pattern(this.passwordRegEx)])
        })
        this.changePasswordForm.setValidators(this.passwordRepeat)
    }

    passwordRepeat(group: UntypedFormGroup) {
        const newPassword = group.get('newPassword').value
        const confirmPassword = group.get('confirmPassword').value
        return newPassword === confirmPassword ? null : { passpasswordRepeat: true }
    }

    /**
     * Send change password request for user to API
     */
    changePassword() {
        const payload = {
            body: {
                oldPassword: this.changePasswordForm.value.oldPassword,
                newPassword: this.changePasswordForm.value.newPassword
            }
        }

        this.userService.updatePassword(payload).subscribe({
            next: () => {
                return this.messageService.add({
                    severity: 'success',
                    closable: true,
                    summary: this.transloco.translate('SUCCESS'),
                    detail: this.transloco.translate('NEW_PASSWORD_SENT_TO_EMAIL')
                })
            },
            error: error => {
                return this.messageService.add({
                    severity: 'error',
                    sticky: true,
                    closable: true,
                    summary: this.apiErrorService.parse(error).displayError,
                    detail: this.transloco.translate('ERROR_CHANGE_PASSWORD')
                })
            }
        })
    }

    showInfo() {
        this.messageService.add({
            severity: 'info',
            closable: true,
            summary: 'Info',
            detail: this.transloco.translate('PASSWORD_HINT')
        })
    }
}
