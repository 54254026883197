import { MessageType, WebSocketAsyncMessage } from './web-socket-async-message'
import { WebSocketMessageEncoder } from './web-socket-message-encoder'
import { Injectable } from '@angular/core'
import { SocketClient } from './socket-client'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { WebSocketError } from './web-socket-error'

/** Message ID max value. After reaching max, counter is reset back to 1 */
const MAX_MSG_ID = 1000000

/** Object that wraps up one request */
class PromiseRequest<T> {
    public messageId: number
    public messageName: string
    public payload: T
    public promise: Promise<any>
    public resolve: any
    public reject: any
    public time: number

    constructor(messageId: number, messageName: string, payload: T) {
        this.messageId = messageId
        this.messageName = messageName
        this.payload = payload
        this.time = new Date().getTime()
    }
}

type RequestCallbackFunction = (message: WebSocketAsyncMessage, data: any) => any

interface RequestCallback {
    messageName: string
    callback: RequestCallbackFunction
}

/** Provides access to IBS internal websocket protocol */
@Injectable({ providedIn: 'root' })
export class SocketMessenger {
    private requestIdGenerator = 0
    private promiseList: PromiseRequest<any>[] = []
    private requestHandlers: RequestCallback[] = []
    private onConnectSubject: Subject<void> = new Subject()
    private onDisconnectSubject: Subject<void> = new Subject()
    private onConnectionStatusSubject: BehaviorSubject<boolean> = new BehaviorSubject(false)

    public readonly onConnect$: Observable<void> = this.onConnectSubject.asObservable()
    public readonly onDisconnect$: Observable<void> = this.onDisconnectSubject.asObservable()
    public readonly onConnectionStatus$: Observable<boolean> = this.onConnectionStatusSubject.asObservable()

    constructor(private socketClient: SocketClient) {
        if (socketClient.isConnected()) {
            this.onSocketConnect()
        }

        socketClient.onConnect.subscribe(this.onSocketConnect.bind(this))
        socketClient.onDisconnect.subscribe(this.onSocketDisconnect.bind(this))
        socketClient.onMessage.subscribe(this.onMessage.bind(this))
    }

    public onSocketConnect() {
        this.onConnectSubject.next()
        this.onConnectionStatusSubject.next(true)
    }

    /** When socket is disconnected this method is triggered */
    private onSocketDisconnect() {
        this.requestIdGenerator = 0
        this.rejectAllRequests()
        this.onDisconnectSubject.next()
        this.onConnectionStatusSubject.next(false)
    }

    /***
     * Emit request to receiver without confirmation or some kind of feed back from the receiver
     */
    public emit(message: WebSocketAsyncMessage) {
        message.messageId = 0
        this.socketClient.send(WebSocketMessageEncoder.encode(message))
    }

    /***
     * Send request to server, and return the promise to the caller, which will be resolved when
     * the response is received or timeout is triggered
     * @param message message to be sent
     * @param timeoutMs optional timeout for waiting for the response
     * @returns promise
     */
    public async sendMessage<U>(message: WebSocketAsyncMessage, timeoutMs = 0): Promise<U> {
        message.messageType = MessageType.request
        this.requestIdGenerator = this.requestIdGenerator > MAX_MSG_ID ? 1 : ++this.requestIdGenerator

        const promiseReq = new PromiseRequest(this.requestIdGenerator, message.messageName, message.payload)

        promiseReq.promise = new Promise((resolve, reject) => {
            promiseReq.resolve = resolve
            promiseReq.reject = reject
            if (timeoutMs && timeoutMs > 0) {
                const id = setTimeout(() => {
                    clearTimeout(id)
                    reject(new Error('Timed out'))
                }, timeoutMs)
            }
        })

        message.messageId = promiseReq.messageId
        this.socketClient.send(WebSocketMessageEncoder.encode(message))
        this.promiseList.push(promiseReq)
        return promiseReq.promise
    }

    /**
     * Specific request handlers identified with messageName
     */
    public onRequest(messageName: string, callback: RequestCallbackFunction) {
        this.requestHandlers.push({ messageName, callback })
    }

    /** Reject all waiting requests in the list */
    private rejectAllRequests() {
        this.promiseList.forEach(r => {
            console.warn(`Rejecting "${r.messageName}"`, r.payload ? JSON.stringify(r.payload) : '')
            r.reject(new Error('Rejected'))
        })
        this.promiseList = []
    }

    private onMessage(data: any) {
        try {
            const message: WebSocketAsyncMessage = WebSocketMessageEncoder.decode(data)
            console.log(message)

            switch (message.messageType) {
                case MessageType.none:
                    this.wrapAndSendError(message, `Invalid message type: ${message.messageType}`)
                    break
                case MessageType.request:
                    void this.request(message)
                    break
                case MessageType.response:
                    this.receive(message)
                    break
                case MessageType.message:
                    this.onSubscriptionMessage(message)
                    break
            }
        } catch (error) {
            console.error('Parsing msg error: ', error)
        }
    }

    /**
     * Process request received over socket
     */
    private async request(message: WebSocketAsyncMessage): Promise<void> {
        const requestHandler = this.requestHandlers.find(r => r.messageName === message.messageName)
        if (requestHandler) {
            try {
                const responseData = await requestHandler.callback(message, message.payload)
                // Saljemo odgovor nazad samo ako je request-response zahtev. U tom slucaju messageId nije nula
                if (message.messageId > 0) {
                    const responseMessage: WebSocketAsyncMessage = {
                        messageType: MessageType.response,
                        messageId: message.messageId,
                        messageName: message.messageName,
                        headers: new Map<string, string>(),
                        payload: responseData
                    }
                    this.socketClient.send(WebSocketMessageEncoder.encode(responseMessage))
                }
            } catch (e) {
                console.warn('request handling error', e, e.message, e.stack, JSON.stringify(e))
                const errorMessage = typeof e === 'string' ? e : e.message
                this.wrapAndSendError(message, errorMessage)
            }
        } else {
            console.warn(`Handler for request not found`, JSON.stringify(message))
            if (message.messageId > 0) {
                this.wrapAndSendError(message, `Handler for request ${message.messageName} not found`)
            }
        }
    }

    /**
     * Process request received over socket
     */
    private onSubscriptionMessage(message: WebSocketAsyncMessage) {
        const handler = this.requestHandlers.find(r => r.messageName === message.messageName)
        if (handler) {
            try {
                handler.callback(message, message.payload)
            } catch (e) {
                console.warn('request handling error', e, e.message, e.stack, JSON.stringify(e))
            }
        } else {
            console.warn(`Handler for request not found`, JSON.stringify(message))
        }
    }

    private wrapAndSendError(request: WebSocketAsyncMessage, error: string) {
        const headers = new Map<string, string>()
        headers.set('error', error)
        const message: WebSocketAsyncMessage = {
            messageType: MessageType.response,
            messageName: request.messageName,
            messageId: request.messageId,
            headers
        }
        this.socketClient.send(WebSocketMessageEncoder.encode(message))
    }

    /** Process response received from socket */
    private receive(response: WebSocketAsyncMessage) {
        try {
            this.promiseList
                .filter(r => r.messageId === response.messageId)
                .forEach(r => {
                    if (response.headers.has('error')) {
                        const errorMessage = response.headers.get('error')
                        const statusCode: number = response.headers.has('statusCode')
                            ? parseInt(response.headers.get('statusCode'))
                            : -1

                        console.debug(`Ws error, code: ${statusCode}, message: ${errorMessage}`)
                        r.reject(new WebSocketError(errorMessage, statusCode))
                    } else {
                        r.resolve(response.payload)
                    }
                })
        } finally {
            this.promiseList = this.promiseList.filter(r => r.messageId !== response.messageId)
        }
    }
}
