/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { AgentAddressDto } from '../models/agent-address-dto';
import { getAgentAddress } from '../fn/agent-address/get-agent-address';
import { GetAgentAddress$Params } from '../fn/agent-address/get-agent-address';
import { updateAgentAddress } from '../fn/agent-address/update-agent-address';
import { UpdateAgentAddress$Params } from '../fn/agent-address/update-agent-address';

@Injectable({ providedIn: 'root' })
export class AgentAddressMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateAgentAddress()` */
  static readonly UpdateAgentAddressPath = '/agent/address';

  /**
   * Update agent address properties.
   *
   * Permission: 'agents-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAgentAddress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAgentAddress$Response(params: UpdateAgentAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<AgentAddressDto>> {
    return updateAgentAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * Update agent address properties.
   *
   * Permission: 'agents-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAgentAddress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAgentAddress(params: UpdateAgentAddress$Params, context?: HttpContext): Observable<AgentAddressDto> {
    return this.updateAgentAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<AgentAddressDto>): AgentAddressDto => r.body)
    );
  }

  /** Path part for operation `getAgentAddress()` */
  static readonly GetAgentAddressPath = '/agent/address/{agentId}';

  /**
   * Get agent address properties.
   *
   * Permission: 'agents-info'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAgentAddress()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgentAddress$Response(params: GetAgentAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<AgentAddressDto>> {
    return getAgentAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * Get agent address properties.
   *
   * Permission: 'agents-info'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAgentAddress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgentAddress(params: GetAgentAddress$Params, context?: HttpContext): Observable<AgentAddressDto> {
    return this.getAgentAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<AgentAddressDto>): AgentAddressDto => r.body)
    );
  }

}
