import { TranslocoService } from '@ngneat/transloco'
import { MenuItem } from 'primeng/api/menuitem'

export interface DropdownItem {
    id?: string
    name?: string
    value?: any
    checked?: boolean
}

export class DropdownItemUtils {
    public static addItemsById(items: string[]): DropdownItem[] {
        return items.map(a => ({ id: a, name: a }))
    }

    public static addItems(items: any, idProperty: string = 'id', nameProperty: string = 'name'): DropdownItem[] {
        if (!Array.isArray(items)) {
            items = [items]
        }
        return items.map(a => ({ id: a[idProperty], name: a[nameProperty] }))
    }

    public static addItemsByIdAndValue(items: string[], values: any[], defaultChecked: boolean): DropdownItem[] {
        return items.map((a, index) => ({ id: a, name: a, value: values[index], checked: defaultChecked }))
    }

    public static findAction(items: DropdownItem[], actionId: string) {
        return items.find(action => action.id === actionId)
    }

    public static translateItems(items: DropdownItem[], transloco: TranslocoService) {
        items.forEach(action => transloco.selectTranslate(action.id).subscribe(value => (action.name = value)))
    }

    public static translateActions(items: MenuItem[], transloco: TranslocoService) {
        items.forEach(action => transloco.selectTranslate(action.label).subscribe(value => (action.label = value)))
    }

    public static translateActionsSynchronously(actions: DropdownItem[], transloco: TranslocoService) {
        actions.forEach(action => (action.name = transloco.translate(action.id)))
    }

    public static translateMenuItems(actions: MenuItem[], transloco: TranslocoService) {
        actions
            .filter(action => action.id !== 'separator')
            .forEach(action => transloco.selectTranslate(action.id).subscribe(value => (action.label = value)))
    }
}
