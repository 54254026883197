/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { BofTmplsFiltersDto } from '../models/bof-tmpls-filters-dto';
import { getAgentFilter } from '../fn/agent-bof-tmpls-filter/get-agent-filter';
import { GetAgentFilter$Params } from '../fn/agent-bof-tmpls-filter/get-agent-filter';
import { updateAgentFilter } from '../fn/agent-bof-tmpls-filter/update-agent-filter';
import { UpdateAgentFilter$Params } from '../fn/agent-bof-tmpls-filter/update-agent-filter';

@Injectable({ providedIn: 'root' })
export class AgentBofTmplsFilterMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateAgentFilter()` */
  static readonly UpdateAgentFilterPath = '/agent/bof-tmpls/filter/{agentId}';

  /**
   * Update bof sorting template filter.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAgentFilter()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAgentFilter$Response(params: UpdateAgentFilter$Params, context?: HttpContext): Observable<StrictHttpResponse<BofTmplsFiltersDto>> {
    return updateAgentFilter(this.http, this.rootUrl, params, context);
  }

  /**
   * Update bof sorting template filter.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAgentFilter$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAgentFilter(params: UpdateAgentFilter$Params, context?: HttpContext): Observable<BofTmplsFiltersDto> {
    return this.updateAgentFilter$Response(params, context).pipe(
      map((r: StrictHttpResponse<BofTmplsFiltersDto>): BofTmplsFiltersDto => r.body)
    );
  }

  /** Path part for operation `getAgentFilter()` */
  static readonly GetAgentFilterPath = '/agent/bof-tmpls/filter/all/{agentId}';

  /**
   * Get bof sorting template filter.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAgentFilter()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgentFilter$Response(params: GetAgentFilter$Params, context?: HttpContext): Observable<StrictHttpResponse<BofTmplsFiltersDto>> {
    return getAgentFilter(this.http, this.rootUrl, params, context);
  }

  /**
   * Get bof sorting template filter.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAgentFilter$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgentFilter(params: GetAgentFilter$Params, context?: HttpContext): Observable<BofTmplsFiltersDto> {
    return this.getAgentFilter$Response(params, context).pipe(
      map((r: StrictHttpResponse<BofTmplsFiltersDto>): BofTmplsFiltersDto => r.body)
    );
  }

}
