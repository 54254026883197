/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { addMinSelectTemplate } from '../fn/bof-tmpl-min-select/add-min-select-template';
import { AddMinSelectTemplate$Params } from '../fn/bof-tmpl-min-select/add-min-select-template';
import { BofTmplDto } from '../models/bof-tmpl-dto';
import { deleteMinSelectTemplate } from '../fn/bof-tmpl-min-select/delete-min-select-template';
import { DeleteMinSelectTemplate$Params } from '../fn/bof-tmpl-min-select/delete-min-select-template';
import { getMinSelectTemplates } from '../fn/bof-tmpl-min-select/get-min-select-templates';
import { GetMinSelectTemplates$Params } from '../fn/bof-tmpl-min-select/get-min-select-templates';
import { renameMinSelectTemplate } from '../fn/bof-tmpl-min-select/rename-min-select-template';
import { RenameMinSelectTemplate$Params } from '../fn/bof-tmpl-min-select/rename-min-select-template';

@Injectable({ providedIn: 'root' })
export class BofTmplMinSelectMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `renameMinSelectTemplate()` */
  static readonly RenameMinSelectTemplatePath = '/bof-tmpl/min-select';

  /**
   * Rename template.
   *
   * Permission: 'bof-tmpl-min-select-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `renameMinSelectTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  renameMinSelectTemplate$Response(params: RenameMinSelectTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<BofTmplDto>> {
    return renameMinSelectTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Rename template.
   *
   * Permission: 'bof-tmpl-min-select-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `renameMinSelectTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  renameMinSelectTemplate(params: RenameMinSelectTemplate$Params, context?: HttpContext): Observable<BofTmplDto> {
    return this.renameMinSelectTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<BofTmplDto>): BofTmplDto => r.body)
    );
  }

  /** Path part for operation `addMinSelectTemplate()` */
  static readonly AddMinSelectTemplatePath = '/bof-tmpl/min-select';

  /**
   * Add new template.
   *
   * Permission: 'bof-tmpl-min-select-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addMinSelectTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addMinSelectTemplate$Response(params: AddMinSelectTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<BofTmplDto>> {
    return addMinSelectTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Add new template.
   *
   * Permission: 'bof-tmpl-min-select-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addMinSelectTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addMinSelectTemplate(params: AddMinSelectTemplate$Params, context?: HttpContext): Observable<BofTmplDto> {
    return this.addMinSelectTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<BofTmplDto>): BofTmplDto => r.body)
    );
  }

  /** Path part for operation `getMinSelectTemplates()` */
  static readonly GetMinSelectTemplatesPath = '/bof-tmpl/min-select/all';

  /**
   * Get list of all templates.
   *
   * Permission: 'bof-tmpl-min-select-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMinSelectTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMinSelectTemplates$Response(params?: GetMinSelectTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BofTmplDto>>> {
    return getMinSelectTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of all templates.
   *
   * Permission: 'bof-tmpl-min-select-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMinSelectTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMinSelectTemplates(params?: GetMinSelectTemplates$Params, context?: HttpContext): Observable<Array<BofTmplDto>> {
    return this.getMinSelectTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BofTmplDto>>): Array<BofTmplDto> => r.body)
    );
  }

  /** Path part for operation `deleteMinSelectTemplate()` */
  static readonly DeleteMinSelectTemplatePath = '/bof-tmpl/min-select/{id}';

  /**
   * Delete template.
   *
   * Permission: 'bof-tmpl-min-select-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMinSelectTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMinSelectTemplate$Response(params: DeleteMinSelectTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteMinSelectTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete template.
   *
   * Permission: 'bof-tmpl-min-select-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteMinSelectTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMinSelectTemplate(params: DeleteMinSelectTemplate$Params, context?: HttpContext): Observable<void> {
    return this.deleteMinSelectTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
