/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { BofSearchResultDto } from '../models/bof-search-result-dto';
import { getFakeBofSearchResultDto } from '../fn/bof/get-fake-bof-search-result-dto';
import { GetFakeBofSearchResultDto$Params } from '../fn/bof/get-fake-bof-search-result-dto';
import { getFakeBofTournament } from '../fn/bof/get-fake-bof-tournament';
import { GetFakeBofTournament$Params } from '../fn/bof/get-fake-bof-tournament';
import { PrematchTournamentDto } from '../models/prematch-tournament-dto';

@Injectable({ providedIn: 'root' })
export class BofMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getFakeBofTournament()` */
  static readonly GetFakeBofTournamentPath = '/bof';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFakeBofTournament()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFakeBofTournament$Response(params?: GetFakeBofTournament$Params, context?: HttpContext): Observable<StrictHttpResponse<PrematchTournamentDto>> {
    return getFakeBofTournament(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFakeBofTournament$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFakeBofTournament(params?: GetFakeBofTournament$Params, context?: HttpContext): Observable<PrematchTournamentDto> {
    return this.getFakeBofTournament$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrematchTournamentDto>): PrematchTournamentDto => r.body)
    );
  }

  /** Path part for operation `getFakeBofSearchResultDto()` */
  static readonly GetFakeBofSearchResultDtoPath = '/bof/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFakeBofSearchResultDto()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFakeBofSearchResultDto$Response(params: GetFakeBofSearchResultDto$Params, context?: HttpContext): Observable<StrictHttpResponse<BofSearchResultDto>> {
    return getFakeBofSearchResultDto(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFakeBofSearchResultDto$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFakeBofSearchResultDto(params: GetFakeBofSearchResultDto$Params, context?: HttpContext): Observable<BofSearchResultDto> {
    return this.getFakeBofSearchResultDto$Response(params, context).pipe(
      map((r: StrictHttpResponse<BofSearchResultDto>): BofSearchResultDto => r.body)
    );
  }

}
