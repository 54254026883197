import { ChangeDetectionStrategy, Component } from '@angular/core'
import { WebSessionService } from './session/web-session-service'

export interface AppConfig {
    inputStyle?: string
    theme?: string
    ripple?: boolean
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class AppComponent {
    constructor(private webSessionService: WebSessionService) {}
}
