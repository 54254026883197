<p-menubar *ngIf='userSession?.isAuthenticated' [model]='mainMenuLinks' class='w-full'>
    <div class='inline-flex flex-row w-full justify-content-start gap 1'>
        <button
            type='button'
            pButton
            icon='pi pi-user'
            (click)='menu.toggle($event)'
        >{{ userSession?.username }}</button>
        <p-menu #menu [popup]='true' [model]='profile'></p-menu>

        <button type='button' pButton icon='pi pi-palette' (click)='theme.toggle($event)' class='ml-2'>&nbsp;</button>
        <p-menu #theme [popup]='true' [model]='themes'></p-menu>

        <button type='button' pButton icon='pi pi-sort' (click)='scale.toggle($event)' class='ml-2'>&nbsp;</button>
        <p-menu #scale [popup]='true' [model]='scales'></p-menu>

        <app-select-lang class='ml-2'></app-select-lang>
    </div>
</p-menubar>
