import { NgModule } from '@angular/core'
import { PagingComponent } from './components/common/paging/paging.component'
import { PaginatorModule } from 'primeng/paginator'
import { DirtyCheckGuard } from './services/dirty-check-guard'
import { ToasterService } from './services/toaster.service'

@NgModule({
    imports: [PaginatorModule],
    declarations: [PagingComponent],
    providers: [DirtyCheckGuard, ToasterService],
    exports: [PagingComponent]
})
export class AppSharedModule {}
