import { Component, OnInit } from '@angular/core'
import { MessageService, TreeNode } from 'primeng/api'
import { PermissionMwApiService } from 'src/app/api/mw-api/MwApiServices'
import { ApiErrorService } from 'src/app/services/api-error.service'
import { TranslocoService } from '@ngneat/transloco'
import { PermissionsTreeDto } from 'src/app/api/mw-api/MwApiModels'

@Component({
    selector: 'app-permissions-tree',
    templateUrl: './permissions-tree.component.html',
    styleUrls: ['./permissions-tree.component.scss']
})
export class PermissionsTreeComponent implements OnInit {
    primeTree: TreeNode[] = []

    loading = false

    constructor(
        private permissionMwApiService: PermissionMwApiService,
        private apiErrorService: ApiErrorService,
        private transloco: TranslocoService,
        private messageService: MessageService
    ) {}

    ngOnInit(): void {
        this.getDataFromServer()
    }

    /** Create new Prime node from DTO data */
    createPrimeNode(permissionsData: PermissionsTreeDto) {
        return {
            label: permissionsData.description,
            data: permissionsData,
            children: [],
            expanded: true
        }
    }

    /** Convert given DTO to Prime node */
    convertTreeNode(primeNode: TreeNode, permissionsData: PermissionsTreeDto) {
        if (permissionsData.permissions) {
            permissionsData.permissions.forEach(child => {
                const primeChild = this.createPrimeNode(child)
                primeNode.children.push(primeChild)
                this.convertTreeNode(primeChild, child)
            })
        }
    }

    /** Convert received data to Prime TreeNode structure */
    convertTreeToPrimeTree(permissionsData: PermissionsTreeDto) {
        const root = this.createPrimeNode(permissionsData)
        this.primeTree.push(root)
        this.convertTreeNode(root, permissionsData)
    }

    /** Get data from server for all permissions as tree */
    getDataFromServer() {
        this.loading = true
        this.primeTree = []

        // this.permissionMwApiService.getPermissionsForUserAsTree({ username }).subscribe(
        //     permissionsData => {
        //         this.loading = false
        //         this.convertTreeToPrimeTree(permissionsData)
        //     },
        //     err => {
        //         this.loading = false
        //         this.primeTree = []
        //         this.messageService.add({
        //             severity: 'error',
        //             sticky: true,
        //             closable: true,
        //             summary: this.transloco.translate('ERROR'),
        //             detail: this.apiErrorService.parse(err).displayError
        //         })
        //     }
        // )
    }
}
