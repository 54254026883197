/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { ExtFeedDto } from '../models/ext-feed-dto';
import { getExtFeeds } from '../fn/ext-feed/get-ext-feeds';
import { GetExtFeeds$Params } from '../fn/ext-feed/get-ext-feeds';
import { getExtMatches } from '../fn/ext-feed/get-ext-matches';
import { GetExtMatches$Params } from '../fn/ext-feed/get-ext-matches';
import { PageDataExtMatchInfo } from '../models/page-data-ext-match-info';

@Injectable({ providedIn: 'root' })
export class ExtFeedMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getExtMatches()` */
  static readonly GetExtMatchesPath = '/ext-feed/matches';

  /**
   * Get ext matches list.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExtMatches()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExtMatches$Response(params: GetExtMatches$Params, context?: HttpContext): Observable<StrictHttpResponse<PageDataExtMatchInfo>> {
    return getExtMatches(this.http, this.rootUrl, params, context);
  }

  /**
   * Get ext matches list.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getExtMatches$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExtMatches(params: GetExtMatches$Params, context?: HttpContext): Observable<PageDataExtMatchInfo> {
    return this.getExtMatches$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageDataExtMatchInfo>): PageDataExtMatchInfo => r.body)
    );
  }

  /** Path part for operation `getExtFeeds()` */
  static readonly GetExtFeedsPath = '/ext-feed/';

  /**
   * Get ext-feed list.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExtFeeds()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExtFeeds$Response(params?: GetExtFeeds$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ExtFeedDto>>> {
    return getExtFeeds(this.http, this.rootUrl, params, context);
  }

  /**
   * Get ext-feed list.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getExtFeeds$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExtFeeds(params?: GetExtFeeds$Params, context?: HttpContext): Observable<Array<ExtFeedDto>> {
    return this.getExtFeeds$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ExtFeedDto>>): Array<ExtFeedDto> => r.body)
    );
  }

}
