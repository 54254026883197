/**
 * All available permissions
 */
export class AccessPermissions {
    // Betslip
    static betOverview = 'bet-overview'

    // External record synchronization
    static extRecSync = 'external-record-synchronization'

    // Sb-media
    static sbMediaSettings = 'sb-media-edit-settings'
    static sbMediaPages = 'sb-media-edit-pages'

    // Settings
    static languageEdit = 'language-edit'
    static webSites = 'web-sites'
    static currency = 'currency'

    // Markets definition
    static marketsDefinitionView = 'markets-definition-view'
    static marketsDefinitionEdit = 'markets-definition-edit'

    // Mail
    static mailEdit = 'mail-edit'
    static mailEditConfig = 'mail-edit-config'

    // Perf
    static perfCounters = 'perf'

    // Agent
    static agentsView = 'agents-view'
    static agentsInfo = 'agents-info'
    static agentsAdd = 'agents-add'
    static agentsEdit = 'agents-edit'
    static agentsEditHiddenStatus = 'agents-edit-hidden-status'
    static agentsEditLockStatus = 'agents-edit-hidden-status'
    static agentsEditComment = 'agents-edit-comment'
    static agentsEditPermission = 'agents-edit-permissions'
    // Agent: user
    static usersAdd = 'users-add'
    static usersView = 'users-view'
    static usersEdit = 'users-edit'
    static usersEditLockStatus = 'users-edit-lock-status'
    static usersEditPassword = 'users-edit-password'
    static usersEditOtherUsersPassword = 'users-edit-other-user-password'
    static usersEditPermission = 'users-edit-permissions'
    // Agent : player
    static playersAdd = 'players-add'
    static playersView = 'players-view'
    static playersInfo = 'players-info'
    static playersExport = 'players-export'
    static playersViewCity = 'players-view-city'
    static playersEdit = 'players-edit'
    static playersEditLockStatus = 'players-edit-lock-status'
    static playersEditPassword = 'playersEditPassword'
    static playersEditRegistrationStatus = 'players-edit-registration-status'

    // Bof
    static bofSettingsView = 'bof-settings-view'
    static bofSettingsEdit = 'bof-settings-edit'
    // Bof-tmpl: highlighted-matches
    static bofTemplateHighlightedMatchesEdit = 'bof-tmpl-highlighted-matches-edit'
    static bofTemplateHighlightedMatchesEditConfig = 'bof-tmpl-highlighted-matches-edit-config'
    static bofTemplateHighlightedMatchesEditFilter = 'bof-tmpl-highlighted-matches-edit-filter'
    // Bof-tmpl: input-codes
    static bofTemplateInputCodesEdit = 'bof-tmpl-input-codes-edit'
    static bofTemplateInputCodesEditConfig = 'bof-tmpl-input-codes-edit-config'
    static bofTemplateInputCodesEditFilter = 'bof-tmpl-input-codes-edit-filter'
    // Bof-tmpl: markets
    static bofTemplateMarketsEdit = 'bof-tmpl-markets-edit'
    static bofTemplateMarketsEditConfig = 'bof-tmpl-markets-edit-config'
    static bofTemplateMarketsEditFilter = 'bof-tmpl-markets-edit-filter'
    // Bof-tmpl: min-select
    static bofTemplateMinSelectionEdit = 'bof-tmpl-min-select-edit'
    static bofTemplateMinSelectionEditConfig = 'bof-tmpl-min-select-edit-config'
    static bofTemplateMinSelectionEditFilter = 'bof-tmpl-min-select-edit-filter'
    // Bof-tmpl: odds-factor
    static bofTemplateOddsFactorEdit = 'bof-tmpl-odds-factor-edit'
    static bofTemplateOddsFactorEditConfig = 'bof-tmpl-odds-factor-edit-config'
    static bofTemplateOddsFactorEditFilter = 'bof-tmpl-odds-factor-edit-filter'
    // Bof-tmpl: offer
    static bofTemplateOfferEdit = 'bof-tmpl-offer-edit'
    static bofTemplateOfferEditConfig = 'bof-tmpl-offer-edit-config'
    static bofTemplateOfferEditFilter = 'bof-tmpl-offer-edit-filter'
    // Bof-tmpl: sorting
    static bofTemplateSortingEdit = 'bof-tmpl-sorting-edit'
    static bofTemplateSortingEditConfig = 'bof-tmpl-sorting-edit-config'
    static bofTemplateSortingEditFilter = 'bof-tmpl-sorting-edit-filter'
    // Bof-tmpl: top-tournaments
    static bofTemplateTopTournamentsEdit = 'bof-tmpl-top-tournaments-edit'
    static bofTemplateTopTournamentsEditConfig = 'bof-tmpl-top-tournaments-edit-config'
    static bofTemplateTopTournamentsEditFilter = 'bof-tmpl-top-tournaments-edit-filter'
}

/**
 * Mapping between angular routes and backend permissions
 * key - route name on angular side
 * value - permission name from backend
 */
export const URL_TO_PERMISSION_MAPPING = {
    betOverview: AccessPermissions.betOverview,
    recordSync: 'externalRecordSync',
    changePassword: 'users-changePassword',
    users: 'users-filter',
    languages: AccessPermissions.languageEdit,
    permissions: 'permissions-edit',
    roles: 'permissions-edit',
    agents: AccessPermissions.agentsView,
    'sb-media-settings': AccessPermissions.sbMediaSettings,
    'sb-media-pages': AccessPermissions.sbMediaPages,
    players: AccessPermissions.playersView,

    'betting-offer': AccessPermissions.bofSettingsView,
    'ext-matches': AccessPermissions.bofSettingsView,
    sports: AccessPermissions.bofSettingsView,
    categories: AccessPermissions.bofSettingsView,
    tournaments: AccessPermissions.bofSettingsView,
    participants: AccessPermissions.bofSettingsView,
    markets: AccessPermissions.marketsDefinitionView,
    'market-groups': AccessPermissions.marketsDefinitionView,
    'web-templates': AccessPermissions.webSites,

    sorting: AccessPermissions.bofTemplateSortingEdit,
    'min-select': AccessPermissions.bofTemplateMinSelectionEdit,
    'top-tournament': AccessPermissions.bofTemplateTopTournamentsEdit,
    offer: AccessPermissions.bofTemplateOfferEdit,

    'odds-factor': AccessPermissions.bofTemplateOddsFactorEdit,
    'highlighted-matches': AccessPermissions.bofTemplateHighlightedMatchesEdit,
    'input-codes': AccessPermissions.bofTemplateInputCodesEdit,
    'market-templates': AccessPermissions.bofTemplateMarketsEdit,

    'mail-accounts': AccessPermissions.mailEdit,

    perf: AccessPermissions.perfCounters
}
