/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { BofTmplInputCodesItemDto } from '../models/bof-tmpl-input-codes-item-dto';
import { getInputCodesItem } from '../fn/bof-tmpl-input-codes-item/get-input-codes-item';
import { GetInputCodesItem$Params } from '../fn/bof-tmpl-input-codes-item/get-input-codes-item';
import { updateInputCodesItem } from '../fn/bof-tmpl-input-codes-item/update-input-codes-item';
import { UpdateInputCodesItem$Params } from '../fn/bof-tmpl-input-codes-item/update-input-codes-item';

@Injectable({ providedIn: 'root' })
export class BofTmplInputCodesItemMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateInputCodesItem()` */
  static readonly UpdateInputCodesItemPath = '/bof-tmpl/input-codes/item/{languageId}';

  /**
   * Update sport items.
   *
   * Permission: 'bof-tmpl-input-codes-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateInputCodesItem()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateInputCodesItem$Response(params: UpdateInputCodesItem$Params, context?: HttpContext): Observable<StrictHttpResponse<BofTmplInputCodesItemDto>> {
    return updateInputCodesItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Update sport items.
   *
   * Permission: 'bof-tmpl-input-codes-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateInputCodesItem$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateInputCodesItem(params: UpdateInputCodesItem$Params, context?: HttpContext): Observable<BofTmplInputCodesItemDto> {
    return this.updateInputCodesItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<BofTmplInputCodesItemDto>): BofTmplInputCodesItemDto => r.body)
    );
  }

  /** Path part for operation `getInputCodesItem()` */
  static readonly GetInputCodesItemPath = '/bof-tmpl/input-codes/item/{languageId}/{templateId}/{marketId}';

  /**
   * Get list of sport input codes templates.
   *
   * Permission: 'bof-tmpl-input-codes-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInputCodesItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInputCodesItem$Response(params: GetInputCodesItem$Params, context?: HttpContext): Observable<StrictHttpResponse<BofTmplInputCodesItemDto>> {
    return getInputCodesItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of sport input codes templates.
   *
   * Permission: 'bof-tmpl-input-codes-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInputCodesItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInputCodesItem(params: GetInputCodesItem$Params, context?: HttpContext): Observable<BofTmplInputCodesItemDto> {
    return this.getInputCodesItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<BofTmplInputCodesItemDto>): BofTmplInputCodesItemDto => r.body)
    );
  }

}
