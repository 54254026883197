/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { addGroup } from '../fn/market-def/add-group';
import { AddGroup$Params } from '../fn/market-def/add-group';
import { addMarketDef } from '../fn/market-def/add-market-def';
import { AddMarketDef$Params } from '../fn/market-def/add-market-def';
import { getGroupAllLanguages } from '../fn/market-def/get-group-all-languages';
import { GetGroupAllLanguages$Params } from '../fn/market-def/get-group-all-languages';
import { getGroups } from '../fn/market-def/get-groups';
import { GetGroups$Params } from '../fn/market-def/get-groups';
import { getMarketDef } from '../fn/market-def/get-market-def';
import { GetMarketDef$Params } from '../fn/market-def/get-market-def';
import { getMarkets } from '../fn/market-def/get-markets';
import { GetMarkets$Params } from '../fn/market-def/get-markets';
import { getMarketUsages } from '../fn/market-def/get-market-usages';
import { GetMarketUsages$Params } from '../fn/market-def/get-market-usages';
import { getOutcomeTypes } from '../fn/market-def/get-outcome-types';
import { GetOutcomeTypes$Params } from '../fn/market-def/get-outcome-types';
import { getSpecifierDataTypes } from '../fn/market-def/get-specifier-data-types';
import { GetSpecifierDataTypes$Params } from '../fn/market-def/get-specifier-data-types';
import { getSpecifiers } from '../fn/market-def/get-specifiers';
import { GetSpecifiers$Params } from '../fn/market-def/get-specifiers';
import { GroupDto } from '../models/group-dto';
import { GroupSortIndexDto } from '../models/group-sort-index-dto';
import { GroupTranslationDto } from '../models/group-translation-dto';
import { MarketDefDto } from '../models/market-def-dto';
import { MarketDefOverviewDto } from '../models/market-def-overview-dto';
import { MarketSortIndexDto } from '../models/market-sort-index-dto';
import { removeGroup } from '../fn/market-def/remove-group';
import { RemoveGroup$Params } from '../fn/market-def/remove-group';
import { removeMarket } from '../fn/market-def/remove-market';
import { RemoveMarket$Params } from '../fn/market-def/remove-market';
import { updateGroup } from '../fn/market-def/update-group';
import { UpdateGroup$Params } from '../fn/market-def/update-group';
import { updateGroupSortIndex } from '../fn/market-def/update-group-sort-index';
import { UpdateGroupSortIndex$Params } from '../fn/market-def/update-group-sort-index';
import { updateGroupTranslation } from '../fn/market-def/update-group-translation';
import { UpdateGroupTranslation$Params } from '../fn/market-def/update-group-translation';
import { updateMarketDef } from '../fn/market-def/update-market-def';
import { UpdateMarketDef$Params } from '../fn/market-def/update-market-def';
import { updateMarketSortIndex } from '../fn/market-def/update-market-sort-index';
import { UpdateMarketSortIndex$Params } from '../fn/market-def/update-market-sort-index';

@Injectable({ providedIn: 'root' })
export class MarketDefMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateMarketDef()` */
  static readonly UpdateMarketDefPath = '/market-def/market/{languageId}';

  /**
   * Update market def.
   *
   * Permission: 'markets-definition-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMarketDef()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMarketDef$Response(params: UpdateMarketDef$Params, context?: HttpContext): Observable<StrictHttpResponse<MarketDefDto>> {
    return updateMarketDef(this.http, this.rootUrl, params, context);
  }

  /**
   * Update market def.
   *
   * Permission: 'markets-definition-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateMarketDef$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMarketDef(params: UpdateMarketDef$Params, context?: HttpContext): Observable<MarketDefDto> {
    return this.updateMarketDef$Response(params, context).pipe(
      map((r: StrictHttpResponse<MarketDefDto>): MarketDefDto => r.body)
    );
  }

  /** Path part for operation `addMarketDef()` */
  static readonly AddMarketDefPath = '/market-def/market/{languageId}';

  /**
   * Add new market def.
   *
   * Permission: 'markets-definition-edit''
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addMarketDef()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addMarketDef$Response(params: AddMarketDef$Params, context?: HttpContext): Observable<StrictHttpResponse<MarketDefDto>> {
    return addMarketDef(this.http, this.rootUrl, params, context);
  }

  /**
   * Add new market def.
   *
   * Permission: 'markets-definition-edit''
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addMarketDef$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addMarketDef(params: AddMarketDef$Params, context?: HttpContext): Observable<MarketDefDto> {
    return this.addMarketDef$Response(params, context).pipe(
      map((r: StrictHttpResponse<MarketDefDto>): MarketDefDto => r.body)
    );
  }

  /** Path part for operation `updateMarketSortIndex()` */
  static readonly UpdateMarketSortIndexPath = '/market-def/market/sort/{groupId}/{sportId}/{marketId}/{moveUp}';

  /**
   * Update market sort index.
   *
   * Permission: 'markets-definition-edit''
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMarketSortIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateMarketSortIndex$Response(params: UpdateMarketSortIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MarketSortIndexDto>>> {
    return updateMarketSortIndex(this.http, this.rootUrl, params, context);
  }

  /**
   * Update market sort index.
   *
   * Permission: 'markets-definition-edit''
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateMarketSortIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateMarketSortIndex(params: UpdateMarketSortIndex$Params, context?: HttpContext): Observable<Array<MarketSortIndexDto>> {
    return this.updateMarketSortIndex$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MarketSortIndexDto>>): Array<MarketSortIndexDto> => r.body)
    );
  }

  /** Path part for operation `updateGroup()` */
  static readonly UpdateGroupPath = '/market-def/group/{languageId}';

  /**
   * Update group.
   *
   * Permission: 'markets-definition-edit''
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateGroup$Response(params: UpdateGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<GroupDto>> {
    return updateGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * Update group.
   *
   * Permission: 'markets-definition-edit''
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateGroup(params: UpdateGroup$Params, context?: HttpContext): Observable<GroupDto> {
    return this.updateGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<GroupDto>): GroupDto => r.body)
    );
  }

  /** Path part for operation `updateGroupTranslation()` */
  static readonly UpdateGroupTranslationPath = '/market-def/group/translation';

  /**
   * Update group translation.
   *
   * Permission: 'markets-definition-edit''
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateGroupTranslation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateGroupTranslation$Response(params: UpdateGroupTranslation$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GroupTranslationDto>>> {
    return updateGroupTranslation(this.http, this.rootUrl, params, context);
  }

  /**
   * Update group translation.
   *
   * Permission: 'markets-definition-edit''
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateGroupTranslation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateGroupTranslation(params: UpdateGroupTranslation$Params, context?: HttpContext): Observable<Array<GroupTranslationDto>> {
    return this.updateGroupTranslation$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GroupTranslationDto>>): Array<GroupTranslationDto> => r.body)
    );
  }

  /** Path part for operation `updateGroupSortIndex()` */
  static readonly UpdateGroupSortIndexPath = '/market-def/group/sort/{groupId}/{moveUp}';

  /**
   * Update group sort index.
   *
   * Permission: 'markets-definition-edit''
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateGroupSortIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateGroupSortIndex$Response(params: UpdateGroupSortIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GroupSortIndexDto>>> {
    return updateGroupSortIndex(this.http, this.rootUrl, params, context);
  }

  /**
   * Update group sort index.
   *
   * Permission: 'markets-definition-edit''
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateGroupSortIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateGroupSortIndex(params: UpdateGroupSortIndex$Params, context?: HttpContext): Observable<Array<GroupSortIndexDto>> {
    return this.updateGroupSortIndex$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GroupSortIndexDto>>): Array<GroupSortIndexDto> => r.body)
    );
  }

  /** Path part for operation `addGroup()` */
  static readonly AddGroupPath = '/market-def/group';

  /**
   * Add new group.
   *
   * Permission: 'markets-definition-edit''
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addGroup$Response(params: AddGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<GroupDto>> {
    return addGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * Add new group.
   *
   * Permission: 'markets-definition-edit''
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addGroup(params: AddGroup$Params, context?: HttpContext): Observable<GroupDto> {
    return this.addGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<GroupDto>): GroupDto => r.body)
    );
  }

  /** Path part for operation `getSpecifierDataTypes()` */
  static readonly GetSpecifierDataTypesPath = '/market-def/specifier/data-types';

  /**
   * Get list of specifiers data types options.
   *
   * Permission: 'markets-definition-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSpecifierDataTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSpecifierDataTypes$Response(params?: GetSpecifierDataTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getSpecifierDataTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of specifiers data types options.
   *
   * Permission: 'markets-definition-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSpecifierDataTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSpecifierDataTypes(params?: GetSpecifierDataTypes$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getSpecifierDataTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `getSpecifiers()` */
  static readonly GetSpecifiersPath = '/market-def/specifier/all';

  /**
   * Get list of specifiers options.
   *
   * Permission: 'markets-definition-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSpecifiers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSpecifiers$Response(params?: GetSpecifiers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getSpecifiers(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of specifiers options.
   *
   * Permission: 'markets-definition-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSpecifiers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSpecifiers(params?: GetSpecifiers$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getSpecifiers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `getOutcomeTypes()` */
  static readonly GetOutcomeTypesPath = '/market-def/outcome/types';

  /**
   * Get list of outcome types.
   *
   * Permission: 'markets-definition-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOutcomeTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOutcomeTypes$Response(params?: GetOutcomeTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getOutcomeTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of outcome types.
   *
   * Permission: 'markets-definition-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOutcomeTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOutcomeTypes(params?: GetOutcomeTypes$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getOutcomeTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `getMarketDef()` */
  static readonly GetMarketDefPath = '/market-def/market/{languageId}/{marketId}';

  /**
   * Get market def.
   *
   * Permission: 'markets-definition-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMarketDef()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketDef$Response(params: GetMarketDef$Params, context?: HttpContext): Observable<StrictHttpResponse<MarketDefDto>> {
    return getMarketDef(this.http, this.rootUrl, params, context);
  }

  /**
   * Get market def.
   *
   * Permission: 'markets-definition-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMarketDef$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketDef(params: GetMarketDef$Params, context?: HttpContext): Observable<MarketDefDto> {
    return this.getMarketDef$Response(params, context).pipe(
      map((r: StrictHttpResponse<MarketDefDto>): MarketDefDto => r.body)
    );
  }

  /** Path part for operation `getMarketUsages()` */
  static readonly GetMarketUsagesPath = '/market-def/market/usages';

  /**
   * Get list of market usage options.
   *
   * Permission: 'markets-definition-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMarketUsages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketUsages$Response(params?: GetMarketUsages$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getMarketUsages(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of market usage options.
   *
   * Permission: 'markets-definition-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMarketUsages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketUsages(params?: GetMarketUsages$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getMarketUsages$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `getMarkets()` */
  static readonly GetMarketsPath = '/market-def/market/all/{languageId}/{sportId}';

  /**
   * Get list of markets.
   *
   * Permission: 'markets-definition-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMarkets()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarkets$Response(params: GetMarkets$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MarketDefOverviewDto>>> {
    return getMarkets(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of markets.
   *
   * Permission: 'markets-definition-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMarkets$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarkets(params: GetMarkets$Params, context?: HttpContext): Observable<Array<MarketDefOverviewDto>> {
    return this.getMarkets$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MarketDefOverviewDto>>): Array<MarketDefOverviewDto> => r.body)
    );
  }

  /** Path part for operation `getGroups()` */
  static readonly GetGroupsPath = '/market-def/group/all/{languageId}';

  /**
   * Get groups.
   *
   * Permission: 'markets-definition-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGroups()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGroups$Response(params: GetGroups$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GroupDto>>> {
    return getGroups(this.http, this.rootUrl, params, context);
  }

  /**
   * Get groups.
   *
   * Permission: 'markets-definition-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGroups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGroups(params: GetGroups$Params, context?: HttpContext): Observable<Array<GroupDto>> {
    return this.getGroups$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GroupDto>>): Array<GroupDto> => r.body)
    );
  }

  /** Path part for operation `getGroupAllLanguages()` */
  static readonly GetGroupAllLanguagesPath = '/market-def/group/all-languages/{groupId}';

  /**
   * Get one group: all languages.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGroupAllLanguages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGroupAllLanguages$Response(params: GetGroupAllLanguages$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GroupTranslationDto>>> {
    return getGroupAllLanguages(this.http, this.rootUrl, params, context);
  }

  /**
   * Get one group: all languages.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGroupAllLanguages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGroupAllLanguages(params: GetGroupAllLanguages$Params, context?: HttpContext): Observable<Array<GroupTranslationDto>> {
    return this.getGroupAllLanguages$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GroupTranslationDto>>): Array<GroupTranslationDto> => r.body)
    );
  }

  /** Path part for operation `removeMarket()` */
  static readonly RemoveMarketPath = '/market-def/market/{marketId}';

  /**
   * Remove market.
   *
   * Permission: 'markets-definition-edit''
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeMarket()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeMarket$Response(params: RemoveMarket$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return removeMarket(this.http, this.rootUrl, params, context);
  }

  /**
   * Remove market.
   *
   * Permission: 'markets-definition-edit''
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeMarket$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeMarket(params: RemoveMarket$Params, context?: HttpContext): Observable<void> {
    return this.removeMarket$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `removeGroup()` */
  static readonly RemoveGroupPath = '/market-def/group/{groupId}';

  /**
   * Remove group.
   *
   * Permission: 'markets-definition-edit''
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeGroup$Response(params: RemoveGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return removeGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * Remove group.
   *
   * Permission: 'markets-definition-edit''
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeGroup(params: RemoveGroup$Params, context?: HttpContext): Observable<void> {
    return this.removeGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
