/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { CurrencyDto } from '../models/currency-dto';
import { getCurrencies } from '../fn/currency/get-currencies';
import { GetCurrencies$Params } from '../fn/currency/get-currencies';

@Injectable({ providedIn: 'root' })
export class CurrencyMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCurrencies()` */
  static readonly GetCurrenciesPath = '/currency/all';

  /**
   * Get all currencies.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrencies()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrencies$Response(params?: GetCurrencies$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CurrencyDto>>> {
    return getCurrencies(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all currencies.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCurrencies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrencies(params?: GetCurrencies$Params, context?: HttpContext): Observable<Array<CurrencyDto>> {
    return this.getCurrencies$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CurrencyDto>>): Array<CurrencyDto> => r.body)
    );
  }

}
