/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AgentDto } from '../../models/agent-dto';

export interface UnlockAgent$Params {
  agentId: number;
}

export function unlockAgent(http: HttpClient, rootUrl: string, params: UnlockAgent$Params, context?: HttpContext): Observable<StrictHttpResponse<AgentDto>> {
  const rb = new RequestBuilder(rootUrl, unlockAgent.PATH, 'put');
  if (params) {
    rb.path('agentId', params.agentId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<AgentDto>;
    })
  );
}

unlockAgent.PATH = '/agent/unlock/{agentId}';
