import { Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { TranslocoService } from '@ngneat/transloco'
import { MenuItem } from 'primeng/api'
import { Subscription } from 'rxjs'
import { AppConfig } from 'src/app/app.component'
import { AppConfigService } from 'src/app/services/appconfig.service'
import { MainMenuLinksService } from 'src/app/services/main-menu-links.service'
import { DropdownItemUtils } from 'src/app/utils/dropdown-utils'
import { AuthService } from '../../session/auth.service'
import { UserSession } from '../../session/user-session'

@Component({
    selector: 'app-navigation-bar',
    templateUrl: './navigation-bar.component.html',
    styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent implements OnInit, OnDestroy {
    config: AppConfig

    activeMenuIndex: number
    mainMenuLinks: MenuItem[]
    sideMenu = false
    isVisible: boolean

    subscription: Subscription
    userSessionSubs$: Subscription
    linksSubs$: Subscription
    userSession: UserSession

    fontSize = '14px'
    newFontSize: number

    appTheme: string

    //** Meni sa desne strane navigacije. On se nalazi unutar menija sa leve strane navigacije u .html fajlu */
    public themes: MenuItem[] = [
        {
            id: 'LIGHT',
            label: 'LIGHT',
            visible: true,
            command: () => this.changeTheme('light')
        },
        {
            id: 'DARK',
            label: 'DARK',
            visible: true,
            command: () => this.changeTheme('dark')
        }
    ]

    public profile: MenuItem[] = [
        {
            id: 'CHANGE_PASSWORD',
            label: 'CHANGE_PASSWORD',
            routerLink: ['/change-password'],
            icon: 'pi pi-pencil',
            visible: true
        },
        {
            id: 'LOGOUT',
            label: 'LOGOUT',
            icon: 'pi pi-sign-out',
            visible: true,
            command: () => this.logout()
        }
    ]

    public scales: MenuItem[] = [
        {
            id: 'SCALE_UP',
            icon: 'pi pi-plus',
            label: 'SCALE_UP',
            visible: true,
            command: () => this.incrementScale()
        },
        {
            id: 'SCALE_DOWN',
            icon: 'pi pi-minus',
            label: 'SCALE_DOWN',
            visible: true,
            command: () => this.decrementScale()
        }
    ]

    constructor(
        readonly router: Router,
        public auth: AuthService,
        private transloco: TranslocoService,
        private configService: AppConfigService,
        private mainMenuLinksService: MainMenuLinksService
    ) {}

    ngOnInit(): void {
        this.userSessionSubs$ = this.auth.userSession$.subscribe(userSession => {
            this.userSession = userSession
            if (userSession.isAnonymous) {
                //    this.router.navigate(['login'])
            }
        })

        this.isVisible = true
        this.linksSubs$ = this.mainMenuLinksService.mainMenuLinks$.subscribe(data => (this.mainMenuLinks = data))

        DropdownItemUtils.translateMenuItems(this.themes, this.transloco)
        DropdownItemUtils.translateMenuItems(this.profile, this.transloco)
        DropdownItemUtils.translateMenuItems(this.scales, this.transloco)
        //**Theme from local storage*/
        this.config = this.configService.config

        //On button click save theme to local storage
        this.subscription = this.configService.configUpdate$.subscribe(config => {
            this.config = config
            localStorage.setItem('theme', this.config.theme)
        })

        this.appTheme = localStorage.getItem('theme')

        /**If local storage is empty, onInit will call this if condition to set value for theme */
        if (this.appTheme === null) {
            localStorage.setItem('theme', this.config.theme)
        }

        if (this.appTheme && this.appTheme !== this.config.theme) {
            this.changeTheme(this.appTheme)
        }

        //** Get font size from local storage*/
        this.fontSize = localStorage.getItem('fontSize')
        document.documentElement.style.fontSize = this.fontSize

        /**If local storage is empty, onInit will call this if condition to set value for font size */
        if (this.fontSize === '14px' || this.fontSize === null) {
            this.fontSize = '14px'
            document.documentElement.style.fontSize = this.fontSize
            localStorage.setItem('fontSize', this.fontSize)
        }
    }

    ngOnDestroy(): void {
        this.userSessionSubs$.unsubscribe()
        this.linksSubs$.unsubscribe()
    }

    changeTheme(theme: string) {
        const themeElement = document.getElementById('theme-link')
        themeElement.setAttribute('href', themeElement.getAttribute('href').replace(this.config.theme, theme))
        this.configService.updateConfig({ ...this.config, ...{ theme } })
    }

    decrementScale() {
        this.fontSize = localStorage.getItem('fontSize')
        this.newFontSize = parseInt(this.fontSize, 10)
        this.newFontSize--
        this.applyScale()
    }

    incrementScale() {
        this.fontSize = localStorage.getItem('fontSize')
        this.newFontSize = parseInt(this.fontSize, 10)
        this.newFontSize++
        this.applyScale()
    }

    applyScale() {
        document.documentElement.style.fontSize = this.newFontSize + 'px'
        localStorage.setItem('fontSize', document.documentElement.style.fontSize)
    }

    /** Logout action clicked */
    logout() {
        this.auth.logout(true)
    }
}
