import { WebSocketAsyncMessage } from './web-socket-async-message'

const SEPARATOR = '\u0000'

export class WebSocketMessageEncoder {
    /**
     * Decodes raw message
     */
    static decode(rawMessage: string): WebSocketAsyncMessage {
        const parts: string[] = rawMessage.split(SEPARATOR)

        const messageHeader: any = JSON.parse(parts[0])

        // Checks
        if (messageHeader.t !== 'r' && messageHeader.t !== 'a' && messageHeader.t !== 'm') {
            throw new Error(`Invalid message type ${messageHeader.t}`)
        }

        if (messageHeader.t === 'a' && messageHeader.i < 1) {
            throw new Error(`Expected valid messageId in response, found: ${messageHeader.t}`)
        }

        const headers = messageHeader?.h ? messageHeader.h : {}
        const headersMap: Map<string, string> = new Map(Object.entries(headers))

        // Return final object
        return {
            messageType: messageHeader.t,
            messageId: messageHeader.i,
            messageName: messageHeader.n,
            headers: headersMap,
            payload: parts.length > 1 && parts[1] && parts[1].length > 0 ? JSON.parse(parts[1]) : undefined
        }
    }

    /**
     * Encode message to raw utf8 string for sneding
     * @param message
     */
    static encode(message: WebSocketAsyncMessage): string {
        const mapToObj = m => {
            return Array.from(m).reduce((obj, [key, value]) => {
                obj[key] = value
                return obj
            }, {})
        }

        const messageMetadata: string = JSON.stringify({
            t: message.messageType,
            i: message.messageId,
            n: message.messageName,
            h: mapToObj(message.headers)
        })

        const payload = message?.payload ? JSON.stringify(message.payload) : ''
        return messageMetadata + SEPARATOR + payload
    }
}
