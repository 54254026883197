/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { getActiveSports } from '../fn/bof-entity-sport/get-active-sports';
import { GetActiveSports$Params } from '../fn/bof-entity-sport/get-active-sports';
import { getSport } from '../fn/bof-entity-sport/get-sport';
import { GetSport$Params } from '../fn/bof-entity-sport/get-sport';
import { getSportAllLanguages } from '../fn/bof-entity-sport/get-sport-all-languages';
import { GetSportAllLanguages$Params } from '../fn/bof-entity-sport/get-sport-all-languages';
import { getSportsWithTranslation } from '../fn/bof-entity-sport/get-sports-with-translation';
import { GetSportsWithTranslation$Params } from '../fn/bof-entity-sport/get-sports-with-translation';
import { getSportsWithTranslationWithPaging } from '../fn/bof-entity-sport/get-sports-with-translation-with-paging';
import { GetSportsWithTranslationWithPaging$Params } from '../fn/bof-entity-sport/get-sports-with-translation-with-paging';
import { getSportWithTranslation } from '../fn/bof-entity-sport/get-sport-with-translation';
import { GetSportWithTranslation$Params } from '../fn/bof-entity-sport/get-sport-with-translation';
import { PageDataSportWithTranslationDto } from '../models/page-data-sport-with-translation-dto';
import { SportDto } from '../models/sport-dto';
import { SportTranslationDto } from '../models/sport-translation-dto';
import { SportWithTranslationDto } from '../models/sport-with-translation-dto';
import { updateSport } from '../fn/bof-entity-sport/update-sport';
import { UpdateSport$Params } from '../fn/bof-entity-sport/update-sport';
import { updateSportTranslations } from '../fn/bof-entity-sport/update-sport-translations';
import { UpdateSportTranslations$Params } from '../fn/bof-entity-sport/update-sport-translations';

@Injectable({ providedIn: 'root' })
export class BofEntitySportMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateSport()` */
  static readonly UpdateSportPath = '/bof-entity/sport';

  /**
   * Update sport.
   *
   * Permission: 'bof-settings-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSport$Response(params: UpdateSport$Params, context?: HttpContext): Observable<StrictHttpResponse<SportDto>> {
    return updateSport(this.http, this.rootUrl, params, context);
  }

  /**
   * Update sport.
   *
   * Permission: 'bof-settings-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateSport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSport(params: UpdateSport$Params, context?: HttpContext): Observable<SportDto> {
    return this.updateSport$Response(params, context).pipe(
      map((r: StrictHttpResponse<SportDto>): SportDto => r.body)
    );
  }

  /** Path part for operation `updateSportTranslations()` */
  static readonly UpdateSportTranslationsPath = '/bof-entity/sport/translations';

  /**
   * Update sport translations.
   *
   * Permission: 'bof-settings-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSportTranslations()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSportTranslations$Response(params: UpdateSportTranslations$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SportTranslationDto>>> {
    return updateSportTranslations(this.http, this.rootUrl, params, context);
  }

  /**
   * Update sport translations.
   *
   * Permission: 'bof-settings-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateSportTranslations$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSportTranslations(params: UpdateSportTranslations$Params, context?: HttpContext): Observable<Array<SportTranslationDto>> {
    return this.updateSportTranslations$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SportTranslationDto>>): Array<SportTranslationDto> => r.body)
    );
  }

  /** Path part for operation `getSport()` */
  static readonly GetSportPath = '/bof-entity/sport/{sportId}';

  /**
   * Get one sport.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSport()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSport$Response(params: GetSport$Params, context?: HttpContext): Observable<StrictHttpResponse<SportDto>> {
    return getSport(this.http, this.rootUrl, params, context);
  }

  /**
   * Get one sport.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSport(params: GetSport$Params, context?: HttpContext): Observable<SportDto> {
    return this.getSport$Response(params, context).pipe(
      map((r: StrictHttpResponse<SportDto>): SportDto => r.body)
    );
  }

  /** Path part for operation `getSportWithTranslation()` */
  static readonly GetSportWithTranslationPath = '/bof-entity/sport/with-translation/{languageId}/{sportId}';

  /**
   * Get one sport with translation.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSportWithTranslation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSportWithTranslation$Response(params: GetSportWithTranslation$Params, context?: HttpContext): Observable<StrictHttpResponse<SportWithTranslationDto>> {
    return getSportWithTranslation(this.http, this.rootUrl, params, context);
  }

  /**
   * Get one sport with translation.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSportWithTranslation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSportWithTranslation(params: GetSportWithTranslation$Params, context?: HttpContext): Observable<SportWithTranslationDto> {
    return this.getSportWithTranslation$Response(params, context).pipe(
      map((r: StrictHttpResponse<SportWithTranslationDto>): SportWithTranslationDto => r.body)
    );
  }

  /** Path part for operation `getSportsWithTranslation()` */
  static readonly GetSportsWithTranslationPath = '/bof-entity/sport/all/with-translation/{languageId}';

  /**
   * Get list of sports.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSportsWithTranslation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSportsWithTranslation$Response(params: GetSportsWithTranslation$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SportWithTranslationDto>>> {
    return getSportsWithTranslation(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of sports.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSportsWithTranslation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSportsWithTranslation(params: GetSportsWithTranslation$Params, context?: HttpContext): Observable<Array<SportWithTranslationDto>> {
    return this.getSportsWithTranslation$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SportWithTranslationDto>>): Array<SportWithTranslationDto> => r.body)
    );
  }

  /** Path part for operation `getSportsWithTranslationWithPaging()` */
  static readonly GetSportsWithTranslationWithPagingPath = '/bof-entity/sport/all/with-translation/paging/{languageId}';

  /**
   * Get list of sports.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSportsWithTranslationWithPaging()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSportsWithTranslationWithPaging$Response(params: GetSportsWithTranslationWithPaging$Params, context?: HttpContext): Observable<StrictHttpResponse<PageDataSportWithTranslationDto>> {
    return getSportsWithTranslationWithPaging(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of sports.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSportsWithTranslationWithPaging$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSportsWithTranslationWithPaging(params: GetSportsWithTranslationWithPaging$Params, context?: HttpContext): Observable<PageDataSportWithTranslationDto> {
    return this.getSportsWithTranslationWithPaging$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageDataSportWithTranslationDto>): PageDataSportWithTranslationDto => r.body)
    );
  }

  /** Path part for operation `getActiveSports()` */
  static readonly GetActiveSportsPath = '/bof-entity/sport/all/with-translation/active/{languageId}';

  /**
   * Get list of active sports.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActiveSports()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveSports$Response(params: GetActiveSports$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SportWithTranslationDto>>> {
    return getActiveSports(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of active sports.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActiveSports$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveSports(params: GetActiveSports$Params, context?: HttpContext): Observable<Array<SportWithTranslationDto>> {
    return this.getActiveSports$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SportWithTranslationDto>>): Array<SportWithTranslationDto> => r.body)
    );
  }

  /** Path part for operation `getSportAllLanguages()` */
  static readonly GetSportAllLanguagesPath = '/bof-entity/sport/all-languages/{sportId}';

  /**
   * Get one sport: all languages.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSportAllLanguages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSportAllLanguages$Response(params: GetSportAllLanguages$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SportTranslationDto>>> {
    return getSportAllLanguages(this.http, this.rootUrl, params, context);
  }

  /**
   * Get one sport: all languages.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSportAllLanguages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSportAllLanguages(params: GetSportAllLanguages$Params, context?: HttpContext): Observable<Array<SportTranslationDto>> {
    return this.getSportAllLanguages$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SportTranslationDto>>): Array<SportTranslationDto> => r.body)
    );
  }

}
