import { Injectable } from '@angular/core'

/** Api error interface that abstracts errors */
export class ApiError {
    /** Short and unique error code. This error code should have transloco support  */
    errorCode: string
    /** Http status if set */
    status: number
    /** Short error messeage. If errorCode does not return translation, this should be used to
     * inform the user about the error.
     */
    error: string
    /** Long descriptive error message. Not intended to bi visible for end user. */
    message: string
    /** Display error after transloco parsing. This should be shown to the end user */
    displayError: string
    /** Optional data object. Type is depending on the context */
    data: any
}

@Injectable({
    providedIn: 'root'
})
export class ApiErrorService {
    /**
     * Parses error and returns common error object
     *
     * @param errorObj error object which can present various forms of error
     */
    parse(errorObj: any) {
        const parsedError = new ApiError()
        if (typeof errorObj === 'object') {
            // Parse status
            if (errorObj?.status) {
                parsedError.status = errorObj.status
            }
            if (errorObj?.error) {
                if (typeof errorObj.error === 'string') {
                    parsedError.error = errorObj.error
                } else if (typeof errorObj.error === 'object') {
                    parsedError.error = errorObj.error.error
                    if (errorObj?.error.message) {
                        parsedError.message = errorObj.error.message
                    }
                    if (errorObj?.error.errorCode) {
                        parsedError.errorCode = errorObj.error.errorCode
                    }
                    if (errorObj?.error.data) {
                        parsedError.data = errorObj.error.data
                    }
                }
            }
        }

        // If errorCode has no translation try with "error"
        if (parsedError.error) {
            parsedError.displayError = parsedError.error
        }

        return parsedError
    }
}
