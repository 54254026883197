/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { BofTmplHighlightedMatchesItemDto } from '../models/bof-tmpl-highlighted-matches-item-dto';
import { getHighlightedMatchesList } from '../fn/bof-tmpl-min-highlighted-matches-item/get-highlighted-matches-list';
import { GetHighlightedMatchesList$Params } from '../fn/bof-tmpl-min-highlighted-matches-item/get-highlighted-matches-list';
import { updateHighlightedMatchesList } from '../fn/bof-tmpl-min-highlighted-matches-item/update-highlighted-matches-list';
import { UpdateHighlightedMatchesList$Params } from '../fn/bof-tmpl-min-highlighted-matches-item/update-highlighted-matches-list';

@Injectable({ providedIn: 'root' })
export class BofTmplMinHighlightedMatchesItemMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateHighlightedMatchesList()` */
  static readonly UpdateHighlightedMatchesListPath = '/bof-tmpl/highlighted-matches/item/{languageId}/{sportId}';

  /**
   * Update items.
   *
   * Permission: 'bof-tmpl-highlighted-matches-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateHighlightedMatchesList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateHighlightedMatchesList$Response(params: UpdateHighlightedMatchesList$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BofTmplHighlightedMatchesItemDto>>> {
    return updateHighlightedMatchesList(this.http, this.rootUrl, params, context);
  }

  /**
   * Update items.
   *
   * Permission: 'bof-tmpl-highlighted-matches-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateHighlightedMatchesList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateHighlightedMatchesList(params: UpdateHighlightedMatchesList$Params, context?: HttpContext): Observable<Array<BofTmplHighlightedMatchesItemDto>> {
    return this.updateHighlightedMatchesList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BofTmplHighlightedMatchesItemDto>>): Array<BofTmplHighlightedMatchesItemDto> => r.body)
    );
  }

  /** Path part for operation `getHighlightedMatchesList()` */
  static readonly GetHighlightedMatchesListPath = '/bof-tmpl/highlighted-matches/item/{languageId}/{templateId}/{sportId}';

  /**
   * Get list of top highlighted matches templates items.
   *
   * Permission: 'bof-tmpl-highlighted-matches-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHighlightedMatchesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHighlightedMatchesList$Response(params: GetHighlightedMatchesList$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BofTmplHighlightedMatchesItemDto>>> {
    return getHighlightedMatchesList(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of top highlighted matches templates items.
   *
   * Permission: 'bof-tmpl-highlighted-matches-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getHighlightedMatchesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHighlightedMatchesList(params: GetHighlightedMatchesList$Params, context?: HttpContext): Observable<Array<BofTmplHighlightedMatchesItemDto>> {
    return this.getHighlightedMatchesList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BofTmplHighlightedMatchesItemDto>>): Array<BofTmplHighlightedMatchesItemDto> => r.body)
    );
  }

}
