import { Injectable } from '@angular/core'
import { TranslocoService } from '@ngneat/transloco'

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    public activeLanguage: string

    constructor(private transloco: TranslocoService) {
        this.setActiveLangFromStoreOrSetDefault()
    }

    setLang(lang: string) {
        if (
            lang &&
            this.transloco
                .getAvailableLangs()
                .map(l => l.id)
                .includes(lang)
        ) {
            if (lang !== this.activeLanguage) {
                localStorage.setItem('lang', lang)
                this.activeLanguage = lang
                this.transloco.setActiveLang(lang)
            }
        } else {
            throw new Error(`Language ${lang} not found in list of available languages`)
        }
    }

    setActiveLangFromStoreOrSetDefault() {
        const langFromStorage = localStorage.getItem('lang')
        this.setLang(langFromStorage ? langFromStorage : this.transloco.getDefaultLang())
    }
}
