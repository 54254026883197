import {Component, OnInit} from '@angular/core'
import {DynamicDialogConfig} from 'primeng/dynamicdialog'
import {SbMediaDetailedStatsDto} from 'src/app/api/mw-api/MwApiModels'
import {SbmediaStatsMwApiService} from 'src/app/api/mw-api/MwApiServices'
import {Observable} from "rxjs"


@Component({
    selector: 'app-operation-stats-detailed-dialog',
    templateUrl: './operation-stats-detailed-dialog.component.html',
    styleUrls: ['./operation-stats-detailed-dialog.component.scss']
})
export class OperationStatsDetailedDialogComponent implements OnInit {
    stats$: Observable<Array<SbMediaDetailedStatsDto>>

    constructor(private config: DynamicDialogConfig, private sbmediaStatsService: SbmediaStatsMwApiService) {
    }

    ngOnInit(): void {
        this.stats$ = this.sbmediaStatsService.getStatsDetailed()
    }
}
