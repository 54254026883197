/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { BofTmplMinSelectItemDto } from '../models/bof-tmpl-min-select-item-dto';
import { getMinSelectList } from '../fn/bof-tmpl-min-select-item/get-min-select-list';
import { GetMinSelectList$Params } from '../fn/bof-tmpl-min-select-item/get-min-select-list';
import { updateMinSelectList } from '../fn/bof-tmpl-min-select-item/update-min-select-list';
import { UpdateMinSelectList$Params } from '../fn/bof-tmpl-min-select-item/update-min-select-list';

@Injectable({ providedIn: 'root' })
export class BofTmplMinSelectItemMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateMinSelectList()` */
  static readonly UpdateMinSelectListPath = '/bof-tmpl/min-select/item/{languageId}/{sportId}';

  /**
   * Update items.
   *
   * Permission: 'bof-tmpl-min-select-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMinSelectList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMinSelectList$Response(params: UpdateMinSelectList$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BofTmplMinSelectItemDto>>> {
    return updateMinSelectList(this.http, this.rootUrl, params, context);
  }

  /**
   * Update items.
   *
   * Permission: 'bof-tmpl-min-select-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateMinSelectList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMinSelectList(params: UpdateMinSelectList$Params, context?: HttpContext): Observable<Array<BofTmplMinSelectItemDto>> {
    return this.updateMinSelectList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BofTmplMinSelectItemDto>>): Array<BofTmplMinSelectItemDto> => r.body)
    );
  }

  /** Path part for operation `getMinSelectList()` */
  static readonly GetMinSelectListPath = '/bof-tmpl/min-select/item/{languageId}/{sportId}/{templateId}';

  /**
   * Get list of min select templates.
   *
   * Permission: 'bof-tmpl-min-select-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMinSelectList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMinSelectList$Response(params: GetMinSelectList$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BofTmplMinSelectItemDto>>> {
    return getMinSelectList(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of min select templates.
   *
   * Permission: 'bof-tmpl-min-select-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMinSelectList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMinSelectList(params: GetMinSelectList$Params, context?: HttpContext): Observable<Array<BofTmplMinSelectItemDto>> {
    return this.getMinSelectList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BofTmplMinSelectItemDto>>): Array<BofTmplMinSelectItemDto> => r.body)
    );
  }

}
