/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { addInputCodesTemplate } from '../fn/bof-tmpl-input-codes/add-input-codes-template';
import { AddInputCodesTemplate$Params } from '../fn/bof-tmpl-input-codes/add-input-codes-template';
import { BofTmplDto } from '../models/bof-tmpl-dto';
import { deleteInputCodesTemplate } from '../fn/bof-tmpl-input-codes/delete-input-codes-template';
import { DeleteInputCodesTemplate$Params } from '../fn/bof-tmpl-input-codes/delete-input-codes-template';
import { getInputCodesTemplates } from '../fn/bof-tmpl-input-codes/get-input-codes-templates';
import { GetInputCodesTemplates$Params } from '../fn/bof-tmpl-input-codes/get-input-codes-templates';
import { renameInputCodesTemplate } from '../fn/bof-tmpl-input-codes/rename-input-codes-template';
import { RenameInputCodesTemplate$Params } from '../fn/bof-tmpl-input-codes/rename-input-codes-template';

@Injectable({ providedIn: 'root' })
export class BofTmplInputCodesMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `renameInputCodesTemplate()` */
  static readonly RenameInputCodesTemplatePath = '/bof-tmpl/input-codes';

  /**
   * Rename template.
   *
   * Permission: 'bof-tmpl-input-codes-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `renameInputCodesTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  renameInputCodesTemplate$Response(params: RenameInputCodesTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<BofTmplDto>> {
    return renameInputCodesTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Rename template.
   *
   * Permission: 'bof-tmpl-input-codes-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `renameInputCodesTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  renameInputCodesTemplate(params: RenameInputCodesTemplate$Params, context?: HttpContext): Observable<BofTmplDto> {
    return this.renameInputCodesTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<BofTmplDto>): BofTmplDto => r.body)
    );
  }

  /** Path part for operation `addInputCodesTemplate()` */
  static readonly AddInputCodesTemplatePath = '/bof-tmpl/input-codes';

  /**
   * Add new template.
   *
   * Permission: 'bof-tmpl-input-codes-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addInputCodesTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addInputCodesTemplate$Response(params: AddInputCodesTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<BofTmplDto>> {
    return addInputCodesTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Add new template.
   *
   * Permission: 'bof-tmpl-input-codes-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addInputCodesTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addInputCodesTemplate(params: AddInputCodesTemplate$Params, context?: HttpContext): Observable<BofTmplDto> {
    return this.addInputCodesTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<BofTmplDto>): BofTmplDto => r.body)
    );
  }

  /** Path part for operation `getInputCodesTemplates()` */
  static readonly GetInputCodesTemplatesPath = '/bof-tmpl/input-codes/all';

  /**
   * Get list of all templates.
   *
   * Permission: 'bof-tmpl-input-codes-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInputCodesTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInputCodesTemplates$Response(params?: GetInputCodesTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BofTmplDto>>> {
    return getInputCodesTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of all templates.
   *
   * Permission: 'bof-tmpl-input-codes-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInputCodesTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInputCodesTemplates(params?: GetInputCodesTemplates$Params, context?: HttpContext): Observable<Array<BofTmplDto>> {
    return this.getInputCodesTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BofTmplDto>>): Array<BofTmplDto> => r.body)
    );
  }

  /** Path part for operation `deleteInputCodesTemplate()` */
  static readonly DeleteInputCodesTemplatePath = '/bof-tmpl/input-codes/{id}';

  /**
   * Delete template.
   *
   * Permission: 'bof-tmpl-input-codes-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteInputCodesTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteInputCodesTemplate$Response(params: DeleteInputCodesTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteInputCodesTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete template.
   *
   * Permission: 'bof-tmpl-input-codes-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteInputCodesTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteInputCodesTemplate(params: DeleteInputCodesTemplate$Params, context?: HttpContext): Observable<void> {
    return this.deleteInputCodesTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
