/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { addSortingTemplate } from '../fn/bof-tmpl-sorting/add-sorting-template';
import { AddSortingTemplate$Params } from '../fn/bof-tmpl-sorting/add-sorting-template';
import { BofTmplDto } from '../models/bof-tmpl-dto';
import { deleteSortingTemplate } from '../fn/bof-tmpl-sorting/delete-sorting-template';
import { DeleteSortingTemplate$Params } from '../fn/bof-tmpl-sorting/delete-sorting-template';
import { getSortingTemplates } from '../fn/bof-tmpl-sorting/get-sorting-templates';
import { GetSortingTemplates$Params } from '../fn/bof-tmpl-sorting/get-sorting-templates';
import { renameSortingTemplate } from '../fn/bof-tmpl-sorting/rename-sorting-template';
import { RenameSortingTemplate$Params } from '../fn/bof-tmpl-sorting/rename-sorting-template';

@Injectable({ providedIn: 'root' })
export class BofTmplSortingMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `renameSortingTemplate()` */
  static readonly RenameSortingTemplatePath = '/bof-tmpl/sorting';

  /**
   * Rename template.
   *
   * Permission: 'bof-tmpl-sorting-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `renameSortingTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  renameSortingTemplate$Response(params: RenameSortingTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<BofTmplDto>> {
    return renameSortingTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Rename template.
   *
   * Permission: 'bof-tmpl-sorting-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `renameSortingTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  renameSortingTemplate(params: RenameSortingTemplate$Params, context?: HttpContext): Observable<BofTmplDto> {
    return this.renameSortingTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<BofTmplDto>): BofTmplDto => r.body)
    );
  }

  /** Path part for operation `addSortingTemplate()` */
  static readonly AddSortingTemplatePath = '/bof-tmpl/sorting';

  /**
   * Add new template.
   *
   * Permission: 'bof-tmpl-sorting-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addSortingTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addSortingTemplate$Response(params: AddSortingTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<BofTmplDto>> {
    return addSortingTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Add new template.
   *
   * Permission: 'bof-tmpl-sorting-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addSortingTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addSortingTemplate(params: AddSortingTemplate$Params, context?: HttpContext): Observable<BofTmplDto> {
    return this.addSortingTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<BofTmplDto>): BofTmplDto => r.body)
    );
  }

  /** Path part for operation `getSortingTemplates()` */
  static readonly GetSortingTemplatesPath = '/bof-tmpl/sorting/all';

  /**
   * Get list of all templates.
   *
   * Permission: 'bof-tmpl-sorting-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSortingTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSortingTemplates$Response(params?: GetSortingTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BofTmplDto>>> {
    return getSortingTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of all templates.
   *
   * Permission: 'bof-tmpl-sorting-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSortingTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSortingTemplates(params?: GetSortingTemplates$Params, context?: HttpContext): Observable<Array<BofTmplDto>> {
    return this.getSortingTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BofTmplDto>>): Array<BofTmplDto> => r.body)
    );
  }

  /** Path part for operation `deleteSortingTemplate()` */
  static readonly DeleteSortingTemplatePath = '/bof-tmpl/sorting/{id}';

  /**
   * Delete template.
   *
   * Permission: 'bof-tmpl-sorting-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSortingTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSortingTemplate$Response(params: DeleteSortingTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteSortingTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete template.
   *
   * Permission: 'bof-tmpl-sorting-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteSortingTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSortingTemplate(params: DeleteSortingTemplate$Params, context?: HttpContext): Observable<void> {
    return this.deleteSortingTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
