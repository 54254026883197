<form [formGroup]="manualApproveForm" (ngSubmit)="onSaveClick()">
    <textarea pInputTextarea [style]="{ width: '100%' }" formControlName="textarea"></textarea>

    <div class="p-dialog-footer p-mt-4 p-p-0">
        <p-progressSpinner [style]="{ width: '50px', height: '50px' }" *ngIf="loading"></p-progressSpinner>

        <button type="submit" pButton pRipple [disabled]="loading" label="{{ 'SAVE' | transloco }}" icon="pi pi-check"></button>

        <button
            type="button"
            pButton
            pRipple
            [disabled]="loading"
            label="{{ 'CANCEL' | transloco }}"
            icon="pi pi-times"
            class="p-button-outlined"
            (click)="onCancelClick()"
        ></button>
    </div>
</form>
