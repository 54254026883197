/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MwApiConfig, MwApiConfigParams } from './mw-api-config';

import { WebGroupMwApiService } from './services/web-group-mw-api.service';
import { WebGroupBofTmplsConfigMwApiService } from './services/web-group-bof-tmpls-config-mw-api.service';
import { WebDomainMwApiService } from './services/web-domain-mw-api.service';
import { UserMwApiService } from './services/user-mw-api.service';
import { SbmediaSettingsMwApiService } from './services/sbmedia-settings-mw-api.service';
import { PlayerMwApiService } from './services/player-mw-api.service';
import { PlayerBofTmplsConfigMwApiService } from './services/player-bof-tmpls-config-mw-api.service';
import { MarketDefMwApiService } from './services/market-def-mw-api.service';
import { MailMwApiService } from './services/mail-mw-api.service';
import { LocaleMwApiService } from './services/locale-mw-api.service';
import { ExternalRecordSynchronizationMwApiService } from './services/external-record-synchronization-mw-api.service';
import { BofTmplMinTopTournamentMwApiService } from './services/bof-tmpl-min-top-tournament-mw-api.service';
import { BofTmplSortingMwApiService } from './services/bof-tmpl-sorting-mw-api.service';
import { BofTmplOfferMwApiService } from './services/bof-tmpl-offer-mw-api.service';
import { BofTmplOddsFactorMwApiService } from './services/bof-tmpl-odds-factor-mw-api.service';
import { BofTmplMinSelectMwApiService } from './services/bof-tmpl-min-select-mw-api.service';
import { BofTmplMarketsMwApiService } from './services/bof-tmpl-markets-mw-api.service';
import { BofTmplInputCodesMwApiService } from './services/bof-tmpl-input-codes-mw-api.service';
import { BofTmplMinHighlightedMatchesMwApiService } from './services/bof-tmpl-min-highlighted-matches-mw-api.service';
import { BofEntityTournamentMwApiService } from './services/bof-entity-tournament-mw-api.service';
import { BofEntitySportMwApiService } from './services/bof-entity-sport-mw-api.service';
import { BofEntityParticipantMwApiService } from './services/bof-entity-participant-mw-api.service';
import { BofEntityCategoryMwApiService } from './services/bof-entity-category-mw-api.service';
import { AgentMwApiService } from './services/agent-mw-api.service';
import { AgentPrintoutMwApiService } from './services/agent-printout-mw-api.service';
import { AgentDetailsMwApiService } from './services/agent-details-mw-api.service';
import { AgentCurrencyFilterMwApiService } from './services/agent-currency-filter-mw-api.service';
import { AgentBofTmplsFilterMwApiService } from './services/agent-bof-tmpls-filter-mw-api.service';
import { AgentBofTmplsConfigMwApiService } from './services/agent-bof-tmpls-config-mw-api.service';
import { AgentAddressMwApiService } from './services/agent-address-mw-api.service';
import { AgentFeedMwApiService } from './services/agent-feed-mw-api.service';
import { SbmediaReloadMwApiService } from './services/sbmedia-reload-mw-api.service';
import { SbmediaImagesMwApiService } from './services/sbmedia-images-mw-api.service';
import { PermissionMwApiService } from './services/permission-mw-api.service';
import { PerfMwApiService } from './services/perf-mw-api.service';
import { BofTmplMinTopTournamentItemMwApiService } from './services/bof-tmpl-min-top-tournament-item-mw-api.service';
import { BofTmplSortingItemMwApiService } from './services/bof-tmpl-sorting-item-mw-api.service';
import { BofTmplOfferItemMwApiService } from './services/bof-tmpl-offer-item-mw-api.service';
import { BofTmplMinSelectItemMwApiService } from './services/bof-tmpl-min-select-item-mw-api.service';
import { BofTmplMarketsItemMwApiService } from './services/bof-tmpl-markets-item-mw-api.service';
import { BofTmplInputCodesItemMwApiService } from './services/bof-tmpl-input-codes-item-mw-api.service';
import { BofTmplMinHighlightedMatchesItemMwApiService } from './services/bof-tmpl-min-highlighted-matches-item-mw-api.service';
import { AuthenticationMwApiService } from './services/authentication-mw-api.service';
import { FakeSportsTreeMwApiService } from './services/fake-sports-tree-mw-api.service';
import { SbmediaStatsMwApiService } from './services/sbmedia-stats-mw-api.service';
import { OddsFeedMwApiService } from './services/odds-feed-mw-api.service';
import { ExtFeedMwApiService } from './services/ext-feed-mw-api.service';
import { CurrencyMwApiService } from './services/currency-mw-api.service';
import { BofMwApiService } from './services/bof-mw-api.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    WebGroupMwApiService,
    WebGroupBofTmplsConfigMwApiService,
    WebDomainMwApiService,
    UserMwApiService,
    SbmediaSettingsMwApiService,
    PlayerMwApiService,
    PlayerBofTmplsConfigMwApiService,
    MarketDefMwApiService,
    MailMwApiService,
    LocaleMwApiService,
    ExternalRecordSynchronizationMwApiService,
    BofTmplMinTopTournamentMwApiService,
    BofTmplSortingMwApiService,
    BofTmplOfferMwApiService,
    BofTmplOddsFactorMwApiService,
    BofTmplMinSelectMwApiService,
    BofTmplMarketsMwApiService,
    BofTmplInputCodesMwApiService,
    BofTmplMinHighlightedMatchesMwApiService,
    BofEntityTournamentMwApiService,
    BofEntitySportMwApiService,
    BofEntityParticipantMwApiService,
    BofEntityCategoryMwApiService,
    AgentMwApiService,
    AgentPrintoutMwApiService,
    AgentDetailsMwApiService,
    AgentCurrencyFilterMwApiService,
    AgentBofTmplsFilterMwApiService,
    AgentBofTmplsConfigMwApiService,
    AgentAddressMwApiService,
    AgentFeedMwApiService,
    SbmediaReloadMwApiService,
    SbmediaImagesMwApiService,
    PermissionMwApiService,
    PerfMwApiService,
    BofTmplMinTopTournamentItemMwApiService,
    BofTmplSortingItemMwApiService,
    BofTmplOfferItemMwApiService,
    BofTmplMinSelectItemMwApiService,
    BofTmplMarketsItemMwApiService,
    BofTmplInputCodesItemMwApiService,
    BofTmplMinHighlightedMatchesItemMwApiService,
    AuthenticationMwApiService,
    FakeSportsTreeMwApiService,
    SbmediaStatsMwApiService,
    OddsFeedMwApiService,
    ExtFeedMwApiService,
    CurrencyMwApiService,
    BofMwApiService,
    MwApiConfig
  ],
})
export class MwApiModule {
  static forRoot(params: MwApiConfigParams): ModuleWithProviders<MwApiModule> {
    return {
      ngModule: MwApiModule,
      providers: [
        {
          provide: MwApiConfig,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: MwApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('MwApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
