import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { sha256 } from 'js-sha256'
import { BehaviorSubject, Observable } from 'rxjs'
import { LoginRequestDto, UserDetailsDto } from '../api/mw-api/MwApiModels'
import { AuthenticationMwApiService, UserMwApiService } from '../api/mw-api/MwApiServices'
import { UserSession } from './user-session'
import { SessionStorageService } from './session-storage-service'
import { LanguageService } from '../services/language.service'

/**
 * Refresh token every hour
 */
const REFRESH_TOKEN_TIMER = 60 * 60 * 1000

export interface LoginError {
    error: string
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public userSession$ = new BehaviorSubject<UserSession>({
        authorities: [],
        isAnonymous: true,
        isAuthenticated: false
    })

    constructor(
        readonly router: Router,
        private languageService: LanguageService,
        private authenticationMwApiService: AuthenticationMwApiService,
        private userMwApiService: UserMwApiService,
        private sessionStorageService: SessionStorageService
    ) {
        const userSession = this.sessionStorageService.getUserSession()
        if (userSession && userSession.sessionId) {
            this.userMwApiService.getMe({ username: userSession.username }).subscribe({
                next: userDetailsDto => {
                    const me = this.convertToUserSession(userDetailsDto)
                    this.setLoggedUser({
                        ...userSession,
                        authorities: me.authorities,
                        agentId: me.agentId
                    })
                },
                error: () => {
                    this.logout(false)
                }
            })
        } else {
            this.logout(false)
        }
    }

    public logout(informHost: boolean) {
        if (informHost) {
            // Eksplicitno radimo logout na serveru, kako bi ponistili tekucu sesiju
            this.authenticationMwApiService.logout({}).subscribe(() => {
                console.log('Logout')
            })
        }
        this.clearAuthTokenAndUser()
        this.router.navigate(['login'])
    }

    public isAuthenticated(): boolean {
        // TODO Milos
        return true
    }

    public login(username: string, password: string): Observable<UserDetailsDto> {
        const payload = {
            body: {
                username,
                password: sha256(password),
                languageId: this.languageService.activeLanguage,
                zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone
            }
        }

        return new Observable<LoginRequestDto>(observer => {
            this.authenticationMwApiService.authenticate(payload).subscribe({
                next: userDetailsDto => {
                    const userSession = this.convertToUserSession(userDetailsDto)
                    this.setLoggedUser(userSession)
                    observer.next(userSession)
                },
                error: error => {
                    const errorMsg = error?.statusText ? error.statusText : 'Unknown error'
                    observer.error({ error: errorMsg })
                    this.logout(false)
                }
            })
        })
    }

    private convertToUserSession(userDetailsDto: UserDetailsDto): UserSession {
        return {
            sessionId: userDetailsDto.sessionId,
            agentId: userDetailsDto.agentId,
            username: userDetailsDto.username,
            authorities: userDetailsDto.authorities,
            isAnonymous: false,
            isAuthenticated: true
        }
    }

    // public refreshJwtTokens() {
    //     const activeAccessToken = this.sessionStorage.getAccessToken()
    //     const refreshToken = this.sessionStorage.getRefreshToken()
    //
    //     if (activeAccessToken && refreshToken) {
    //         return this.authenticationMwApiService.generateNewAccessToken$Response({ body: refreshToken }).pipe(
    //             tap(response => {
    //                 const newAccessToken = response.headers.get(HeaderTokenNames.authorizationToken)
    //                 const newRefreshToken = response.headers.get(HeaderTokenNames.refreshToken)
    //
    //                 if (newAccessToken && newRefreshToken && this.decodeToken(newAccessToken)) {
    //                     this.saveToken(newAccessToken)
    //                     this.setLoggedUser(this.loggedUser)
    //                     this.sessionStorage.setRefreshToken(newRefreshToken)
    //                     setTimeout(this.refreshJwtTokens.bind(this), REFRESH_TOKEN_TIMER)
    //                 } else {
    //                     this.logout()
    //                 }
    //             }),
    //             catchError(err => {
    //                 this.logout()
    //                 return throwError(() => err)
    //             })
    //         )
    //     }
    // }

    private clearAuthTokenAndUser() {
        const anonymousSession: UserSession = {
            authorities: [],
            isAnonymous: true,
            isAuthenticated: false
        }
        this.sessionStorageService.setUserSession(anonymousSession)
        this.setLoggedUser(anonymousSession)
    }

    private setLoggedUser(userSession: UserSession) {
        this.sessionStorageService.setUserSession(userSession)
        this.userSession$.next(userSession)
    }
}
