import { Injectable } from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Observable } from 'rxjs'
import { SessionStorageService } from './session-storage-service'

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
    constructor(private sessionStorageService: SessionStorageService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const userSession = this.sessionStorageService.getUserSession()
        if (userSession && userSession.sessionId) {
            const clonedReq = request.clone({
                setHeaders: {
                    'x-caller-username': userSession.username,
                    'session-id': userSession.sessionId
                }
            })
            return next.handle(clonedReq)
        } else {
            return next.handle(request)
        }
    }
}
