/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { getStats } from '../fn/sbmedia-stats/get-stats';
import { GetStats$Params } from '../fn/sbmedia-stats/get-stats';
import { getStatsDetailed } from '../fn/sbmedia-stats/get-stats-detailed';
import { GetStatsDetailed$Params } from '../fn/sbmedia-stats/get-stats-detailed';
import { SbMediaDetailedStatsDto } from '../models/sb-media-detailed-stats-dto';
import { SbMediaStatsDto } from '../models/sb-media-stats-dto';

@Injectable({ providedIn: 'root' })
export class SbmediaStatsMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getStats()` */
  static readonly GetStatsPath = '/sb-media/stats';

  /**
   * Get stats.
   *
   * Permission: 'sb-media-edit-settings'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStats()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStats$Response(params?: GetStats$Params, context?: HttpContext): Observable<StrictHttpResponse<SbMediaStatsDto>> {
    return getStats(this.http, this.rootUrl, params, context);
  }

  /**
   * Get stats.
   *
   * Permission: 'sb-media-edit-settings'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getStats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStats(params?: GetStats$Params, context?: HttpContext): Observable<SbMediaStatsDto> {
    return this.getStats$Response(params, context).pipe(
      map((r: StrictHttpResponse<SbMediaStatsDto>): SbMediaStatsDto => r.body)
    );
  }

  /** Path part for operation `getStatsDetailed()` */
  static readonly GetStatsDetailedPath = '/sb-media/stats/detailed';

  /**
   * Get stats detailed.
   *
   * Permission: 'sb-media-edit-settings'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStatsDetailed()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStatsDetailed$Response(params?: GetStatsDetailed$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SbMediaDetailedStatsDto>>> {
    return getStatsDetailed(this.http, this.rootUrl, params, context);
  }

  /**
   * Get stats detailed.
   *
   * Permission: 'sb-media-edit-settings'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getStatsDetailed$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStatsDetailed(params?: GetStatsDetailed$Params, context?: HttpContext): Observable<Array<SbMediaDetailedStatsDto>> {
    return this.getStatsDetailed$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SbMediaDetailedStatsDto>>): Array<SbMediaDetailedStatsDto> => r.body)
    );
  }

}
