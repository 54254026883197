import { Injectable } from '@angular/core'
import { SocketMessenger } from '../lib/web-socket/socket-messenger'
import { BehaviorSubject, combineLatest, Observable } from 'rxjs'
import { AuthService } from './auth.service'
import { MessageType, WebSocketAsyncMessage } from '../lib/web-socket/web-socket-async-message'
import { WebSocketAsyncMessageBuilder } from '../lib/web-socket/web-socket-async-message-builder'
import { LanguageService } from '../services/language.service'
import { WebSocketError } from '../lib/web-socket/web-socket-error'

@Injectable({
    providedIn: 'root'
})
export class WebSessionService {
    private sessionRegisteredSubject: BehaviorSubject<boolean> = new BehaviorSubject(false)
    public sessionRegistered$: Observable<boolean> = this.sessionRegisteredSubject.asObservable()

    constructor(
        languageService: LanguageService,
        private authService: AuthService,
        private socketMessenger: SocketMessenger
    ) {
        this.socketMessenger.onDisconnect$.subscribe(() => {
            this.sessionRegisteredSubject.next(false)
        })

        combineLatest([this.socketMessenger.onConnectionStatus$, this.authService.userSession$]).subscribe(
            ([onConnect, userSession]) => {
                if (onConnect && userSession) {
                    // When connected and with user session send session...
                    const message: WebSocketAsyncMessage = WebSocketAsyncMessageBuilder.builder()
                        .setMessageType(MessageType.request)
                        .setMessageName('registerSession')
                        .setPayload({
                            username: userSession.username,
                            sessionId: userSession.sessionId,
                            languageId: languageService.activeLanguage,
                            zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone
                        })
                        .build()
                    this.socketMessenger
                        .sendMessage<any>(message)
                        .then(() => {
                            this.sessionRegisteredSubject.next(true)
                        })
                        .catch(err => {
                            if (err instanceof WebSocketError) {
                                console.error('Register session error', err.getFormattedMessage())
                                if (err.getStatusCode() !== 404) {
                                    // 404 Suggests that web domain is not valid
                                    // Either its injected or domain is not in the
                                    // "web domain" list on the backend side
                                    authService.logout(true)
                                }
                            }
                        })
                }
            }
        )
    }
}
