/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { BofTmplSortingItemDto } from '../models/bof-tmpl-sorting-item-dto';
import { getCategorySortingTemplates } from '../fn/bof-tmpl-sorting-item/get-category-sorting-templates';
import { GetCategorySortingTemplates$Params } from '../fn/bof-tmpl-sorting-item/get-category-sorting-templates';
import { getSportSortingTemplates } from '../fn/bof-tmpl-sorting-item/get-sport-sorting-templates';
import { GetSportSortingTemplates$Params } from '../fn/bof-tmpl-sorting-item/get-sport-sorting-templates';
import { getTournamentSortingTemplates } from '../fn/bof-tmpl-sorting-item/get-tournament-sorting-templates';
import { GetTournamentSortingTemplates$Params } from '../fn/bof-tmpl-sorting-item/get-tournament-sorting-templates';
import { updateCategorySortingTemplates } from '../fn/bof-tmpl-sorting-item/update-category-sorting-templates';
import { UpdateCategorySortingTemplates$Params } from '../fn/bof-tmpl-sorting-item/update-category-sorting-templates';
import { updateSportSortingTemplates } from '../fn/bof-tmpl-sorting-item/update-sport-sorting-templates';
import { UpdateSportSortingTemplates$Params } from '../fn/bof-tmpl-sorting-item/update-sport-sorting-templates';
import { updateTournamentSortingTemplates } from '../fn/bof-tmpl-sorting-item/update-tournament-sorting-templates';
import { UpdateTournamentSortingTemplates$Params } from '../fn/bof-tmpl-sorting-item/update-tournament-sorting-templates';

@Injectable({ providedIn: 'root' })
export class BofTmplSortingItemMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateTournamentSortingTemplates()` */
  static readonly UpdateTournamentSortingTemplatesPath = '/bof-tmpl/sorting/item/tournament/{languageId}/{sportId}';

  /**
   * Update tournament items.
   *
   * Permission: 'bof-tmpl-sorting-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTournamentSortingTemplates()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTournamentSortingTemplates$Response(params: UpdateTournamentSortingTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BofTmplSortingItemDto>>> {
    return updateTournamentSortingTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * Update tournament items.
   *
   * Permission: 'bof-tmpl-sorting-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTournamentSortingTemplates$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTournamentSortingTemplates(params: UpdateTournamentSortingTemplates$Params, context?: HttpContext): Observable<Array<BofTmplSortingItemDto>> {
    return this.updateTournamentSortingTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BofTmplSortingItemDto>>): Array<BofTmplSortingItemDto> => r.body)
    );
  }

  /** Path part for operation `updateSportSortingTemplates()` */
  static readonly UpdateSportSortingTemplatesPath = '/bof-tmpl/sorting/item/sport/{languageId}';

  /**
   * Update sport items.
   *
   * Permission: 'bof-tmpl-sorting-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSportSortingTemplates()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSportSortingTemplates$Response(params: UpdateSportSortingTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BofTmplSortingItemDto>>> {
    return updateSportSortingTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * Update sport items.
   *
   * Permission: 'bof-tmpl-sorting-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateSportSortingTemplates$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSportSortingTemplates(params: UpdateSportSortingTemplates$Params, context?: HttpContext): Observable<Array<BofTmplSortingItemDto>> {
    return this.updateSportSortingTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BofTmplSortingItemDto>>): Array<BofTmplSortingItemDto> => r.body)
    );
  }

  /** Path part for operation `updateCategorySortingTemplates()` */
  static readonly UpdateCategorySortingTemplatesPath = '/bof-tmpl/sorting/item/category/{languageId}/{sportId}';

  /**
   * Update category items.
   *
   * Permission: 'bof-tmpl-sorting-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCategorySortingTemplates()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCategorySortingTemplates$Response(params: UpdateCategorySortingTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BofTmplSortingItemDto>>> {
    return updateCategorySortingTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * Update category items.
   *
   * Permission: 'bof-tmpl-sorting-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCategorySortingTemplates$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCategorySortingTemplates(params: UpdateCategorySortingTemplates$Params, context?: HttpContext): Observable<Array<BofTmplSortingItemDto>> {
    return this.updateCategorySortingTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BofTmplSortingItemDto>>): Array<BofTmplSortingItemDto> => r.body)
    );
  }

  /** Path part for operation `getTournamentSortingTemplates()` */
  static readonly GetTournamentSortingTemplatesPath = '/bof-tmpl/sorting/item/tournament/{languageId}/{sportId}/{templateId}';

  /**
   * Get list of tournament sorting templates.
   *
   * Permission: 'bof-tmpl-sorting-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTournamentSortingTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTournamentSortingTemplates$Response(params: GetTournamentSortingTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BofTmplSortingItemDto>>> {
    return getTournamentSortingTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of tournament sorting templates.
   *
   * Permission: 'bof-tmpl-sorting-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTournamentSortingTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTournamentSortingTemplates(params: GetTournamentSortingTemplates$Params, context?: HttpContext): Observable<Array<BofTmplSortingItemDto>> {
    return this.getTournamentSortingTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BofTmplSortingItemDto>>): Array<BofTmplSortingItemDto> => r.body)
    );
  }

  /** Path part for operation `getSportSortingTemplates()` */
  static readonly GetSportSortingTemplatesPath = '/bof-tmpl/sorting/item/sport/{languageId}/{templateId}';

  /**
   * Get list of sport sorting templates.
   *
   * Permission: 'bof-tmpl-sorting-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSportSortingTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSportSortingTemplates$Response(params: GetSportSortingTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BofTmplSortingItemDto>>> {
    return getSportSortingTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of sport sorting templates.
   *
   * Permission: 'bof-tmpl-sorting-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSportSortingTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSportSortingTemplates(params: GetSportSortingTemplates$Params, context?: HttpContext): Observable<Array<BofTmplSortingItemDto>> {
    return this.getSportSortingTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BofTmplSortingItemDto>>): Array<BofTmplSortingItemDto> => r.body)
    );
  }

  /** Path part for operation `getCategorySortingTemplates()` */
  static readonly GetCategorySortingTemplatesPath = '/bof-tmpl/sorting/item/category/{languageId}/{sportId}/{templateId}';

  /**
   * Get list of category sorting templates.
   *
   * Permission: 'bof-tmpl-sorting-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCategorySortingTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCategorySortingTemplates$Response(params: GetCategorySortingTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BofTmplSortingItemDto>>> {
    return getCategorySortingTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of category sorting templates.
   *
   * Permission: 'bof-tmpl-sorting-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCategorySortingTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCategorySortingTemplates(params: GetCategorySortingTemplates$Params, context?: HttpContext): Observable<Array<BofTmplSortingItemDto>> {
    return this.getCategorySortingTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BofTmplSortingItemDto>>): Array<BofTmplSortingItemDto> => r.body)
    );
  }

}
