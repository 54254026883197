<form class='flex flex-column align-items-center pt-4' [formGroup]='changePasswordForm' (ngSubmit)='changePassword()'>
    <h1 class='pb-2'>{{ 'CHANGE_PASS' | transloco }}</h1>
    <div class='mb-2 pb-2'>
        <span class='p-float-label'>
            <p-password id='oldPassword' formControlName='oldPassword' [toggleMask]='true'
                        [feedback]='false'> </p-password>
            <label for='oldPassword'>{{ 'OLD_PASSWORD' | transloco }}</label>
        </span>
    </div>

    <div class='mb-2 pb-2 ml3'>
        <span class='p-float-label'>
            <p-password id='newPassword' formControlName='newPassword' [toggleMask]='true'>
                <ng-template pTemplate='footer'>
                    <div class='mt-2'>{{ 'SUGGESTIONS' | transloco }}</div>
                    <ul class='pl-2 ml-2 mt-0'>
                        <li>{{ 'PASSWORD_HINT' | transloco }}</li>
                    </ul>
                </ng-template>
            </p-password>
            <label for='newPassword' class='mt-1'>{{ 'NEW_PASSWORD' | transloco }}</label>
            <button pButton class='mt-3 ml-2' type='button' icon='pi pi-info'
                    (click)='showInfo()'>&nbsp;</button>
        </span>
    </div>

    <div class='mb-2 pb-2 pt-3'>
        <span class='p-float-label'>
            <p-password id='confirmPassword' formControlName='confirmPassword' [toggleMask]='true'
                        [feedback]='false'></p-password>
            <label for='confirmPassword'>{{ 'NEW_PASSWORD_REPEATED' | transloco }}</label>
        </span>
    </div>

    <button pButton type='submit'
            [disabled]='!changePasswordForm.valid'>{{ 'CHANGE_PASS' | transloco }}</button>
</form>
