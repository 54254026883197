/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PageDataPlayerOverviewDto } from '../../models/page-data-player-overview-dto';
import { VerificationStatus } from '../../models/verification-status';

export interface GetPlayers$Params {
  agentId: number;
  pageNumber: number;
  pageSize: number;
  verificationStatus?: VerificationStatus;
  customizedBofTemplates?: boolean;
  fromDate?: string;
  toDate?: string;
  fullNamePattern?: string;
  usernamePattern?: string;
}

export function getPlayers(http: HttpClient, rootUrl: string, params: GetPlayers$Params, context?: HttpContext): Observable<StrictHttpResponse<PageDataPlayerOverviewDto>> {
  const rb = new RequestBuilder(rootUrl, getPlayers.PATH, 'get');
  if (params) {
    rb.path('agentId', params.agentId, {});
    rb.path('pageNumber', params.pageNumber, {});
    rb.path('pageSize', params.pageSize, {});
    rb.query('verificationStatus', params.verificationStatus, {});
    rb.query('customizedBofTemplates', params.customizedBofTemplates, {});
    rb.query('fromDate', params.fromDate, {});
    rb.query('toDate', params.toDate, {});
    rb.query('fullNamePattern', params.fullNamePattern, {});
    rb.query('usernamePattern', params.usernamePattern, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<PageDataPlayerOverviewDto>;
    })
  );
}

getPlayers.PATH = '/player/all/{agentId}/{pageNumber}/{pageSize}';
