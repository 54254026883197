/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SportWithTranslationDto } from '../../models/sport-with-translation-dto';

export interface GetSportWithTranslation$Params {
  languageId: string;
  sportId: number;
}

export function getSportWithTranslation(http: HttpClient, rootUrl: string, params: GetSportWithTranslation$Params, context?: HttpContext): Observable<StrictHttpResponse<SportWithTranslationDto>> {
  const rb = new RequestBuilder(rootUrl, getSportWithTranslation.PATH, 'get');
  if (params) {
    rb.path('languageId', params.languageId, {});
    rb.path('sportId', params.sportId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<SportWithTranslationDto>;
    })
  );
}

getSportWithTranslation.PATH = '/bof-entity/sport/with-translation/{languageId}/{sportId}';
