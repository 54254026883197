export class WebSocketError extends Error {
    constructor(
        msg: string,
        private statusCode?: number
    ) {
        super(msg)
        Object.setPrototypeOf(this, WebSocketError.prototype)
    }

    getStatusCode(): number {
        return this.statusCode
    }

    getMessage(): string {
        return this.message
    }

    getFormattedMessage(): string {
        return this.statusCode
            ? `WebSocketError: Status => ${this.statusCode}, Message => ${this.message}`
            : `WebSocketError: Message => ${this.message}`
    }
}
