/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { BofTmplTopTournamentItemDto } from '../models/bof-tmpl-top-tournament-item-dto';
import { getTopTournamentList } from '../fn/bof-tmpl-min-top-tournament-item/get-top-tournament-list';
import { GetTopTournamentList$Params } from '../fn/bof-tmpl-min-top-tournament-item/get-top-tournament-list';
import { updateTopTournamentList } from '../fn/bof-tmpl-min-top-tournament-item/update-top-tournament-list';
import { UpdateTopTournamentList$Params } from '../fn/bof-tmpl-min-top-tournament-item/update-top-tournament-list';

@Injectable({ providedIn: 'root' })
export class BofTmplMinTopTournamentItemMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateTopTournamentList()` */
  static readonly UpdateTopTournamentListPath = '/bof-tmpl/top-tournament/item/{languageId}/{sportId}';

  /**
   * Update items.
   *
   * Permission: 'bof-tmpl-top-tournaments-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTopTournamentList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTopTournamentList$Response(params: UpdateTopTournamentList$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BofTmplTopTournamentItemDto>>> {
    return updateTopTournamentList(this.http, this.rootUrl, params, context);
  }

  /**
   * Update items.
   *
   * Permission: 'bof-tmpl-top-tournaments-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTopTournamentList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTopTournamentList(params: UpdateTopTournamentList$Params, context?: HttpContext): Observable<Array<BofTmplTopTournamentItemDto>> {
    return this.updateTopTournamentList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BofTmplTopTournamentItemDto>>): Array<BofTmplTopTournamentItemDto> => r.body)
    );
  }

  /** Path part for operation `getTopTournamentList()` */
  static readonly GetTopTournamentListPath = '/bof-tmpl/top-tournament/item/{languageId}/{sportId}/{templateId}';

  /**
   * Get list of top tournament templates.
   *
   * Permission: 'bof-tmpl-top-tournaments-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTopTournamentList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTopTournamentList$Response(params: GetTopTournamentList$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BofTmplTopTournamentItemDto>>> {
    return getTopTournamentList(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of top tournament templates.
   *
   * Permission: 'bof-tmpl-top-tournaments-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTopTournamentList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTopTournamentList(params: GetTopTournamentList$Params, context?: HttpContext): Observable<Array<BofTmplTopTournamentItemDto>> {
    return this.getTopTournamentList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BofTmplTopTournamentItemDto>>): Array<BofTmplTopTournamentItemDto> => r.body)
    );
  }

}
