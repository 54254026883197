<div *ngIf="!pageDialogForm">
    <div class="overflow">
        <div style="padding-bottom: 1rem">
            <p-skeleton height="2rem" styleClass="mb-2"></p-skeleton>
            <p-skeleton height="2rem" styleClass="mb-2"></p-skeleton>
            <p-skeleton height="2rem" styleClass="mb-2"></p-skeleton>
            <p-skeleton height="2rem" styleClass="mb-2"></p-skeleton>
            <p-skeleton height="2rem" styleClass="mb-2"></p-skeleton>
            <p-skeleton width="5rem" styleClass="mb-2"></p-skeleton>
        </div>
        <div class="skeleton">
            <p-skeleton width="5rem" styleClass="mb-2"></p-skeleton>
            <p-skeleton width="5rem" styleClass="mb-2"></p-skeleton>
        </div>
    </div>
</div>
<div *ngIf="pageDialogForm">
    <form class="form-container" [formGroup]="pageDialogForm" (ngSubmit)="onSubmitForm()" autocomplete="off">
        <div class="p-fluid">
            <div class="my-3" *ngIf="this.config.data?.actionType === 'ADD'">
                <label>{{ 'OPERATION' | transloco }}</label>
                <p-dropdown
                    [options]="operations"
                    optionLabel="name"
                    formControlName="operationId"
                    optionValue="id"
                    (onChange)="imagesChangeOnOperationChange(imagesCopy)"
                >
                </p-dropdown>
            </div>

            <div class="my-3" *ngIf="this.config.data?.actionType === 'ADD'">
                <label>{{ 'PAGE_TYPE' | transloco }}</label>
                <p-dropdown [options]="pageTypes" optionLabel="name" formControlName="pageType" optionValue="id"> </p-dropdown>
            </div>

            <div class="field gap-1" *ngIf="this.config.data?.actionType === 'ADD'">
                <label>{{ 'SUB_PAGE' | transloco }}</label>
                <p-inputNumber formControlName="subPage" [min]="0" [max]="50"> </p-inputNumber>
                <small id="username2-help" class="p-error block" *ngIf="pageDialogForm?.get('subPage')?.errors?.invalidSupPage">{{
                    pageDialogForm.get('subPage').errors['errorMsg'] | transloco
                }}</small>
            </div>

            <!-- <div class="p-my-3">
            <label>{{ 'IMAGE' | transloco }}</label>
            <p-dropdown [options]="images" optionLabel="name" formControlName="image" [(ngModel)]="selectedImage"
                [showClear]="true">
            </p-dropdown>
        </div> -->

            <div class="my-3">
                <label>{{ 'IMAGE' | transloco }}</label>
                <p-skeleton height="2rem" styleClass="mb-2" *ngIf="imagesLoading"></p-skeleton>
                <p-dropdown
                    [options]="images"
                    optionLabel="name"
                    formControlName="image"
                    [showClear]="true"
                    dataKey="value"
                    optionValue="id"
                    placeholder="{{ 'CHOOSE_IMAGE' | transloco }}"
                >
                </p-dropdown>
            </div>

            <div class="p-field">
                <label>{{ 'INFO_TEXT' | transloco }}</label>
                <input type="text" pInputText formControlName="infoText" autocomplete="off" />
            </div>

            <p-checkbox binary="true" formControlName="active" label="{{ 'ACTIVE' | transloco }}" class="p-field-checkbox">
            </p-checkbox>
        </div>

        <div class="p-dialog-footer p-0">
            <p-progressSpinner [style]="{ width: '50px', height: '50px' }" *ngIf="loading"></p-progressSpinner>

            <button
                type="submit"
                pButton
                pRipple
                class="desktop"
                [disabled]="loading || !pageDialogForm.valid"
                label="{{ 'SAVE' | transloco }}"
                icon="pi pi-check"
            ></button>

            <button
                type="button"
                pButton
                pRipple
                [disabled]="loading"
                label="{{ 'CANCEL' | transloco }}"
                icon="pi pi-times"
                class="p-button-outlined desktop"
                (click)="onCancelClick()"
            ></button>
        </div>
    </form>
</div>
