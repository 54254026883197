import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'
import { MainMenuLinksService } from '../services/main-menu-links.service'

@Injectable({
    providedIn: 'root'
})
export class RoleGuard  {
    constructor(private mainMenuLinksService: MainMenuLinksService) {}

    canActivate(route: ActivatedRouteSnapshot) {
        return route.url.length === 0 || this.mainMenuLinksService.isLinkVisible(route.url[0].path)
    }
}
