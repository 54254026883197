/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { createMailAccountConfig } from '../fn/mail/create-mail-account-config';
import { CreateMailAccountConfig$Params } from '../fn/mail/create-mail-account-config';
import { deleteMailAccountConfig } from '../fn/mail/delete-mail-account-config';
import { DeleteMailAccountConfig$Params } from '../fn/mail/delete-mail-account-config';
import { getMailAccountConfig } from '../fn/mail/get-mail-account-config';
import { GetMailAccountConfig$Params } from '../fn/mail/get-mail-account-config';
import { getMailAccountConfigs } from '../fn/mail/get-mail-account-configs';
import { GetMailAccountConfigs$Params } from '../fn/mail/get-mail-account-configs';
import { getMailAccountConfigsPage } from '../fn/mail/get-mail-account-configs-page';
import { GetMailAccountConfigsPage$Params } from '../fn/mail/get-mail-account-configs-page';
import { MailAccountConfigDto } from '../models/mail-account-config-dto';
import { MailAccountConfigItemDto } from '../models/mail-account-config-item-dto';
import { PageDataMailAccountConfigItemDto } from '../models/page-data-mail-account-config-item-dto';
import { sendTestMail } from '../fn/mail/send-test-mail';
import { SendTestMail$Params } from '../fn/mail/send-test-mail';
import { updateMailAccountConfig } from '../fn/mail/update-mail-account-config';
import { UpdateMailAccountConfig$Params } from '../fn/mail/update-mail-account-config';
import { updateMailAccountConfigPassword } from '../fn/mail/update-mail-account-config-password';
import { UpdateMailAccountConfigPassword$Params } from '../fn/mail/update-mail-account-config-password';

@Injectable({ providedIn: 'root' })
export class MailMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getMailAccountConfig()` */
  static readonly GetMailAccountConfigPath = '/mail/{mailConfigId}';

  /**
   * Get mail config item.
   *
   * Permission: 'mail-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMailAccountConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMailAccountConfig$Response(params: GetMailAccountConfig$Params, context?: HttpContext): Observable<StrictHttpResponse<MailAccountConfigDto>> {
    return getMailAccountConfig(this.http, this.rootUrl, params, context);
  }

  /**
   * Get mail config item.
   *
   * Permission: 'mail-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMailAccountConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMailAccountConfig(params: GetMailAccountConfig$Params, context?: HttpContext): Observable<MailAccountConfigDto> {
    return this.getMailAccountConfig$Response(params, context).pipe(
      map((r: StrictHttpResponse<MailAccountConfigDto>): MailAccountConfigDto => r.body)
    );
  }

  /** Path part for operation `updateMailAccountConfig()` */
  static readonly UpdateMailAccountConfigPath = '/mail/{mailConfigId}';

  /**
   * Update mail config item.
   *
   * Permission: 'mail-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMailAccountConfig()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMailAccountConfig$Response(params: UpdateMailAccountConfig$Params, context?: HttpContext): Observable<StrictHttpResponse<MailAccountConfigDto>> {
    return updateMailAccountConfig(this.http, this.rootUrl, params, context);
  }

  /**
   * Update mail config item.
   *
   * Permission: 'mail-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateMailAccountConfig$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMailAccountConfig(params: UpdateMailAccountConfig$Params, context?: HttpContext): Observable<MailAccountConfigDto> {
    return this.updateMailAccountConfig$Response(params, context).pipe(
      map((r: StrictHttpResponse<MailAccountConfigDto>): MailAccountConfigDto => r.body)
    );
  }

  /** Path part for operation `deleteMailAccountConfig()` */
  static readonly DeleteMailAccountConfigPath = '/mail/{mailConfigId}';

  /**
   * Delete mail config item.
   *
   * Permission: 'mail-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMailAccountConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMailAccountConfig$Response(params: DeleteMailAccountConfig$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteMailAccountConfig(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete mail config item.
   *
   * Permission: 'mail-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteMailAccountConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMailAccountConfig(params: DeleteMailAccountConfig$Params, context?: HttpContext): Observable<void> {
    return this.deleteMailAccountConfig$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateMailAccountConfigPassword()` */
  static readonly UpdateMailAccountConfigPasswordPath = '/mail/password/{mailConfigId}';

  /**
   * Update mail config password.
   *
   * Permission: 'mail-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMailAccountConfigPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMailAccountConfigPassword$Response(params: UpdateMailAccountConfigPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<MailAccountConfigDto>> {
    return updateMailAccountConfigPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * Update mail config password.
   *
   * Permission: 'mail-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateMailAccountConfigPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMailAccountConfigPassword(params: UpdateMailAccountConfigPassword$Params, context?: HttpContext): Observable<MailAccountConfigDto> {
    return this.updateMailAccountConfigPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<MailAccountConfigDto>): MailAccountConfigDto => r.body)
    );
  }

  /** Path part for operation `createMailAccountConfig()` */
  static readonly CreateMailAccountConfigPath = '/mail';

  /**
   * Add new mail config item.
   *
   * Permission: 'mail-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createMailAccountConfig()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createMailAccountConfig$Response(params: CreateMailAccountConfig$Params, context?: HttpContext): Observable<StrictHttpResponse<MailAccountConfigDto>> {
    return createMailAccountConfig(this.http, this.rootUrl, params, context);
  }

  /**
   * Add new mail config item.
   *
   * Permission: 'mail-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createMailAccountConfig$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createMailAccountConfig(params: CreateMailAccountConfig$Params, context?: HttpContext): Observable<MailAccountConfigDto> {
    return this.createMailAccountConfig$Response(params, context).pipe(
      map((r: StrictHttpResponse<MailAccountConfigDto>): MailAccountConfigDto => r.body)
    );
  }

  /** Path part for operation `sendTestMail()` */
  static readonly SendTestMailPath = '/mail/send/test-mail/{mailConfigId}';

  /**
   * Send test mail.
   *
   * Permission: 'mail-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendTestMail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendTestMail$Response(params: SendTestMail$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return sendTestMail(this.http, this.rootUrl, params, context);
  }

  /**
   * Send test mail.
   *
   * Permission: 'mail-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendTestMail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendTestMail(params: SendTestMail$Params, context?: HttpContext): Observable<void> {
    return this.sendTestMail$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getMailAccountConfigsPage()` */
  static readonly GetMailAccountConfigsPagePath = '/mail/page/{pageNumber}/{pageSize}';

  /**
   * Get page of mail config items.
   *
   * Permission: 'mail-edit-config'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMailAccountConfigsPage()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMailAccountConfigsPage$Response(params: GetMailAccountConfigsPage$Params, context?: HttpContext): Observable<StrictHttpResponse<PageDataMailAccountConfigItemDto>> {
    return getMailAccountConfigsPage(this.http, this.rootUrl, params, context);
  }

  /**
   * Get page of mail config items.
   *
   * Permission: 'mail-edit-config'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMailAccountConfigsPage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMailAccountConfigsPage(params: GetMailAccountConfigsPage$Params, context?: HttpContext): Observable<PageDataMailAccountConfigItemDto> {
    return this.getMailAccountConfigsPage$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageDataMailAccountConfigItemDto>): PageDataMailAccountConfigItemDto => r.body)
    );
  }

  /** Path part for operation `getMailAccountConfigs()` */
  static readonly GetMailAccountConfigsPath = '/mail/all';

  /**
   * Get list of all mail config items.
   *
   * Permission: 'mail-edit-config'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMailAccountConfigs()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMailAccountConfigs$Response(params?: GetMailAccountConfigs$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MailAccountConfigItemDto>>> {
    return getMailAccountConfigs(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of all mail config items.
   *
   * Permission: 'mail-edit-config'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMailAccountConfigs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMailAccountConfigs(params?: GetMailAccountConfigs$Params, context?: HttpContext): Observable<Array<MailAccountConfigItemDto>> {
    return this.getMailAccountConfigs$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MailAccountConfigItemDto>>): Array<MailAccountConfigItemDto> => r.body)
    );
  }

}
