/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { createWebDomain } from '../fn/web-domain/create-web-domain';
import { CreateWebDomain$Params } from '../fn/web-domain/create-web-domain';
import { deleteWebDomain } from '../fn/web-domain/delete-web-domain';
import { DeleteWebDomain$Params } from '../fn/web-domain/delete-web-domain';
import { getWebDomain } from '../fn/web-domain/get-web-domain';
import { GetWebDomain$Params } from '../fn/web-domain/get-web-domain';
import { getWebDomains } from '../fn/web-domain/get-web-domains';
import { GetWebDomains$Params } from '../fn/web-domain/get-web-domains';
import { updateWebDomain } from '../fn/web-domain/update-web-domain';
import { UpdateWebDomain$Params } from '../fn/web-domain/update-web-domain';
import { WebDomainDto } from '../models/web-domain-dto';
import { WebDomainOverviewDto } from '../models/web-domain-overview-dto';

@Injectable({ providedIn: 'root' })
export class WebDomainMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getWebDomain()` */
  static readonly GetWebDomainPath = '/web-domain/{webDomainId}';

  /**
   * Get web domain.
   *
   * Permission: 'web-sites'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWebDomain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWebDomain$Response(params: GetWebDomain$Params, context?: HttpContext): Observable<StrictHttpResponse<WebDomainDto>> {
    return getWebDomain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get web domain.
   *
   * Permission: 'web-sites'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWebDomain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWebDomain(params: GetWebDomain$Params, context?: HttpContext): Observable<WebDomainDto> {
    return this.getWebDomain$Response(params, context).pipe(
      map((r: StrictHttpResponse<WebDomainDto>): WebDomainDto => r.body)
    );
  }

  /** Path part for operation `updateWebDomain()` */
  static readonly UpdateWebDomainPath = '/web-domain/{webDomainId}';

  /**
   * Update web domain.
   *
   * Permission: 'web-sites'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateWebDomain()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWebDomain$Response(params: UpdateWebDomain$Params, context?: HttpContext): Observable<StrictHttpResponse<WebDomainDto>> {
    return updateWebDomain(this.http, this.rootUrl, params, context);
  }

  /**
   * Update web domain.
   *
   * Permission: 'web-sites'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateWebDomain$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWebDomain(params: UpdateWebDomain$Params, context?: HttpContext): Observable<WebDomainDto> {
    return this.updateWebDomain$Response(params, context).pipe(
      map((r: StrictHttpResponse<WebDomainDto>): WebDomainDto => r.body)
    );
  }

  /** Path part for operation `deleteWebDomain()` */
  static readonly DeleteWebDomainPath = '/web-domain/{webDomainId}';

  /**
   * Delete web domain.
   *
   * Permission: 'web-sites'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteWebDomain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteWebDomain$Response(params: DeleteWebDomain$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteWebDomain(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete web domain.
   *
   * Permission: 'web-sites'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteWebDomain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteWebDomain(params: DeleteWebDomain$Params, context?: HttpContext): Observable<void> {
    return this.deleteWebDomain$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `createWebDomain()` */
  static readonly CreateWebDomainPath = '/web-domain';

  /**
   * Create web domain.
   *
   * Permission: 'web-sites'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createWebDomain()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createWebDomain$Response(params: CreateWebDomain$Params, context?: HttpContext): Observable<StrictHttpResponse<WebDomainDto>> {
    return createWebDomain(this.http, this.rootUrl, params, context);
  }

  /**
   * Create web domain.
   *
   * Permission: 'web-sites'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createWebDomain$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createWebDomain(params: CreateWebDomain$Params, context?: HttpContext): Observable<WebDomainDto> {
    return this.createWebDomain$Response(params, context).pipe(
      map((r: StrictHttpResponse<WebDomainDto>): WebDomainDto => r.body)
    );
  }

  /** Path part for operation `getWebDomains()` */
  static readonly GetWebDomainsPath = '/web-domain/all';

  /**
   * Get web domains.
   *
   * Permission: 'web-sites'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWebDomains()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWebDomains$Response(params?: GetWebDomains$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<WebDomainOverviewDto>>> {
    return getWebDomains(this.http, this.rootUrl, params, context);
  }

  /**
   * Get web domains.
   *
   * Permission: 'web-sites'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWebDomains$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWebDomains(params?: GetWebDomains$Params, context?: HttpContext): Observable<Array<WebDomainOverviewDto>> {
    return this.getWebDomains$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WebDomainOverviewDto>>): Array<WebDomainOverviewDto> => r.body)
    );
  }

}
