import {Component, OnInit} from '@angular/core'
import {DynamicDialogConfig} from 'primeng/dynamicdialog'
import {SbMediaStatsDto} from 'src/app/api/mw-api/MwApiModels'
import {SbmediaStatsMwApiService} from 'src/app/api/mw-api/MwApiServices'
import {Observable} from "rxjs";

@Component({
    selector: 'app-operation-stats-dialog',
    templateUrl: './operation-stats-dialog.component.html',
    styleUrls: ['./operation-stats-dialog.component.scss']
})
export class OperationStatsDialogComponent implements OnInit {
    stats$: Observable<SbMediaStatsDto>
    constructor(private config: DynamicDialogConfig, private sbmediaStatsService: SbmediaStatsMwApiService) {}

    ngOnInit(): void {
        this.stats$ = this.sbmediaStatsService.getStats()
    }
}
