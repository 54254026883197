/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { create } from '../fn/external-record-synchronization/create';
import { Create$Params } from '../fn/external-record-synchronization/create';
import { ExtRecordSyncManualApproveDto } from '../models/ext-record-sync-manual-approve-dto';
import { ExtRecSyncDto } from '../models/ext-rec-sync-dto';
import { ExtRecSyncListDto } from '../models/ext-rec-sync-list-dto';
import { filterExtRecords } from '../fn/external-record-synchronization/filter-ext-records';
import { FilterExtRecords$Params } from '../fn/external-record-synchronization/filter-ext-records';
import { updateExtRecSync } from '../fn/external-record-synchronization/update-ext-rec-sync';
import { UpdateExtRecSync$Params } from '../fn/external-record-synchronization/update-ext-rec-sync';

@Injectable({ providedIn: 'root' })
export class ExternalRecordSynchronizationMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateExtRecSync()` */
  static readonly UpdateExtRecSyncPath = '/extRecSync';

  /**
   * Manually confirm undecided ext record sync object.
   *
   * Permission: 'externalRecordSync'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateExtRecSync()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateExtRecSync$Response(params: UpdateExtRecSync$Params, context?: HttpContext): Observable<StrictHttpResponse<ExtRecordSyncManualApproveDto>> {
    return updateExtRecSync(this.http, this.rootUrl, params, context);
  }

  /**
   * Manually confirm undecided ext record sync object.
   *
   * Permission: 'externalRecordSync'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateExtRecSync$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateExtRecSync(params: UpdateExtRecSync$Params, context?: HttpContext): Observable<ExtRecordSyncManualApproveDto> {
    return this.updateExtRecSync$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExtRecordSyncManualApproveDto>): ExtRecordSyncManualApproveDto => r.body)
    );
  }

  /** Path part for operation `create()` */
  static readonly CreatePath = '/extRecSync';

  /**
   * Create ext rec sync.
   *
   * Permission: 'externalRecordSync'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params: Create$Params, context?: HttpContext): Observable<StrictHttpResponse<ExtRecSyncDto>> {
    return create(this.http, this.rootUrl, params, context);
  }

  /**
   * Create ext rec sync.
   *
   * Permission: 'externalRecordSync'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params: Create$Params, context?: HttpContext): Observable<ExtRecSyncDto> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExtRecSyncDto>): ExtRecSyncDto => r.body)
    );
  }

  /** Path part for operation `filterExtRecords()` */
  static readonly FilterExtRecordsPath = '/extRecSync/filter';

  /**
   * Get all ExtRecSync objects.
   *
   * Permission: 'externalRecordSync'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filterExtRecords()` instead.
   *
   * This method doesn't expect any request body.
   */
  filterExtRecords$Response(params?: FilterExtRecords$Params, context?: HttpContext): Observable<StrictHttpResponse<ExtRecSyncListDto>> {
    return filterExtRecords(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all ExtRecSync objects.
   *
   * Permission: 'externalRecordSync'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `filterExtRecords$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  filterExtRecords(params?: FilterExtRecords$Params, context?: HttpContext): Observable<ExtRecSyncListDto> {
    return this.filterExtRecords$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExtRecSyncListDto>): ExtRecSyncListDto => r.body)
    );
  }

}
