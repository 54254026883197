import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'

@Injectable({
    providedIn: 'root'
})
export class HandsetService {
    isHandset$: Observable<boolean> = this.breakpointObserver
        .observe(Breakpoints.Handset || Breakpoints.Tablet)
        .pipe(
            map(breakPointState => breakPointState.matches),
            tap(matches => {
                if (matches) {
                    console.log('Handset/Tablet detected')
                } else {
                    console.log('Desktop detected')
                }
            })
        )

    constructor(private breakpointObserver: BreakpointObserver) {
    }
}
