import { Component, Input } from '@angular/core'
import { FilterRequest } from 'src/app/utils/filter-request'

@Component({
    selector: 'app-paging',
    templateUrl: './paging.component.html',
    styleUrls: ['./paging.component.scss']
})
export class PagingComponent {
    @Input() filterRequest: FilterRequest = null
}
