export enum MessageType {
    none = '',
    request = 'r',
    response = 'a',
    message = 'm'
}

export interface WebSocketAsyncMessage {
    messageType: MessageType
    messageId: number
    messageName: string
    headers: Map<string, string>
    payload?: any
}
