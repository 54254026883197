/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { addTournament } from '../fn/bof-entity-tournament/add-tournament';
import { AddTournament$Params } from '../fn/bof-entity-tournament/add-tournament';
import { deleteTournament } from '../fn/bof-entity-tournament/delete-tournament';
import { DeleteTournament$Params } from '../fn/bof-entity-tournament/delete-tournament';
import { getTournament } from '../fn/bof-entity-tournament/get-tournament';
import { GetTournament$Params } from '../fn/bof-entity-tournament/get-tournament';
import { getTournamentAllLanguages } from '../fn/bof-entity-tournament/get-tournament-all-languages';
import { GetTournamentAllLanguages$Params } from '../fn/bof-entity-tournament/get-tournament-all-languages';
import { getTournamentsWithTranslationWithPaging } from '../fn/bof-entity-tournament/get-tournaments-with-translation-with-paging';
import { GetTournamentsWithTranslationWithPaging$Params } from '../fn/bof-entity-tournament/get-tournaments-with-translation-with-paging';
import { getTournamentWithTranslation } from '../fn/bof-entity-tournament/get-tournament-with-translation';
import { GetTournamentWithTranslation$Params } from '../fn/bof-entity-tournament/get-tournament-with-translation';
import { PageDataTournamentWithTranslationDto } from '../models/page-data-tournament-with-translation-dto';
import { TournamentDto } from '../models/tournament-dto';
import { TournamentTranslationDto } from '../models/tournament-translation-dto';
import { TournamentWithTranslationDto } from '../models/tournament-with-translation-dto';
import { updateTournament } from '../fn/bof-entity-tournament/update-tournament';
import { UpdateTournament$Params } from '../fn/bof-entity-tournament/update-tournament';
import { updateTournamentTranslations } from '../fn/bof-entity-tournament/update-tournament-translations';
import { UpdateTournamentTranslations$Params } from '../fn/bof-entity-tournament/update-tournament-translations';

@Injectable({ providedIn: 'root' })
export class BofEntityTournamentMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateTournament()` */
  static readonly UpdateTournamentPath = '/bof-entity/tournament';

  /**
   * Update tournament.
   *
   * Permission: 'bof-settings-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTournament()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTournament$Response(params: UpdateTournament$Params, context?: HttpContext): Observable<StrictHttpResponse<TournamentDto>> {
    return updateTournament(this.http, this.rootUrl, params, context);
  }

  /**
   * Update tournament.
   *
   * Permission: 'bof-settings-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTournament$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTournament(params: UpdateTournament$Params, context?: HttpContext): Observable<TournamentDto> {
    return this.updateTournament$Response(params, context).pipe(
      map((r: StrictHttpResponse<TournamentDto>): TournamentDto => r.body)
    );
  }

  /** Path part for operation `addTournament()` */
  static readonly AddTournamentPath = '/bof-entity/tournament';

  /**
   * Add tournament.
   *
   * Permission: 'bof-settings-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addTournament()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addTournament$Response(params: AddTournament$Params, context?: HttpContext): Observable<StrictHttpResponse<TournamentDto>> {
    return addTournament(this.http, this.rootUrl, params, context);
  }

  /**
   * Add tournament.
   *
   * Permission: 'bof-settings-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addTournament$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addTournament(params: AddTournament$Params, context?: HttpContext): Observable<TournamentDto> {
    return this.addTournament$Response(params, context).pipe(
      map((r: StrictHttpResponse<TournamentDto>): TournamentDto => r.body)
    );
  }

  /** Path part for operation `updateTournamentTranslations()` */
  static readonly UpdateTournamentTranslationsPath = '/bof-entity/tournament/translations';

  /**
   * Update tournament translations.
   *
   * Permission: 'bof-settings-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTournamentTranslations()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTournamentTranslations$Response(params: UpdateTournamentTranslations$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TournamentTranslationDto>>> {
    return updateTournamentTranslations(this.http, this.rootUrl, params, context);
  }

  /**
   * Update tournament translations.
   *
   * Permission: 'bof-settings-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTournamentTranslations$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTournamentTranslations(params: UpdateTournamentTranslations$Params, context?: HttpContext): Observable<Array<TournamentTranslationDto>> {
    return this.updateTournamentTranslations$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TournamentTranslationDto>>): Array<TournamentTranslationDto> => r.body)
    );
  }

  /** Path part for operation `getTournament()` */
  static readonly GetTournamentPath = '/bof-entity/tournament/{tournamentId}';

  /**
   * Get one tournament.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTournament()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTournament$Response(params: GetTournament$Params, context?: HttpContext): Observable<StrictHttpResponse<TournamentDto>> {
    return getTournament(this.http, this.rootUrl, params, context);
  }

  /**
   * Get one tournament.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTournament$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTournament(params: GetTournament$Params, context?: HttpContext): Observable<TournamentDto> {
    return this.getTournament$Response(params, context).pipe(
      map((r: StrictHttpResponse<TournamentDto>): TournamentDto => r.body)
    );
  }

  /** Path part for operation `deleteTournament()` */
  static readonly DeleteTournamentPath = '/bof-entity/tournament/{tournamentId}';

  /**
   * Delete tournament.
   *
   * Permission: 'bof-settings-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTournament()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTournament$Response(params: DeleteTournament$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteTournament(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete tournament.
   *
   * Permission: 'bof-settings-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTournament$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTournament(params: DeleteTournament$Params, context?: HttpContext): Observable<void> {
    return this.deleteTournament$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getTournamentWithTranslation()` */
  static readonly GetTournamentWithTranslationPath = '/bof-entity/tournament/with-translation/{languageId}/{tournamentId}';

  /**
   * Get one tournament with translation.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTournamentWithTranslation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTournamentWithTranslation$Response(params: GetTournamentWithTranslation$Params, context?: HttpContext): Observable<StrictHttpResponse<TournamentWithTranslationDto>> {
    return getTournamentWithTranslation(this.http, this.rootUrl, params, context);
  }

  /**
   * Get one tournament with translation.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTournamentWithTranslation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTournamentWithTranslation(params: GetTournamentWithTranslation$Params, context?: HttpContext): Observable<TournamentWithTranslationDto> {
    return this.getTournamentWithTranslation$Response(params, context).pipe(
      map((r: StrictHttpResponse<TournamentWithTranslationDto>): TournamentWithTranslationDto => r.body)
    );
  }

  /** Path part for operation `getTournamentsWithTranslationWithPaging()` */
  static readonly GetTournamentsWithTranslationWithPagingPath = '/bof-entity/tournament/with-translation/all/{languageId}/{sportId}';

  /**
   * Get list of tournaments, with paging.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTournamentsWithTranslationWithPaging()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTournamentsWithTranslationWithPaging$Response(params: GetTournamentsWithTranslationWithPaging$Params, context?: HttpContext): Observable<StrictHttpResponse<PageDataTournamentWithTranslationDto>> {
    return getTournamentsWithTranslationWithPaging(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of tournaments, with paging.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTournamentsWithTranslationWithPaging$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTournamentsWithTranslationWithPaging(params: GetTournamentsWithTranslationWithPaging$Params, context?: HttpContext): Observable<PageDataTournamentWithTranslationDto> {
    return this.getTournamentsWithTranslationWithPaging$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageDataTournamentWithTranslationDto>): PageDataTournamentWithTranslationDto => r.body)
    );
  }

  /** Path part for operation `getTournamentAllLanguages()` */
  static readonly GetTournamentAllLanguagesPath = '/bof-entity/tournament/all-languages/{tournamentId}';

  /**
   * Get one tournament: all languages.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTournamentAllLanguages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTournamentAllLanguages$Response(params: GetTournamentAllLanguages$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TournamentTranslationDto>>> {
    return getTournamentAllLanguages(this.http, this.rootUrl, params, context);
  }

  /**
   * Get one tournament: all languages.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTournamentAllLanguages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTournamentAllLanguages(params: GetTournamentAllLanguages$Params, context?: HttpContext): Observable<Array<TournamentTranslationDto>> {
    return this.getTournamentAllLanguages$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TournamentTranslationDto>>): Array<TournamentTranslationDto> => r.body)
    );
  }

}
