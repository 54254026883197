import { Component } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { DynamicDialogRef } from 'primeng/dynamicdialog'

@Component({
    selector: 'app-manual-approve-dialog',
    templateUrl: './manual-approve-dialog.component.html',
    styleUrls: ['./manual-approve-dialog.component.scss']
})
export class ManualApproveDialogComponent {
    loading = false

    manualApproveForm = new UntypedFormGroup({
        textarea: new UntypedFormControl(null)
    })

    constructor(private dialogRef: DynamicDialogRef) {}

    /** Geting user comment after clicking Ok button and closing the dialog box */
    onSaveClick() {
        this.loading = true
        this.dialogRef.close(this.manualApproveForm.value.textarea)
    }

    onCancelClick() {
        this.dialogRef.close()
    }
}
