/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { addTopTournamentTemplate } from '../fn/bof-tmpl-min-top-tournament/add-top-tournament-template';
import { AddTopTournamentTemplate$Params } from '../fn/bof-tmpl-min-top-tournament/add-top-tournament-template';
import { BofTmplDto } from '../models/bof-tmpl-dto';
import { deleteTopTournamentTemplate } from '../fn/bof-tmpl-min-top-tournament/delete-top-tournament-template';
import { DeleteTopTournamentTemplate$Params } from '../fn/bof-tmpl-min-top-tournament/delete-top-tournament-template';
import { getTopTournamentTemplates } from '../fn/bof-tmpl-min-top-tournament/get-top-tournament-templates';
import { GetTopTournamentTemplates$Params } from '../fn/bof-tmpl-min-top-tournament/get-top-tournament-templates';
import { renameTopTournamentTemplate } from '../fn/bof-tmpl-min-top-tournament/rename-top-tournament-template';
import { RenameTopTournamentTemplate$Params } from '../fn/bof-tmpl-min-top-tournament/rename-top-tournament-template';

@Injectable({ providedIn: 'root' })
export class BofTmplMinTopTournamentMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `renameTopTournamentTemplate()` */
  static readonly RenameTopTournamentTemplatePath = '/bof-tmpl/top-tournament';

  /**
   * Rename template.
   *
   * Permission: 'bof-tmpl-top-tournaments-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `renameTopTournamentTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  renameTopTournamentTemplate$Response(params: RenameTopTournamentTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<BofTmplDto>> {
    return renameTopTournamentTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Rename template.
   *
   * Permission: 'bof-tmpl-top-tournaments-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `renameTopTournamentTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  renameTopTournamentTemplate(params: RenameTopTournamentTemplate$Params, context?: HttpContext): Observable<BofTmplDto> {
    return this.renameTopTournamentTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<BofTmplDto>): BofTmplDto => r.body)
    );
  }

  /** Path part for operation `addTopTournamentTemplate()` */
  static readonly AddTopTournamentTemplatePath = '/bof-tmpl/top-tournament';

  /**
   * Add new template.
   *
   * Permission: 'bof-tmpl-top-tournaments-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addTopTournamentTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addTopTournamentTemplate$Response(params: AddTopTournamentTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<BofTmplDto>> {
    return addTopTournamentTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Add new template.
   *
   * Permission: 'bof-tmpl-top-tournaments-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addTopTournamentTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addTopTournamentTemplate(params: AddTopTournamentTemplate$Params, context?: HttpContext): Observable<BofTmplDto> {
    return this.addTopTournamentTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<BofTmplDto>): BofTmplDto => r.body)
    );
  }

  /** Path part for operation `getTopTournamentTemplates()` */
  static readonly GetTopTournamentTemplatesPath = '/bof-tmpl/top-tournament/all';

  /**
   * Get list of all templates.
   *
   * Permission: 'bof-tmpl-top-tournaments-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTopTournamentTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTopTournamentTemplates$Response(params?: GetTopTournamentTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BofTmplDto>>> {
    return getTopTournamentTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of all templates.
   *
   * Permission: 'bof-tmpl-top-tournaments-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTopTournamentTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTopTournamentTemplates(params?: GetTopTournamentTemplates$Params, context?: HttpContext): Observable<Array<BofTmplDto>> {
    return this.getTopTournamentTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BofTmplDto>>): Array<BofTmplDto> => r.body)
    );
  }

  /** Path part for operation `deleteTopTournamentTemplate()` */
  static readonly DeleteTopTournamentTemplatePath = '/bof-tmpl/top-tournament/{id}';

  /**
   * Delete template.
   *
   * Permission: 'bof-tmpl-top-tournaments-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTopTournamentTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTopTournamentTemplate$Response(params: DeleteTopTournamentTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteTopTournamentTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete template.
   *
   * Permission: 'bof-tmpl-top-tournaments-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTopTournamentTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTopTournamentTemplate(params: DeleteTopTournamentTemplate$Params, context?: HttpContext): Observable<void> {
    return this.deleteTopTournamentTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
