/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { addParticipant } from '../fn/bof-entity-participant/add-participant';
import { AddParticipant$Params } from '../fn/bof-entity-participant/add-participant';
import { deleteParticipant } from '../fn/bof-entity-participant/delete-participant';
import { DeleteParticipant$Params } from '../fn/bof-entity-participant/delete-participant';
import { getParticipant } from '../fn/bof-entity-participant/get-participant';
import { GetParticipant$Params } from '../fn/bof-entity-participant/get-participant';
import { getParticipantAllLanguages } from '../fn/bof-entity-participant/get-participant-all-languages';
import { GetParticipantAllLanguages$Params } from '../fn/bof-entity-participant/get-participant-all-languages';
import { getParticipantsWithTranslationWithPaging } from '../fn/bof-entity-participant/get-participants-with-translation-with-paging';
import { GetParticipantsWithTranslationWithPaging$Params } from '../fn/bof-entity-participant/get-participants-with-translation-with-paging';
import { getParticipantWithTranslation } from '../fn/bof-entity-participant/get-participant-with-translation';
import { GetParticipantWithTranslation$Params } from '../fn/bof-entity-participant/get-participant-with-translation';
import { PageDataParticipantWithTranslationDto } from '../models/page-data-participant-with-translation-dto';
import { ParticipantDto } from '../models/participant-dto';
import { ParticipantTranslationDto } from '../models/participant-translation-dto';
import { ParticipantWithTranslationDto } from '../models/participant-with-translation-dto';
import { updateParticipant } from '../fn/bof-entity-participant/update-participant';
import { UpdateParticipant$Params } from '../fn/bof-entity-participant/update-participant';
import { updateParticipantTranslations } from '../fn/bof-entity-participant/update-participant-translations';
import { UpdateParticipantTranslations$Params } from '../fn/bof-entity-participant/update-participant-translations';

@Injectable({ providedIn: 'root' })
export class BofEntityParticipantMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateParticipant()` */
  static readonly UpdateParticipantPath = '/bof-entity/participant';

  /**
   * Update participant.
   *
   * Permission: 'bof-settings-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateParticipant()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateParticipant$Response(params: UpdateParticipant$Params, context?: HttpContext): Observable<StrictHttpResponse<ParticipantDto>> {
    return updateParticipant(this.http, this.rootUrl, params, context);
  }

  /**
   * Update participant.
   *
   * Permission: 'bof-settings-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateParticipant$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateParticipant(params: UpdateParticipant$Params, context?: HttpContext): Observable<ParticipantDto> {
    return this.updateParticipant$Response(params, context).pipe(
      map((r: StrictHttpResponse<ParticipantDto>): ParticipantDto => r.body)
    );
  }

  /** Path part for operation `addParticipant()` */
  static readonly AddParticipantPath = '/bof-entity/participant';

  /**
   * Add participant.
   *
   * Permission: 'bof-settings-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addParticipant()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addParticipant$Response(params: AddParticipant$Params, context?: HttpContext): Observable<StrictHttpResponse<ParticipantDto>> {
    return addParticipant(this.http, this.rootUrl, params, context);
  }

  /**
   * Add participant.
   *
   * Permission: 'bof-settings-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addParticipant$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addParticipant(params: AddParticipant$Params, context?: HttpContext): Observable<ParticipantDto> {
    return this.addParticipant$Response(params, context).pipe(
      map((r: StrictHttpResponse<ParticipantDto>): ParticipantDto => r.body)
    );
  }

  /** Path part for operation `updateParticipantTranslations()` */
  static readonly UpdateParticipantTranslationsPath = '/bof-entity/participant/translations';

  /**
   * Update participant translations.
   *
   * Permission: 'bof-settings-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateParticipantTranslations()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateParticipantTranslations$Response(params: UpdateParticipantTranslations$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ParticipantTranslationDto>>> {
    return updateParticipantTranslations(this.http, this.rootUrl, params, context);
  }

  /**
   * Update participant translations.
   *
   * Permission: 'bof-settings-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateParticipantTranslations$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateParticipantTranslations(params: UpdateParticipantTranslations$Params, context?: HttpContext): Observable<Array<ParticipantTranslationDto>> {
    return this.updateParticipantTranslations$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ParticipantTranslationDto>>): Array<ParticipantTranslationDto> => r.body)
    );
  }

  /** Path part for operation `getParticipant()` */
  static readonly GetParticipantPath = '/bof-entity/participant/{participantId}';

  /**
   * Get one participant.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getParticipant()` instead.
   *
   * This method doesn't expect any request body.
   */
  getParticipant$Response(params: GetParticipant$Params, context?: HttpContext): Observable<StrictHttpResponse<ParticipantDto>> {
    return getParticipant(this.http, this.rootUrl, params, context);
  }

  /**
   * Get one participant.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getParticipant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getParticipant(params: GetParticipant$Params, context?: HttpContext): Observable<ParticipantDto> {
    return this.getParticipant$Response(params, context).pipe(
      map((r: StrictHttpResponse<ParticipantDto>): ParticipantDto => r.body)
    );
  }

  /** Path part for operation `deleteParticipant()` */
  static readonly DeleteParticipantPath = '/bof-entity/participant/{participantId}';

  /**
   * Delete participant.
   *
   * Permission: 'bof-settings-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteParticipant()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteParticipant$Response(params: DeleteParticipant$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteParticipant(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete participant.
   *
   * Permission: 'bof-settings-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteParticipant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteParticipant(params: DeleteParticipant$Params, context?: HttpContext): Observable<void> {
    return this.deleteParticipant$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getParticipantWithTranslation()` */
  static readonly GetParticipantWithTranslationPath = '/bof-entity/participant/with-translation/{languageId}/{participantId}';

  /**
   * Get one participant with translation.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getParticipantWithTranslation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getParticipantWithTranslation$Response(params: GetParticipantWithTranslation$Params, context?: HttpContext): Observable<StrictHttpResponse<ParticipantWithTranslationDto>> {
    return getParticipantWithTranslation(this.http, this.rootUrl, params, context);
  }

  /**
   * Get one participant with translation.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getParticipantWithTranslation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getParticipantWithTranslation(params: GetParticipantWithTranslation$Params, context?: HttpContext): Observable<ParticipantWithTranslationDto> {
    return this.getParticipantWithTranslation$Response(params, context).pipe(
      map((r: StrictHttpResponse<ParticipantWithTranslationDto>): ParticipantWithTranslationDto => r.body)
    );
  }

  /** Path part for operation `getParticipantsWithTranslationWithPaging()` */
  static readonly GetParticipantsWithTranslationWithPagingPath = '/bof-entity/participant/with-translation/all/{languageId}/{sportId}';

  /**
   * Get list of participants, with paging.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getParticipantsWithTranslationWithPaging()` instead.
   *
   * This method doesn't expect any request body.
   */
  getParticipantsWithTranslationWithPaging$Response(params: GetParticipantsWithTranslationWithPaging$Params, context?: HttpContext): Observable<StrictHttpResponse<PageDataParticipantWithTranslationDto>> {
    return getParticipantsWithTranslationWithPaging(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of participants, with paging.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getParticipantsWithTranslationWithPaging$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getParticipantsWithTranslationWithPaging(params: GetParticipantsWithTranslationWithPaging$Params, context?: HttpContext): Observable<PageDataParticipantWithTranslationDto> {
    return this.getParticipantsWithTranslationWithPaging$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageDataParticipantWithTranslationDto>): PageDataParticipantWithTranslationDto => r.body)
    );
  }

  /** Path part for operation `getParticipantAllLanguages()` */
  static readonly GetParticipantAllLanguagesPath = '/bof-entity/participant/all-languages/{participantId}';

  /**
   * Get one participant: all languages.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getParticipantAllLanguages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getParticipantAllLanguages$Response(params: GetParticipantAllLanguages$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ParticipantTranslationDto>>> {
    return getParticipantAllLanguages(this.http, this.rootUrl, params, context);
  }

  /**
   * Get one participant: all languages.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getParticipantAllLanguages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getParticipantAllLanguages(params: GetParticipantAllLanguages$Params, context?: HttpContext): Observable<Array<ParticipantTranslationDto>> {
    return this.getParticipantAllLanguages$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ParticipantTranslationDto>>): Array<ParticipantTranslationDto> => r.body)
    );
  }

}
