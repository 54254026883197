/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { BofTmplMarketsItemDto } from '../models/bof-tmpl-markets-item-dto';
import { getMarketsItem } from '../fn/bof-tmpl-markets-item/get-markets-item';
import { GetMarketsItem$Params } from '../fn/bof-tmpl-markets-item/get-markets-item';
import { updateMarketsItem } from '../fn/bof-tmpl-markets-item/update-markets-item';
import { UpdateMarketsItem$Params } from '../fn/bof-tmpl-markets-item/update-markets-item';

@Injectable({ providedIn: 'root' })
export class BofTmplMarketsItemMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateMarketsItem()` */
  static readonly UpdateMarketsItemPath = '/bof-tmpl/markets/item/{languageId}';

  /**
   * Update sport items.
   *
   * Permission: 'bof-tmpl-markets-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMarketsItem()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMarketsItem$Response(params: UpdateMarketsItem$Params, context?: HttpContext): Observable<StrictHttpResponse<BofTmplMarketsItemDto>> {
    return updateMarketsItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Update sport items.
   *
   * Permission: 'bof-tmpl-markets-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateMarketsItem$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMarketsItem(params: UpdateMarketsItem$Params, context?: HttpContext): Observable<BofTmplMarketsItemDto> {
    return this.updateMarketsItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<BofTmplMarketsItemDto>): BofTmplMarketsItemDto => r.body)
    );
  }

  /** Path part for operation `getMarketsItem()` */
  static readonly GetMarketsItemPath = '/bof-tmpl/markets/item/{languageId}/{templateId}/{sportId}/{marketId}';

  /**
   * Get list of sport markets codes templates.
   *
   * Permission: 'bof-tmpl-markets-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMarketsItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketsItem$Response(params: GetMarketsItem$Params, context?: HttpContext): Observable<StrictHttpResponse<BofTmplMarketsItemDto>> {
    return getMarketsItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of sport markets codes templates.
   *
   * Permission: 'bof-tmpl-markets-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMarketsItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketsItem(params: GetMarketsItem$Params, context?: HttpContext): Observable<BofTmplMarketsItemDto> {
    return this.getMarketsItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<BofTmplMarketsItemDto>): BofTmplMarketsItemDto => r.body)
    );
  }

}
