/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { reloadAllPages } from '../fn/sbmedia-reload/reload-all-pages';
import { ReloadAllPages$Params } from '../fn/sbmedia-reload/reload-all-pages';

@Injectable({ providedIn: 'root' })
export class SbmediaReloadMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `reloadAllPages()` */
  static readonly ReloadAllPagesPath = '/sb-media/reload/{operationId}';

  /**
   * Reload page.
   *
   * Permission: 'sb-media-edit-settings'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reloadAllPages()` instead.
   *
   * This method doesn't expect any request body.
   */
  reloadAllPages$Response(params: ReloadAllPages$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return reloadAllPages(this.http, this.rootUrl, params, context);
  }

  /**
   * Reload page.
   *
   * Permission: 'sb-media-edit-settings'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reloadAllPages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reloadAllPages(params: ReloadAllPages$Params, context?: HttpContext): Observable<string> {
    return this.reloadAllPages$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
