/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { createPlayer } from '../fn/player/create-player';
import { CreatePlayer$Params } from '../fn/player/create-player';
import { freezePlayer } from '../fn/player/freeze-player';
import { FreezePlayer$Params } from '../fn/player/freeze-player';
import { getPlayer } from '../fn/player/get-player';
import { GetPlayer$Params } from '../fn/player/get-player';
import { getPlayers } from '../fn/player/get-players';
import { GetPlayers$Params } from '../fn/player/get-players';
import { lockPlayer } from '../fn/player/lock-player';
import { LockPlayer$Params } from '../fn/player/lock-player';
import { PageDataPlayerOverviewDto } from '../models/page-data-player-overview-dto';
import { PlayerDto } from '../models/player-dto';
import { PlayerOverviewDto } from '../models/player-overview-dto';
import { unfreezePlayer } from '../fn/player/unfreeze-player';
import { UnfreezePlayer$Params } from '../fn/player/unfreeze-player';
import { unlockPlayer } from '../fn/player/unlock-player';
import { UnlockPlayer$Params } from '../fn/player/unlock-player';
import { updatePlayer } from '../fn/player/update-player';
import { UpdatePlayer$Params } from '../fn/player/update-player';
import { updatePlayerPassword } from '../fn/player/update-player-password';
import { UpdatePlayerPassword$Params } from '../fn/player/update-player-password';

@Injectable({ providedIn: 'root' })
export class PlayerMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getPlayer()` */
  static readonly GetPlayerPath = '/player/{playerId}';

  /**
   * Get player.
   *
   * Permission: 'players-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlayer()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlayer$Response(params: GetPlayer$Params, context?: HttpContext): Observable<StrictHttpResponse<PlayerDto>> {
    return getPlayer(this.http, this.rootUrl, params, context);
  }

  /**
   * Get player.
   *
   * Permission: 'players-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlayer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlayer(params: GetPlayer$Params, context?: HttpContext): Observable<PlayerDto> {
    return this.getPlayer$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlayerDto>): PlayerDto => r.body)
    );
  }

  /** Path part for operation `updatePlayer()` */
  static readonly UpdatePlayerPath = '/player/{playerId}';

  /**
   * Update player.
   *
   * Permission: 'players-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePlayer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePlayer$Response(params: UpdatePlayer$Params, context?: HttpContext): Observable<StrictHttpResponse<PlayerDto>> {
    return updatePlayer(this.http, this.rootUrl, params, context);
  }

  /**
   * Update player.
   *
   * Permission: 'players-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePlayer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePlayer(params: UpdatePlayer$Params, context?: HttpContext): Observable<PlayerDto> {
    return this.updatePlayer$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlayerDto>): PlayerDto => r.body)
    );
  }

  /** Path part for operation `unlockPlayer()` */
  static readonly UnlockPlayerPath = '/player/unlock/{playerId}';

  /**
   * Unlock player.
   *
   * Permission: 'players-edit-lock-status'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unlockPlayer()` instead.
   *
   * This method doesn't expect any request body.
   */
  unlockPlayer$Response(params: UnlockPlayer$Params, context?: HttpContext): Observable<StrictHttpResponse<PlayerOverviewDto>> {
    return unlockPlayer(this.http, this.rootUrl, params, context);
  }

  /**
   * Unlock player.
   *
   * Permission: 'players-edit-lock-status'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unlockPlayer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unlockPlayer(params: UnlockPlayer$Params, context?: HttpContext): Observable<PlayerOverviewDto> {
    return this.unlockPlayer$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlayerOverviewDto>): PlayerOverviewDto => r.body)
    );
  }

  /** Path part for operation `unfreezePlayer()` */
  static readonly UnfreezePlayerPath = '/player/unfreeze/{playerId}';

  /**
   * Unfreeze player.
   *
   * Permission: 'players-edit-lock-status'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unfreezePlayer()` instead.
   *
   * This method doesn't expect any request body.
   */
  unfreezePlayer$Response(params: UnfreezePlayer$Params, context?: HttpContext): Observable<StrictHttpResponse<PlayerOverviewDto>> {
    return unfreezePlayer(this.http, this.rootUrl, params, context);
  }

  /**
   * Unfreeze player.
   *
   * Permission: 'players-edit-lock-status'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unfreezePlayer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unfreezePlayer(params: UnfreezePlayer$Params, context?: HttpContext): Observable<PlayerOverviewDto> {
    return this.unfreezePlayer$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlayerOverviewDto>): PlayerOverviewDto => r.body)
    );
  }

  /** Path part for operation `updatePlayerPassword()` */
  static readonly UpdatePlayerPasswordPath = '/player/password/{playerId}';

  /**
   * Update player password.
   *
   * Permission: 'players-edit-password'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePlayerPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePlayerPassword$Response(params: UpdatePlayerPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<PlayerOverviewDto>> {
    return updatePlayerPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * Update player password.
   *
   * Permission: 'players-edit-password'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePlayerPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePlayerPassword(params: UpdatePlayerPassword$Params, context?: HttpContext): Observable<PlayerOverviewDto> {
    return this.updatePlayerPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlayerOverviewDto>): PlayerOverviewDto => r.body)
    );
  }

  /** Path part for operation `lockPlayer()` */
  static readonly LockPlayerPath = '/player/lock/{playerId}';

  /**
   * Lock player.
   *
   * Permission: 'players-edit-lock-status'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lockPlayer()` instead.
   *
   * This method doesn't expect any request body.
   */
  lockPlayer$Response(params: LockPlayer$Params, context?: HttpContext): Observable<StrictHttpResponse<PlayerOverviewDto>> {
    return lockPlayer(this.http, this.rootUrl, params, context);
  }

  /**
   * Lock player.
   *
   * Permission: 'players-edit-lock-status'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lockPlayer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lockPlayer(params: LockPlayer$Params, context?: HttpContext): Observable<PlayerOverviewDto> {
    return this.lockPlayer$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlayerOverviewDto>): PlayerOverviewDto => r.body)
    );
  }

  /** Path part for operation `freezePlayer()` */
  static readonly FreezePlayerPath = '/player/freeze/{playerId}';

  /**
   * Freeze player.
   *
   * Permission: 'players-edit-lock-status'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `freezePlayer()` instead.
   *
   * This method doesn't expect any request body.
   */
  freezePlayer$Response(params: FreezePlayer$Params, context?: HttpContext): Observable<StrictHttpResponse<PlayerOverviewDto>> {
    return freezePlayer(this.http, this.rootUrl, params, context);
  }

  /**
   * Freeze player.
   *
   * Permission: 'players-edit-lock-status'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `freezePlayer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  freezePlayer(params: FreezePlayer$Params, context?: HttpContext): Observable<PlayerOverviewDto> {
    return this.freezePlayer$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlayerOverviewDto>): PlayerOverviewDto => r.body)
    );
  }

  /** Path part for operation `createPlayer()` */
  static readonly CreatePlayerPath = '/player';

  /**
   * Create new player.
   *
   * Permission: 'players-add'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPlayer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPlayer$Response(params: CreatePlayer$Params, context?: HttpContext): Observable<StrictHttpResponse<PlayerDto>> {
    return createPlayer(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new player.
   *
   * Permission: 'players-add'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPlayer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPlayer(params: CreatePlayer$Params, context?: HttpContext): Observable<PlayerDto> {
    return this.createPlayer$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlayerDto>): PlayerDto => r.body)
    );
  }

  /** Path part for operation `getPlayers()` */
  static readonly GetPlayersPath = '/player/all/{agentId}/{pageNumber}/{pageSize}';

  /**
   * Get list of all players.
   *
   * Permission: 'players-info'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlayers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlayers$Response(params: GetPlayers$Params, context?: HttpContext): Observable<StrictHttpResponse<PageDataPlayerOverviewDto>> {
    return getPlayers(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of all players.
   *
   * Permission: 'players-info'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlayers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlayers(params: GetPlayers$Params, context?: HttpContext): Observable<PageDataPlayerOverviewDto> {
    return this.getPlayers$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageDataPlayerOverviewDto>): PageDataPlayerOverviewDto => r.body)
    );
  }

}
