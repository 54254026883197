/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CategoryWithTranslationDto } from '../../models/category-with-translation-dto';

export interface GetCategoryWithTranslation$Params {
  languageId: string;
  categoryId: number;
}

export function getCategoryWithTranslation(http: HttpClient, rootUrl: string, params: GetCategoryWithTranslation$Params, context?: HttpContext): Observable<StrictHttpResponse<CategoryWithTranslationDto>> {
  const rb = new RequestBuilder(rootUrl, getCategoryWithTranslation.PATH, 'get');
  if (params) {
    rb.path('languageId', params.languageId, {});
    rb.path('categoryId', params.categoryId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CategoryWithTranslationDto>;
    })
  );
}

getCategoryWithTranslation.PATH = '/bof-entity/category/with-translation/{languageId}/{categoryId}';
