import { Injectable } from '@angular/core'
import { SessionStorage } from './session-storage'
import { environment } from '../../environments/environment'
import { LocalSessionStorage } from './local-session-storage'
import { MemorySessionStorage } from './memory-session-storage'
import { UserSession } from './user-session'

@Injectable({
    providedIn: 'root'
})
export class SessionStorageService {
    private cachedSession: UserSession
    private sessionStorage: SessionStorage

    constructor() {
        this.sessionStorage = environment.storage === 'localStorage' ? new LocalSessionStorage() : new MemorySessionStorage()
    }

    getUserSession(): UserSession {
        if (!this.cachedSession) {
            const userSession = this.sessionStorage.getUserSession()
            this.cachedSession = userSession
            return userSession
        }
        return this.cachedSession
    }

    setUserSession(userSession: UserSession): void {
        this.sessionStorage.setUserSession(userSession)
        this.cachedSession = userSession
    }

    clearUserSession(): void {
        this.sessionStorage.clearUserSession()
        this.cachedSession = null
    }
}
