import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { AppConfig } from '../app.component'

@Injectable()
export class AppConfigService {
    configUpdate = new Subject<AppConfig>()

    config: AppConfig = {
        theme: 'light',
        inputStyle: 'outlined',
        ripple: true
    }

    configUpdate$ = this.configUpdate.asObservable()

    updateConfig(config: AppConfig) {
        this.config = config
        this.configUpdate.next(config)
    }

    getConfig() {
        return this.config
    }
}
