/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { AgentCommentDto } from '../models/agent-comment-dto';
import { AgentDetailsDto } from '../models/agent-details-dto';
import { getAgentComment } from '../fn/agent-details/get-agent-comment';
import { GetAgentComment$Params } from '../fn/agent-details/get-agent-comment';
import { getAgentDetails } from '../fn/agent-details/get-agent-details';
import { GetAgentDetails$Params } from '../fn/agent-details/get-agent-details';
import { updateAgentComment } from '../fn/agent-details/update-agent-comment';
import { UpdateAgentComment$Params } from '../fn/agent-details/update-agent-comment';
import { updateAgentDetails } from '../fn/agent-details/update-agent-details';
import { UpdateAgentDetails$Params } from '../fn/agent-details/update-agent-details';

@Injectable({ providedIn: 'root' })
export class AgentDetailsMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateAgentDetails()` */
  static readonly UpdateAgentDetailsPath = '/agent/details';

  /**
   * Update agent details properties.
   *
   * Permission: 'agents-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAgentDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAgentDetails$Response(params: UpdateAgentDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<AgentDetailsDto>> {
    return updateAgentDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * Update agent details properties.
   *
   * Permission: 'agents-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAgentDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAgentDetails(params: UpdateAgentDetails$Params, context?: HttpContext): Observable<AgentDetailsDto> {
    return this.updateAgentDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<AgentDetailsDto>): AgentDetailsDto => r.body)
    );
  }

  /** Path part for operation `updateAgentComment()` */
  static readonly UpdateAgentCommentPath = '/agent/details/comment';

  /**
   * Update agent comment property.
   *
   * Permission: 'agents-edit-comment'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAgentComment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAgentComment$Response(params: UpdateAgentComment$Params, context?: HttpContext): Observable<StrictHttpResponse<AgentCommentDto>> {
    return updateAgentComment(this.http, this.rootUrl, params, context);
  }

  /**
   * Update agent comment property.
   *
   * Permission: 'agents-edit-comment'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAgentComment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAgentComment(params: UpdateAgentComment$Params, context?: HttpContext): Observable<AgentCommentDto> {
    return this.updateAgentComment$Response(params, context).pipe(
      map((r: StrictHttpResponse<AgentCommentDto>): AgentCommentDto => r.body)
    );
  }

  /** Path part for operation `getAgentDetails()` */
  static readonly GetAgentDetailsPath = '/agent/details/{agentId}';

  /**
   * Get agent details properties.
   *
   * Permission: 'agents-info'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAgentDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgentDetails$Response(params: GetAgentDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<AgentDetailsDto>> {
    return getAgentDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * Get agent details properties.
   *
   * Permission: 'agents-info'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAgentDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgentDetails(params: GetAgentDetails$Params, context?: HttpContext): Observable<AgentDetailsDto> {
    return this.getAgentDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<AgentDetailsDto>): AgentDetailsDto => r.body)
    );
  }

  /** Path part for operation `getAgentComment()` */
  static readonly GetAgentCommentPath = '/agent/details/comment/{agentId}';

  /**
   * Get agent comment property.
   *
   * Permission: 'agents-edit-comment'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAgentComment()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgentComment$Response(params: GetAgentComment$Params, context?: HttpContext): Observable<StrictHttpResponse<AgentCommentDto>> {
    return getAgentComment(this.http, this.rootUrl, params, context);
  }

  /**
   * Get agent comment property.
   *
   * Permission: 'agents-edit-comment'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAgentComment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgentComment(params: GetAgentComment$Params, context?: HttpContext): Observable<AgentCommentDto> {
    return this.getAgentComment$Response(params, context).pipe(
      map((r: StrictHttpResponse<AgentCommentDto>): AgentCommentDto => r.body)
    );
  }

}
