/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { createPageSettings } from '../fn/sbmedia-settings/create-page-settings';
import { CreatePageSettings$Params } from '../fn/sbmedia-settings/create-page-settings';
import { deletePageSettings } from '../fn/sbmedia-settings/delete-page-settings';
import { DeletePageSettings$Params } from '../fn/sbmedia-settings/delete-page-settings';
import { getPageSettings } from '../fn/sbmedia-settings/get-page-settings';
import { GetPageSettings$Params } from '../fn/sbmedia-settings/get-page-settings';
import { getPageTypes } from '../fn/sbmedia-settings/get-page-types';
import { GetPageTypes$Params } from '../fn/sbmedia-settings/get-page-types';
import { SbMediaPageSettingsDto } from '../models/sb-media-page-settings-dto';
import { setImageForSettings } from '../fn/sbmedia-settings/set-image-for-settings';
import { SetImageForSettings$Params } from '../fn/sbmedia-settings/set-image-for-settings';
import { updatePageSettings } from '../fn/sbmedia-settings/update-page-settings';
import { UpdatePageSettings$Params } from '../fn/sbmedia-settings/update-page-settings';

@Injectable({ providedIn: 'root' })
export class SbmediaSettingsMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getPageSettings()` */
  static readonly GetPageSettingsPath = '/sb-media/settings';

  /**
   * Get sb-media settings item.
   *
   * Permission: 'sb-media-edit-settings'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPageSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPageSettings$Response(params?: GetPageSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SbMediaPageSettingsDto>>> {
    return getPageSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * Get sb-media settings item.
   *
   * Permission: 'sb-media-edit-settings'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPageSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPageSettings(params?: GetPageSettings$Params, context?: HttpContext): Observable<Array<SbMediaPageSettingsDto>> {
    return this.getPageSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SbMediaPageSettingsDto>>): Array<SbMediaPageSettingsDto> => r.body)
    );
  }

  /** Path part for operation `updatePageSettings()` */
  static readonly UpdatePageSettingsPath = '/sb-media/settings';

  /**
   * Change sb-media settings item.
   *
   * Permission: 'sb-media-edit-settings'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePageSettings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePageSettings$Response(params: UpdatePageSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<SbMediaPageSettingsDto>> {
    return updatePageSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * Change sb-media settings item.
   *
   * Permission: 'sb-media-edit-settings'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePageSettings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePageSettings(params: UpdatePageSettings$Params, context?: HttpContext): Observable<SbMediaPageSettingsDto> {
    return this.updatePageSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<SbMediaPageSettingsDto>): SbMediaPageSettingsDto => r.body)
    );
  }

  /** Path part for operation `createPageSettings()` */
  static readonly CreatePageSettingsPath = '/sb-media/settings';

  /**
   * Add new sbmedia settings item.
   *
   * Permission: 'sb-media-edit-settings'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPageSettings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPageSettings$Response(params: CreatePageSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<SbMediaPageSettingsDto>> {
    return createPageSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * Add new sbmedia settings item.
   *
   * Permission: 'sb-media-edit-settings'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPageSettings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPageSettings(params: CreatePageSettings$Params, context?: HttpContext): Observable<SbMediaPageSettingsDto> {
    return this.createPageSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<SbMediaPageSettingsDto>): SbMediaPageSettingsDto => r.body)
    );
  }

  /** Path part for operation `deletePageSettings()` */
  static readonly DeletePageSettingsPath = '/sb-media/settings';

  /**
   * Delete page settings.
   *
   * Permission: 'sb-media-edit-settings'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePageSettings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deletePageSettings$Response(params: DeletePageSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return deletePageSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete page settings.
   *
   * Permission: 'sb-media-edit-settings'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deletePageSettings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deletePageSettings(params: DeletePageSettings$Params, context?: HttpContext): Observable<string> {
    return this.deletePageSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `setImageForSettings()` */
  static readonly SetImageForSettingsPath = '/sb-media/settings/image';

  /**
   * Set image on settings item.
   *
   * Permission: 'sb-media-edit-settings'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setImageForSettings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setImageForSettings$Response(params: SetImageForSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return setImageForSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * Set image on settings item.
   *
   * Permission: 'sb-media-edit-settings'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setImageForSettings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setImageForSettings(params: SetImageForSettings$Params, context?: HttpContext): Observable<string> {
    return this.setImageForSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getPageTypes()` */
  static readonly GetPageTypesPath = '/sb-media/settings/page-types';

  /**
   * Get page types.
   *
   * Permission: 'sb-media-edit-settings'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPageTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPageTypes$Response(params?: GetPageTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getPageTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * Get page types.
   *
   * Permission: 'sb-media-edit-settings'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPageTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPageTypes(params?: GetPageTypes$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getPageTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

}
