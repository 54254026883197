import { MenuItem } from 'primeng/api'
import { BehaviorSubject, Subject, Subscription } from 'rxjs'
import { Injectable } from '@angular/core'
import { PermissionsService } from './permissions.service'
import { DeepCopy } from '../shared/deep-copy'
import { TranslocoService } from '@ngneat/transloco'
import { URL_TO_PERMISSION_MAPPING } from '../security/permissions'

const initialMenuLinks: MenuItem[] = [
    {
        id: 'agents',
        label: 'AGENTS',
        title: 'AGENTS',
        routerLink: 'agents',
        icon: 'pi pi-sitemap',
        visible: true
    },
    {
        id: 'betOverview',
        label: 'BET_OVERVIEW',
        title: 'BET_OVERVIEW',
        routerLink: ['overview'],
        icon: 'pi pi-money-bill',
        visible: true
    },
    {
        id: 'recordSync',
        label: 'RECORD_SYNC',
        title: 'RECORD_SYNC',
        routerLink: ['recordSync'],
        icon: 'pi pi-cloud-download',
        visible: true
    },
    {
        id: 'settings',
        label: 'SETTINGS',
        title: 'SETTINGS',
        icon: 'pi pi-cog',
        visible: true,
        items: [
            {
                id: 'languages',
                label: 'LANGUAGES',
                title: 'LANGUAGES',
                routerLink: 'settings/languages',
                icon: 'pi pi-language',
                visible: true
            },
            {
                id: 'currencies',
                label: 'CURRENCIES',
                title: 'CURRENCIES',
                routerLink: 'settings/currencies',
                icon: 'pi pi-euro',
                visible: true
            },
            {
                id: 'web-templates',
                label: 'webGroups.webGroups',
                title: 'webGroups.webGroups',
                routerLink: 'settings/web-templates',
                icon: 'pi pi-globe',
                visible: true
            },
            {
                id: 'mail-accounts',
                label: 'mailAccounts.mailAccounts',
                title: 'mailAccounts.mailAccounts',
                routerLink: 'settings/mail-accounts',
                icon: 'pi pi-globe',
                visible: true
            },
            {
                id: 'markets-definition',
                label: 'markets-definition.MARKETS_DEFINITION',
                title: 'markets-definition.MARKETS_DEFINITION',
                icon: 'pi pi-folder-open',
                visible: true,
                items: [
                    {
                        id: 'market-groups',
                        label: 'markets-definition.MARKET_GROUPS',
                        title: 'markets-definition.MARKET_GROUPS',
                        routerLink: 'settings/market-groups',

                        visible: true
                    },
                    {
                        id: 'markets',
                        label: 'markets-definition.MARKETS',
                        title: 'markets-definition.MARKETS',
                        routerLink: 'settings/markets',

                        visible: true
                    }
                ]
            }
        ]
    },
    {
        id: 'bof-settings',
        label: 'BETTING_OFFER',
        title: 'BETTING_OFFER',
        icon: 'pi pi-cog',
        visible: true,
        items: [
            {
                id: 'sports',
                label: 'SPORTS',
                title: 'SPORTS',
                routerLink: ['bof/sports'],
                icon: 'pi pi-th-large',
                visible: true
            },
            {
                id: 'categories',
                label: 'CATEGORIES',
                title: 'CATEGORIES',
                routerLink: ['bof/categories'],
                icon: 'pi pi-th-large',
                visible: true
            },
            {
                id: 'tournaments',
                label: 'TOURNAMENTS',
                title: 'TOURNAMENTS',
                routerLink: ['bof/tournaments'],
                icon: 'pi pi-th-large',
                visible: true
            },
            {
                id: 'participants',
                label: 'PARTICIPANTS',
                title: 'PARTICIPANTS',
                routerLink: ['bof/participants'],
                icon: 'pi pi-th-large',
                visible: true
            },
            {
                id: 'betting-offer',
                label: 'bettingOffer.bettingOffer',
                title: 'bettingOffer.bettingOffer',
                routerLink: ['bof/betting-offer'],
                icon: 'pi pi-th-large',
                visible: true
            },
            {
                id: 'bof-separator-1',
                separator: true
            },
            {
                id: 'templates',
                label: 'TEMPLATES',
                title: 'TEMPLATES',
                routerLink: [],
                icon: 'pi pi-sitemap',
                visible: true,
                items: [
                    {
                        id: 'market-templates',
                        label: 'BOF_MARKETS_TEMPLATE',
                        title: 'BOF_MARKETS_TEMPLATE',
                        routerLink: ['bof-templates/market-templates'],
                        icon: 'pi pi-th-large',
                        visible: true
                    },
                    {
                        id: 'input-codes',
                        label: 'BOF_INPUT_CODES_TEMPLATE',
                        title: 'BOF_INPUT_CODES_TEMPLATE',
                        routerLink: ['bof-templates/input-codes'],
                        icon: 'pi pi-th-large',
                        visible: true
                    },
                    {
                        id: 'sorting',
                        label: 'BOF_SORTING_TEMPLATE',
                        title: 'BOF_SORTING_TEMPLATE',
                        routerLink: ['bof-templates/sorting'],
                        icon: 'pi pi-th-large',
                        visible: true
                    },
                    {
                        id: 'min-select',
                        label: 'BOF_MIN_SELECT_TEMPLATE',
                        title: 'BOF_MIN_SELECT_TEMPLATE',
                        routerLink: ['bof-templates/min-select'],
                        icon: 'pi pi-th-large',
                        visible: true
                    },
                    {
                        id: 'top-tournament',
                        label: 'BOF_TOP_TOURNAMENT_TEMPLATE',
                        title: 'BOF_TOP_TOURNAMENT_TEMPLATE',
                        routerLink: ['bof-templates/top-tournament'],
                        icon: 'pi pi-th-large',
                        visible: true
                    },
                    {
                        id: 'offer',
                        label: 'BOF_OFFER_TEMPLATES',
                        title: 'BOF_OFFER_TEMPLATES',
                        routerLink: ['bof-templates/offer'],
                        icon: 'pi pi-th-large',
                        visible: true
                    },
                    {
                        id: 'highlighted-matches',
                        label: 'BOF_HIGHLIGHTED_MATCHES_TEMPLATES',
                        title: 'BOF_HIGHLIGHTED_MATCHES_TEMPLATES',
                        routerLink: ['bof-templates/highlighted-matches'],
                        icon: 'pi pi-th-large',
                        visible: true
                    },
                    {
                        id: 'odds-factor',
                        label: 'BOF_ODDS_FACTOR_TEMPLATES',
                        title: 'BOF_ODDS_FACTOR_TEMPLATES',
                        routerLink: ['bof-templates/odds-factor'],
                        icon: 'pi pi-th-large',
                        visible: true
                    }
                ]
            }
        ]
    },
    {
        id: 'ext-feed',
        label: 'EXT_FEED',
        title: 'EXT_FEED',
        icon: 'pi pi-cog',
        visible: true,
        items: [
            {
                id: 'ext-matches',
                label: 'EXT_MATCHES',
                title: 'EXT_MATCHES',
                routerLink: ['ext-feed/ext-matches'],
                icon: 'pi pi-th-large',
                visible: true
            }
        ]
    },
    {
        id: 'sbMedia',
        label: 'SB_MEDIA',
        title: 'SB_MEDIA',
        icon: 'pi pi-image',
        visible: true,
        items: [
            {
                id: 'sb-media-settings',
                label: 'SB_MEDIA_SETTINGS',
                title: 'SB_MEDIA_SETTINGS',
                routerLink: ['sb-media-settings'],
                icon: 'pi pi-cog',
                visible: true
            },
            {
                id: 'sb-media-pages',
                label: 'SB_MEDIA_IMAGES',
                title: 'SB_MEDIA_IMAGES',
                routerLink: ['sb-media-pages'],
                icon: 'pi pi-images',
                visible: true
            }
        ]
    },
    {
        id: 'system',
        label: 'SYSTEM',
        title: 'SYSTEM',
        icon: 'pi pi-cog',
        visible: true,
        items: [
            {
                id: 'perf',
                label: 'PERFORMANCE',
                title: 'PERFORMANCE',
                routerLink: ['perf/view'],
                icon: 'pi pi-euro',
                visible: true
            }
        ]
    }
]

@Injectable({
    providedIn: 'root'
})
export class MainMenuLinksService {
    //** Meni sa leve strane navigacije */
    public defaultLink$ = new Subject<MenuItem>()
    public mainMenuLinks$ = new BehaviorSubject<MenuItem[]>(null)
    private mainMenuLinks: MenuItem[] = initialMenuLinks

    private defaultLink: MenuItem = null

    private langChange: Subscription

    constructor(
        private transloco: TranslocoService,
        private permissionsService: PermissionsService
    ) {
        this.subscribeToPermissionsChange()
    }

    public isLinkVisible(route: string): boolean {
        return this.mainMenuLinks.some(menuItem => this.isLinkVisiblerecursive(menuItem, route))
    }

    public getTitleFromInitialMenuLinks(pathName: string): string {
        const matchingItem = this.mainMenuLinks.find(menuItem => {
            if (menuItem.hasOwnProperty('items')) {
                return menuItem.items.find(item => item?.routerLink[0] === pathName)
            } else {
                return menuItem.title === pathName
            }
        })

        if (matchingItem) {
            return matchingItem.title
        } else {
            return ''
        }
    }

    private isLinkVisiblerecursive(menuItem: MenuItem, route: string): boolean {
        if (menuItem?.routerLink && menuItem.routerLink[0] === route) {
            return menuItem.visible || menuItem?.items.some(i => i.visible)
        }
        return menuItem.items?.some(i => this.isLinkVisiblerecursive(i, route))
    }

    private updateLinksPermissions = (authorities: string[]) => {
        this.mainMenuLinks.forEach(link => this.updateLinksPermissionsRecursive(link, authorities))
    }

    private updateLinksPermissionsRecursive(link: MenuItem, authorities: string[]) {
        if (link.hasOwnProperty('items')) {
            link.items.forEach(item => this.updateLinksPermissionsRecursive(item, authorities))
            link.visible = link.items.some(l => l.visible)
        } else {
            link.visible = link.visible && authorities.some(auth => auth === URL_TO_PERMISSION_MAPPING[link.id])
        }
    }

    private calculateDefaultLink() {
        this.defaultLink = null
        this.mainMenuLinks.some(item => this.calculateDefaultLinkRecursive(item))
        this.defaultLink$.next(this.defaultLink)
    }

    private calculateDefaultLinkRecursive(link: MenuItem): boolean {
        if (link.visible) {
            this.defaultLink = typeof link.routerLink === 'object' ? link.routerLink[0] : link.routerLink
            return true
        }

        return link?.items && link.items.some(item => this.calculateDefaultLinkRecursive(item))
    }

    private subscribeToPermissionsChange() {
        this.permissionsService.loggedUserPermissions$.subscribe(authorities => {
            if (this.langChange) {
                this.langChange.unsubscribe()
            }
            this.langChange = this.transloco.selectTranslation().subscribe(() => {
                this.mainMenuLinks = DeepCopy.copy(initialMenuLinks)
                this.updateLinksPermissions(authorities)
                this.calculateDefaultLink()

                this.mainMenuLinks.forEach(menuItem => this.menuItemTranslation(menuItem))
                this.mainMenuLinks$.next(this.mainMenuLinks)
            })
        })
    }

    private menuItemTranslation(menuItem: MenuItem) {
        menuItem.label = this.transloco.translate(menuItem.title)
        menuItem.items?.forEach(m => this.menuItemTranslation(m))
    }
}
