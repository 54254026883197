<div *ngFor="let instance of (stats$ | async)">
    <h3>{{ instance.id }}</h3>
    <div *ngFor="let operation of instance.operations">
        <h4>{{ operation.operationName }}</h4>
        <div *ngFor="let pageType of operation.pageTypes">
            <span class="pre">{{ pageType.pageType }}:</span><span class="pre">{{ pageType.count }}</span>
        </div>
    </div>
</div>

