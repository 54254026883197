import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { AgentsPermissions } from '../security/agents-permissions'
import { AccessPermissions } from '../security/permissions'
import { AuthService } from '../session/auth.service'

@Injectable({
    providedIn: 'root'
})
export class PermissionsService {
    loggedUserPermissions$ = new BehaviorSubject<Array<string>>([])

    private loggedUserPermissions: Array<string> = []

    constructor(private auth: AuthService) {
        this.auth.userSession$.subscribe(userDetails => {
            if (userDetails) {
                this.loggedUserPermissions = userDetails.authorities ? userDetails.authorities : []
                this.loggedUserPermissions$.next(this.loggedUserPermissions)
            }
        })
    }

    /**
     * Check for granted permission for user
     * Example of use:
     * const hasPermission = this.authService.hasPermission(AccessPermissions.usersCreate);
     *
     * @param permissionName name of permission from permissions.ts
     * @returns true if permisssion is granted to user
     */
    public hasPermission(permissionName: string): boolean {
        if (this.loggedUserPermissions && this.loggedUserPermissions.filter(a => a === permissionName).length === 0) {
            console.table(`Permission ${permissionName} not granted for user`)
            return false
        }
        return true
    }

    agentPermissions(permissions: string[]): AgentsPermissions {
        return {
            // Agent
            agentsView: permissions.includes(AccessPermissions.agentsView),
            agentsInfo: permissions.includes(AccessPermissions.agentsInfo),
            agentsAdd: permissions.includes(AccessPermissions.agentsAdd),
            agentsEdit: permissions.includes(AccessPermissions.agentsEdit),
            agentsEditHiddenStatus: permissions.includes(AccessPermissions.agentsEditHiddenStatus),
            agentsEditLockStatus: permissions.includes(AccessPermissions.agentsEditLockStatus),
            agentsEditComment: permissions.includes(AccessPermissions.agentsEditComment),
            agentsEditPermission: permissions.includes(AccessPermissions.agentsEditPermission),
            // Agent: user
            usersAdd: permissions.includes(AccessPermissions.usersAdd),
            usersView: permissions.includes(AccessPermissions.usersView),
            usersEdit: permissions.includes(AccessPermissions.usersEdit),
            usersEditLockStatus: permissions.includes(AccessPermissions.usersEditLockStatus),
            usersEditPassword: permissions.includes(AccessPermissions.usersEditPassword),
            usersEditOtherUsersPassword: permissions.includes(AccessPermissions.usersEditOtherUsersPassword),
            usersEditPermission: permissions.includes(AccessPermissions.usersEditPermission),
            // Agent: player
            playersView: permissions.includes(AccessPermissions.playersView),
            playersInfo: permissions.includes(AccessPermissions.playersInfo),
            playersExport: permissions.includes(AccessPermissions.playersExport),
            playersAdd: permissions.includes(AccessPermissions.playersAdd),
            playersViewCity: permissions.includes(AccessPermissions.playersViewCity),
            playersEdit: permissions.includes(AccessPermissions.playersEdit),
            playersEditLockStatus: permissions.includes(AccessPermissions.playersEditLockStatus),
            playersEditPassword: permissions.includes(AccessPermissions.playersEditPassword),
            playersEditRegistrationStatus: permissions.includes(AccessPermissions.playersEditRegistrationStatus)
        }
    }
}
