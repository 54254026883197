/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { BofSearchParamsDto } from '../../models/bof-search-params-dto';
import { BofSearchResultDto } from '../../models/bof-search-result-dto';

export interface GetFakeBofSearchResultDto$Params {
  bofSearchParamsDto: BofSearchParamsDto;
}

export function getFakeBofSearchResultDto(http: HttpClient, rootUrl: string, params: GetFakeBofSearchResultDto$Params, context?: HttpContext): Observable<StrictHttpResponse<BofSearchResultDto>> {
  const rb = new RequestBuilder(rootUrl, getFakeBofSearchResultDto.PATH, 'get');
  if (params) {
    rb.query('bofSearchParamsDto', params.bofSearchParamsDto, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<BofSearchResultDto>;
    })
  );
}

getFakeBofSearchResultDto.PATH = '/bof/search';
