/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { BofTmplOfferItemDto } from '../models/bof-tmpl-offer-item-dto';
import { getCategoryOfferTemplates } from '../fn/bof-tmpl-offer-item/get-category-offer-templates';
import { GetCategoryOfferTemplates$Params } from '../fn/bof-tmpl-offer-item/get-category-offer-templates';
import { getSportOfferTemplates } from '../fn/bof-tmpl-offer-item/get-sport-offer-templates';
import { GetSportOfferTemplates$Params } from '../fn/bof-tmpl-offer-item/get-sport-offer-templates';
import { getTournamentOfferTemplates } from '../fn/bof-tmpl-offer-item/get-tournament-offer-templates';
import { GetTournamentOfferTemplates$Params } from '../fn/bof-tmpl-offer-item/get-tournament-offer-templates';
import { updateCategoryOfferTemplates } from '../fn/bof-tmpl-offer-item/update-category-offer-templates';
import { UpdateCategoryOfferTemplates$Params } from '../fn/bof-tmpl-offer-item/update-category-offer-templates';
import { updateSportOfferTemplates } from '../fn/bof-tmpl-offer-item/update-sport-offer-templates';
import { UpdateSportOfferTemplates$Params } from '../fn/bof-tmpl-offer-item/update-sport-offer-templates';
import { updateTournamentOfferTemplates } from '../fn/bof-tmpl-offer-item/update-tournament-offer-templates';
import { UpdateTournamentOfferTemplates$Params } from '../fn/bof-tmpl-offer-item/update-tournament-offer-templates';

@Injectable({ providedIn: 'root' })
export class BofTmplOfferItemMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateTournamentOfferTemplates()` */
  static readonly UpdateTournamentOfferTemplatesPath = '/bof-tmpl/offer/item/tournament/{languageId}/{sportId}';

  /**
   * Update tournament items.
   *
   * Permission: 'bof-tmpl-offer-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTournamentOfferTemplates()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTournamentOfferTemplates$Response(params: UpdateTournamentOfferTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BofTmplOfferItemDto>>> {
    return updateTournamentOfferTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * Update tournament items.
   *
   * Permission: 'bof-tmpl-offer-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTournamentOfferTemplates$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTournamentOfferTemplates(params: UpdateTournamentOfferTemplates$Params, context?: HttpContext): Observable<Array<BofTmplOfferItemDto>> {
    return this.updateTournamentOfferTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BofTmplOfferItemDto>>): Array<BofTmplOfferItemDto> => r.body)
    );
  }

  /** Path part for operation `updateSportOfferTemplates()` */
  static readonly UpdateSportOfferTemplatesPath = '/bof-tmpl/offer/item/sport/{languageId}';

  /**
   * Update sport items.
   *
   * Permission: 'bof-tmpl-offer-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSportOfferTemplates()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSportOfferTemplates$Response(params: UpdateSportOfferTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BofTmplOfferItemDto>>> {
    return updateSportOfferTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * Update sport items.
   *
   * Permission: 'bof-tmpl-offer-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateSportOfferTemplates$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSportOfferTemplates(params: UpdateSportOfferTemplates$Params, context?: HttpContext): Observable<Array<BofTmplOfferItemDto>> {
    return this.updateSportOfferTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BofTmplOfferItemDto>>): Array<BofTmplOfferItemDto> => r.body)
    );
  }

  /** Path part for operation `updateCategoryOfferTemplates()` */
  static readonly UpdateCategoryOfferTemplatesPath = '/bof-tmpl/offer/item/category/{languageId}/{sportId}';

  /**
   * Update category items.
   *
   * Permission: 'bof-tmpl-offer-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCategoryOfferTemplates()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCategoryOfferTemplates$Response(params: UpdateCategoryOfferTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BofTmplOfferItemDto>>> {
    return updateCategoryOfferTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * Update category items.
   *
   * Permission: 'bof-tmpl-offer-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCategoryOfferTemplates$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCategoryOfferTemplates(params: UpdateCategoryOfferTemplates$Params, context?: HttpContext): Observable<Array<BofTmplOfferItemDto>> {
    return this.updateCategoryOfferTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BofTmplOfferItemDto>>): Array<BofTmplOfferItemDto> => r.body)
    );
  }

  /** Path part for operation `getTournamentOfferTemplates()` */
  static readonly GetTournamentOfferTemplatesPath = '/bof-tmpl/offer/item/tournament/{languageId}/{sportId}/{templateId}';

  /**
   * Get list of tournament offer templates.
   *
   * Permission: 'bof-tmpl-offer-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTournamentOfferTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTournamentOfferTemplates$Response(params: GetTournamentOfferTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BofTmplOfferItemDto>>> {
    return getTournamentOfferTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of tournament offer templates.
   *
   * Permission: 'bof-tmpl-offer-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTournamentOfferTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTournamentOfferTemplates(params: GetTournamentOfferTemplates$Params, context?: HttpContext): Observable<Array<BofTmplOfferItemDto>> {
    return this.getTournamentOfferTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BofTmplOfferItemDto>>): Array<BofTmplOfferItemDto> => r.body)
    );
  }

  /** Path part for operation `getSportOfferTemplates()` */
  static readonly GetSportOfferTemplatesPath = '/bof-tmpl/offer/item/sport/{languageId}/{templateId}';

  /**
   * Get list of sport offer templates.
   *
   * Permission: 'bof-tmpl-offer-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSportOfferTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSportOfferTemplates$Response(params: GetSportOfferTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BofTmplOfferItemDto>>> {
    return getSportOfferTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of sport offer templates.
   *
   * Permission: 'bof-tmpl-offer-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSportOfferTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSportOfferTemplates(params: GetSportOfferTemplates$Params, context?: HttpContext): Observable<Array<BofTmplOfferItemDto>> {
    return this.getSportOfferTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BofTmplOfferItemDto>>): Array<BofTmplOfferItemDto> => r.body)
    );
  }

  /** Path part for operation `getCategoryOfferTemplates()` */
  static readonly GetCategoryOfferTemplatesPath = '/bof-tmpl/offer/item/category/{languageId}/{sportId}/{templateId}';

  /**
   * Get list of category offer templates.
   *
   * Permission: 'bof-tmpl-offer-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCategoryOfferTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCategoryOfferTemplates$Response(params: GetCategoryOfferTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BofTmplOfferItemDto>>> {
    return getCategoryOfferTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of category offer templates.
   *
   * Permission: 'bof-tmpl-offer-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCategoryOfferTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCategoryOfferTemplates(params: GetCategoryOfferTemplates$Params, context?: HttpContext): Observable<Array<BofTmplOfferItemDto>> {
    return this.getCategoryOfferTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BofTmplOfferItemDto>>): Array<BofTmplOfferItemDto> => r.body)
    );
  }

}
