import { SessionStorage } from './session-storage'
import { UserSession } from './user-session'

export class MemorySessionStorage implements SessionStorage {
    private userSession: UserSession

    getUserSession(): UserSession {
        if (this.userSession) {
            return { ...this.userSession }
        }
        return null
    }

    setUserSession(userSession: UserSession): void {
        this.userSession = { ...userSession }
    }

    clearUserSession(): void {
        this.userSession = null
    }
}
