/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { createUser } from '../fn/user/create-user';
import { CreateUser$Params } from '../fn/user/create-user';
import { exists } from '../fn/user/exists';
import { Exists$Params } from '../fn/user/exists';
import { getMe } from '../fn/user/get-me';
import { GetMe$Params } from '../fn/user/get-me';
import { getUser } from '../fn/user/get-user';
import { GetUser$Params } from '../fn/user/get-user';
import { getUserByAgentId } from '../fn/user/get-user-by-agent-id';
import { GetUserByAgentId$Params } from '../fn/user/get-user-by-agent-id';
import { getUsers } from '../fn/user/get-users';
import { GetUsers$Params } from '../fn/user/get-users';
import { lockUser } from '../fn/user/lock-user';
import { LockUser$Params } from '../fn/user/lock-user';
import { PageDataUserDto } from '../models/page-data-user-dto';
import { unlockUser } from '../fn/user/unlock-user';
import { UnlockUser$Params } from '../fn/user/unlock-user';
import { updateOtherUserPassword } from '../fn/user/update-other-user-password';
import { UpdateOtherUserPassword$Params } from '../fn/user/update-other-user-password';
import { updatePassword } from '../fn/user/update-password';
import { UpdatePassword$Params } from '../fn/user/update-password';
import { updateUser } from '../fn/user/update-user';
import { UpdateUser$Params } from '../fn/user/update-user';
import { UserDetailsDto } from '../models/user-details-dto';
import { UserDto } from '../models/user-dto';

@Injectable({ providedIn: 'root' })
export class UserMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getUser()` */
  static readonly GetUserPath = '/user/{username}';

  /**
   * Get data for one user.
   *
   * Permission: 'users-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser$Response(params: GetUser$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return getUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Get data for one user.
   *
   * Permission: 'users-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser(params: GetUser$Params, context?: HttpContext): Observable<UserDto> {
    return this.getUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

  /** Path part for operation `updateUser()` */
  static readonly UpdateUserPath = '/user/{username}';

  /**
   * Update user properties.
   *
   * Permission: 'users-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser$Response(params: UpdateUser$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return updateUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Update user properties.
   *
   * Permission: 'users-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser(params: UpdateUser$Params, context?: HttpContext): Observable<UserDto> {
    return this.updateUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

  /** Path part for operation `unlockUser()` */
  static readonly UnlockUserPath = '/user/unlock/{username}';

  /**
   * Unlock user.
   *
   * Permission: 'users-edit-lock-status'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unlockUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  unlockUser$Response(params: UnlockUser$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return unlockUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Unlock user.
   *
   * Permission: 'users-edit-lock-status'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unlockUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unlockUser(params: UnlockUser$Params, context?: HttpContext): Observable<UserDto> {
    return this.unlockUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

  /** Path part for operation `updatePassword()` */
  static readonly UpdatePasswordPath = '/user/password';

  /**
   * Change password.
   *
   * Permission: 'users-edit-password'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePassword$Response(params: UpdatePassword$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updatePassword(this.http, this.rootUrl, params, context);
  }

  /**
   * Change password.
   *
   * Permission: 'users-edit-password'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePassword(params: UpdatePassword$Params, context?: HttpContext): Observable<void> {
    return this.updatePassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateOtherUserPassword()` */
  static readonly UpdateOtherUserPasswordPath = '/user/password/{username}';

  /**
   * Change password for another user.
   *
   * Permission: 'users-edit-other-user-password'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOtherUserPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOtherUserPassword$Response(params: UpdateOtherUserPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateOtherUserPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * Change password for another user.
   *
   * Permission: 'users-edit-other-user-password'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOtherUserPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOtherUserPassword(params: UpdateOtherUserPassword$Params, context?: HttpContext): Observable<void> {
    return this.updateOtherUserPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `lockUser()` */
  static readonly LockUserPath = '/user/lock/{username}';

  /**
   * Lock user.
   *
   * Permission: 'users-edit-lock-status'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lockUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  lockUser$Response(params: LockUser$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return lockUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Lock user.
   *
   * Permission: 'users-edit-lock-status'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lockUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lockUser(params: LockUser$Params, context?: HttpContext): Observable<UserDto> {
    return this.lockUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

  /** Path part for operation `createUser()` */
  static readonly CreateUserPath = '/user';

  /**
   * Create new user.
   *
   * Permission: 'users-add'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUser$Response(params: CreateUser$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return createUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new user.
   *
   * Permission: 'users-add'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUser(params: CreateUser$Params, context?: HttpContext): Observable<UserDto> {
    return this.createUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

  /** Path part for operation `getMe()` */
  static readonly GetMePath = '/user/me/{username}';

  /**
   * Users data for user from token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMe()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMe$Response(params: GetMe$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDetailsDto>> {
    return getMe(this.http, this.rootUrl, params, context);
  }

  /**
   * Users data for user from token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMe$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMe(params: GetMe$Params, context?: HttpContext): Observable<UserDetailsDto> {
    return this.getMe$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDetailsDto>): UserDetailsDto => r.body)
    );
  }

  /** Path part for operation `exists()` */
  static readonly ExistsPath = '/user/exists/{username}';

  /**
   * Is one user exists.
   *
   * Permission: 'users-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exists()` instead.
   *
   * This method doesn't expect any request body.
   */
  exists$Response(params: Exists$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return exists(this.http, this.rootUrl, params, context);
  }

  /**
   * Is one user exists.
   *
   * Permission: 'users-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exists$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exists(params: Exists$Params, context?: HttpContext): Observable<boolean> {
    return this.exists$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `getUsers()` */
  static readonly GetUsersPath = '/user/all/{agentId}/{userType}/{pageNumber}/{pageSize}';

  /**
   * Load users by filters.
   *
   * Permission: 'users-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers$Response(params: GetUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<PageDataUserDto>> {
    return getUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * Load users by filters.
   *
   * Permission: 'users-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers(params: GetUsers$Params, context?: HttpContext): Observable<PageDataUserDto> {
    return this.getUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageDataUserDto>): PageDataUserDto => r.body)
    );
  }

  /** Path part for operation `getUserByAgentId()` */
  static readonly GetUserByAgentIdPath = '/user/agent/{agentId}';

  /**
   * Get data for one user.
   *
   * Permission: 'users-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserByAgentId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserByAgentId$Response(params: GetUserByAgentId$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return getUserByAgentId(this.http, this.rootUrl, params, context);
  }

  /**
   * Get data for one user.
   *
   * Permission: 'users-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserByAgentId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserByAgentId(params: GetUserByAgentId$Params, context?: HttpContext): Observable<UserDto> {
    return this.getUserByAgentId$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

}
