import { SessionStorage } from './session-storage'
import { StorageUserSession } from './auth.types'
import { UserSession } from './user-session'

export class LocalSessionStorage implements SessionStorage {
    getUserSession(): UserSession {
        const item = localStorage.getItem(StorageUserSession.name)
        if (item) {
            try {
                return JSON.parse(item)
            } catch (e) {
                console.error(`Error while loading session from local storage`, e)
            }
        }
        return null
    }

    setUserSession(userSession: UserSession): void {
        localStorage.setItem(StorageUserSession.name, JSON.stringify(userSession))
    }

    clearUserSession(): void {
        localStorage.removeItem(StorageUserSession.name)
    }
}
