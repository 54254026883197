/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { getAllCounterDefinitions } from '../fn/perf/get-all-counter-definitions';
import { GetAllCounterDefinitions$Params } from '../fn/perf/get-all-counter-definitions';
import { getValues } from '../fn/perf/get-values';
import { GetValues$Params } from '../fn/perf/get-values';
import { PerfCounterCurrentWindowDto } from '../models/perf-counter-current-window-dto';
import { PerfCounterDefinitionDto } from '../models/perf-counter-definition-dto';

@Injectable({ providedIn: 'root' })
export class PerfMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getValues()` */
  static readonly GetValuesPath = '/perf/values';

  /**
   * Get list of values for a given list of counters.
   *
   * Permission: 'perf'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getValues()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getValues$Response(params: GetValues$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PerfCounterCurrentWindowDto>>> {
    return getValues(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of values for a given list of counters.
   *
   * Permission: 'perf'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getValues$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getValues(params: GetValues$Params, context?: HttpContext): Observable<Array<PerfCounterCurrentWindowDto>> {
    return this.getValues$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PerfCounterCurrentWindowDto>>): Array<PerfCounterCurrentWindowDto> => r.body)
    );
  }

  /** Path part for operation `getAllCounterDefinitions()` */
  static readonly GetAllCounterDefinitionsPath = '/perf/counters';

  /**
   * Get list of counters.
   *
   * Permission: 'perf'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllCounterDefinitions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCounterDefinitions$Response(params?: GetAllCounterDefinitions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PerfCounterDefinitionDto>>> {
    return getAllCounterDefinitions(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of counters.
   *
   * Permission: 'perf'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllCounterDefinitions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCounterDefinitions(params?: GetAllCounterDefinitions$Params, context?: HttpContext): Observable<Array<PerfCounterDefinitionDto>> {
    return this.getAllCounterDefinitions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PerfCounterDefinitionDto>>): Array<PerfCounterDefinitionDto> => r.body)
    );
  }

}
