/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { AgentDto } from '../models/agent-dto';
import { AgentTreeDto } from '../models/agent-tree-dto';
import { createAgent } from '../fn/agent/create-agent';
import { CreateAgent$Params } from '../fn/agent/create-agent';
import { getAgent } from '../fn/agent/get-agent';
import { GetAgent$Params } from '../fn/agent/get-agent';
import { getAgentStructureAsTree } from '../fn/agent/get-agent-structure-as-tree';
import { GetAgentStructureAsTree$Params } from '../fn/agent/get-agent-structure-as-tree';
import { getOperations } from '../fn/agent/get-operations';
import { GetOperations$Params } from '../fn/agent/get-operations';
import { hideAgent } from '../fn/agent/hide-agent';
import { HideAgent$Params } from '../fn/agent/hide-agent';
import { lockAgent } from '../fn/agent/lock-agent';
import { LockAgent$Params } from '../fn/agent/lock-agent';
import { unhideAgent } from '../fn/agent/unhide-agent';
import { UnhideAgent$Params } from '../fn/agent/unhide-agent';
import { unlockAgent } from '../fn/agent/unlock-agent';
import { UnlockAgent$Params } from '../fn/agent/unlock-agent';
import { updateAgent } from '../fn/agent/update-agent';
import { UpdateAgent$Params } from '../fn/agent/update-agent';

@Injectable({ providedIn: 'root' })
export class AgentMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAgent()` */
  static readonly GetAgentPath = '/agent/{agentId}';

  /**
   * Get data for one agent.
   *
   * Permission: 'agents-info'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAgent()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgent$Response(params: GetAgent$Params, context?: HttpContext): Observable<StrictHttpResponse<AgentDto>> {
    return getAgent(this.http, this.rootUrl, params, context);
  }

  /**
   * Get data for one agent.
   *
   * Permission: 'agents-info'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAgent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgent(params: GetAgent$Params, context?: HttpContext): Observable<AgentDto> {
    return this.getAgent$Response(params, context).pipe(
      map((r: StrictHttpResponse<AgentDto>): AgentDto => r.body)
    );
  }

  /** Path part for operation `updateAgent()` */
  static readonly UpdateAgentPath = '/agent/{agentId}';

  /**
   * Update agent property.
   *
   * Permission: 'agents-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAgent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAgent$Response(params: UpdateAgent$Params, context?: HttpContext): Observable<StrictHttpResponse<AgentDto>> {
    return updateAgent(this.http, this.rootUrl, params, context);
  }

  /**
   * Update agent property.
   *
   * Permission: 'agents-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAgent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAgent(params: UpdateAgent$Params, context?: HttpContext): Observable<AgentDto> {
    return this.updateAgent$Response(params, context).pipe(
      map((r: StrictHttpResponse<AgentDto>): AgentDto => r.body)
    );
  }

  /** Path part for operation `unlockAgent()` */
  static readonly UnlockAgentPath = '/agent/unlock/{agentId}';

  /**
   * Unlock agent.
   *
   * Permission: 'agents-edit-lock-status'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unlockAgent()` instead.
   *
   * This method doesn't expect any request body.
   */
  unlockAgent$Response(params: UnlockAgent$Params, context?: HttpContext): Observable<StrictHttpResponse<AgentDto>> {
    return unlockAgent(this.http, this.rootUrl, params, context);
  }

  /**
   * Unlock agent.
   *
   * Permission: 'agents-edit-lock-status'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unlockAgent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unlockAgent(params: UnlockAgent$Params, context?: HttpContext): Observable<AgentDto> {
    return this.unlockAgent$Response(params, context).pipe(
      map((r: StrictHttpResponse<AgentDto>): AgentDto => r.body)
    );
  }

  /** Path part for operation `unhideAgent()` */
  static readonly UnhideAgentPath = '/agent/unhide/{agentId}';

  /**
   * Unhide agent.
   *
   * Permission: 'agents-edit-hidden-status'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unhideAgent()` instead.
   *
   * This method doesn't expect any request body.
   */
  unhideAgent$Response(params: UnhideAgent$Params, context?: HttpContext): Observable<StrictHttpResponse<AgentDto>> {
    return unhideAgent(this.http, this.rootUrl, params, context);
  }

  /**
   * Unhide agent.
   *
   * Permission: 'agents-edit-hidden-status'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unhideAgent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unhideAgent(params: UnhideAgent$Params, context?: HttpContext): Observable<AgentDto> {
    return this.unhideAgent$Response(params, context).pipe(
      map((r: StrictHttpResponse<AgentDto>): AgentDto => r.body)
    );
  }

  /** Path part for operation `lockAgent()` */
  static readonly LockAgentPath = '/agent/lock/{agentId}';

  /**
   * Lock agent.
   *
   * Permission: 'agents-edit-lock-status'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lockAgent()` instead.
   *
   * This method doesn't expect any request body.
   */
  lockAgent$Response(params: LockAgent$Params, context?: HttpContext): Observable<StrictHttpResponse<AgentDto>> {
    return lockAgent(this.http, this.rootUrl, params, context);
  }

  /**
   * Lock agent.
   *
   * Permission: 'agents-edit-lock-status'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lockAgent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lockAgent(params: LockAgent$Params, context?: HttpContext): Observable<AgentDto> {
    return this.lockAgent$Response(params, context).pipe(
      map((r: StrictHttpResponse<AgentDto>): AgentDto => r.body)
    );
  }

  /** Path part for operation `hideAgent()` */
  static readonly HideAgentPath = '/agent/hide/{agentId}';

  /**
   * Hide agent.
   *
   * Permission: 'agents-edit-hidden-status'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hideAgent()` instead.
   *
   * This method doesn't expect any request body.
   */
  hideAgent$Response(params: HideAgent$Params, context?: HttpContext): Observable<StrictHttpResponse<AgentDto>> {
    return hideAgent(this.http, this.rootUrl, params, context);
  }

  /**
   * Hide agent.
   *
   * Permission: 'agents-edit-hidden-status'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hideAgent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hideAgent(params: HideAgent$Params, context?: HttpContext): Observable<AgentDto> {
    return this.hideAgent$Response(params, context).pipe(
      map((r: StrictHttpResponse<AgentDto>): AgentDto => r.body)
    );
  }

  /** Path part for operation `createAgent()` */
  static readonly CreateAgentPath = '/agent';

  /**
   * Create new agent.
   *
   * Permission: 'agents-add'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAgent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAgent$Response(params: CreateAgent$Params, context?: HttpContext): Observable<StrictHttpResponse<AgentTreeDto>> {
    return createAgent(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new agent.
   *
   * Permission: 'agents-add'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createAgent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAgent(params: CreateAgent$Params, context?: HttpContext): Observable<AgentTreeDto> {
    return this.createAgent$Response(params, context).pipe(
      map((r: StrictHttpResponse<AgentTreeDto>): AgentTreeDto => r.body)
    );
  }

  /** Path part for operation `getAgentStructureAsTree()` */
  static readonly GetAgentStructureAsTreePath = '/agent/all/tree';

  /**
   * Get agents tree structure.
   *
   * Permission: 'agents-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAgentStructureAsTree()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgentStructureAsTree$Response(params?: GetAgentStructureAsTree$Params, context?: HttpContext): Observable<StrictHttpResponse<AgentTreeDto>> {
    return getAgentStructureAsTree(this.http, this.rootUrl, params, context);
  }

  /**
   * Get agents tree structure.
   *
   * Permission: 'agents-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAgentStructureAsTree$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgentStructureAsTree(params?: GetAgentStructureAsTree$Params, context?: HttpContext): Observable<AgentTreeDto> {
    return this.getAgentStructureAsTree$Response(params, context).pipe(
      map((r: StrictHttpResponse<AgentTreeDto>): AgentTreeDto => r.body)
    );
  }

  /** Path part for operation `getOperations()` */
  static readonly GetOperationsPath = '/agent/all/operation';

  /**
   * Get list of agents.
   *
   * Permission: 'agents-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOperations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOperations$Response(params?: GetOperations$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AgentDto>>> {
    return getOperations(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of agents.
   *
   * Permission: 'agents-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOperations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOperations(params?: GetOperations$Params, context?: HttpContext): Observable<Array<AgentDto>> {
    return this.getOperations$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AgentDto>>): Array<AgentDto> => r.body)
    );
  }

}
