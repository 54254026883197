import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AppRoutingModule } from './app-routing.module'
import { PrimeNgModule } from './primeng.module'
import { MwApiModule } from './api/mw-api/mw-api.module'
import { TranslocoRootModule } from './transloco/transloco-root.module'

import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http'
import { ErrorInterceptor } from './security/error.interceptor'

import { AppComponent } from './app.component'
import { LoginComponent } from './components/login/login.component'
import { ChangePasswordComponent } from './components/change-password/change-password.component'
import { SelectLangComponent } from './components/select-lang/select-lang.component'
import { ManualApproveDialogComponent } from './components/ext-record-sync/manual-approve-dialog/manual-approve-dialog.component'
import { ErrorComponent } from './components/error/error.component'

import { PermissionsTreeComponent } from './components/permissions-tree/permissions-tree.component'

import { ApiErrorService } from './services/api-error.service'
import { AppConfigService } from './services/appconfig.service'

// import { BetAgentDialogComponent } from './components/betslips-overview/bet-agent-dialog/bet-agent-dialog.component'
import { NavigationBarComponent } from './components/navigation-bar/navigation-bar.component'
import { MainMenuLinksService } from './services/main-menu-links.service'
import { OperationStatsDialogComponent } from './components/sb-media/sb-media-settings/operation-stats-dialog/operation-stats-dialog.component'
import { PageAddOrEditComponent } from './components/sb-media/sb-media-settings/page-add-or-edit/page-add-or-edit.component'
import { TitleStrategy } from '@angular/router'
import { CustomTitleStrategyService } from './services/custom-title-strategy.service'
import { AppSharedModule } from './app.shared.module'
import { HandsetService } from './services/handset.service'
import { OperationStatsDetailedDialogComponent } from './components/sb-media/sb-media-settings/operation-stats-detailed-dialog/operation-stats-detailed-dialog.component'
import { SocketClient } from './lib/web-socket/socket-client'
import { SocketMessenger } from './lib/web-socket/socket-messenger'
import { AuthService } from './session/auth.service'
import { SessionInterceptor } from './session/session-interceptor'
import { SessionStorageService } from './session/session-storage-service'
import { WebSessionService } from './session/web-session-service'

@NgModule({
    declarations: [
        AppComponent,
        ChangePasswordComponent,
        LoginComponent,
        SelectLangComponent,
        //ExtRecordSyncComponent,
        //ExtRecordSyncFiltersComponent,
        ManualApproveDialogComponent,
        ErrorComponent,
        // UsersComponent,
        // UsersFiltersComponent,
        // UsersEditDialogComponent,
        PermissionsTreeComponent,

        NavigationBarComponent,
        OperationStatsDialogComponent,
        OperationStatsDetailedDialogComponent,

        PageAddOrEditComponent
    ],
    // entryComponents: [
    //   BetAgentDialogComponent
    // ],
    imports: [
        AppSharedModule,
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        PrimeNgModule,
        TranslocoRootModule,
        MwApiModule.forRoot({ rootUrl: `/api/bo` })
    ],
    providers: [
        HttpClient,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SessionInterceptor,
            multi: true
        },
        { provide: TitleStrategy, useClass: CustomTitleStrategyService },
        AppConfigService,
        MainMenuLinksService,
        HandsetService,
        AuthService,
        SessionStorageService,
        ApiErrorService,
        SocketClient,
        SocketMessenger,
        WebSessionService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
