import { Injectable } from '@angular/core'
import { MessageService } from 'primeng/api'
import { TranslocoService } from '@ngneat/transloco'

@Injectable({
    providedIn: 'root'
})
export class ToasterService {
    constructor(
        private messageService: MessageService,
        private transloco: TranslocoService
    ) {}

    public success(message: string) {
        this.messageService.add({
            severity: 'success',
            closable: true,
            summary: this.transloco.translate('SUCCESS'),
            detail: message
        })
    }

    public error(message: string) {
        this.messageService.add({
            severity: 'error',
            sticky: true,
            closable: true,
            summary: this.transloco.translate('ERROR'),
            detail: message
        })
    }
}
