/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { addCategory } from '../fn/bof-entity-category/add-category';
import { AddCategory$Params } from '../fn/bof-entity-category/add-category';
import { CategoryDto } from '../models/category-dto';
import { CategoryTranslationDto } from '../models/category-translation-dto';
import { CategoryWithTranslationDto } from '../models/category-with-translation-dto';
import { deleteCategory } from '../fn/bof-entity-category/delete-category';
import { DeleteCategory$Params } from '../fn/bof-entity-category/delete-category';
import { getCategoriesWithTranslation } from '../fn/bof-entity-category/get-categories-with-translation';
import { GetCategoriesWithTranslation$Params } from '../fn/bof-entity-category/get-categories-with-translation';
import { getCategoriesWithTranslationWithPaging } from '../fn/bof-entity-category/get-categories-with-translation-with-paging';
import { GetCategoriesWithTranslationWithPaging$Params } from '../fn/bof-entity-category/get-categories-with-translation-with-paging';
import { getCategory } from '../fn/bof-entity-category/get-category';
import { GetCategory$Params } from '../fn/bof-entity-category/get-category';
import { getCategoryAllLanguages } from '../fn/bof-entity-category/get-category-all-languages';
import { GetCategoryAllLanguages$Params } from '../fn/bof-entity-category/get-category-all-languages';
import { getCategoryWithTranslation } from '../fn/bof-entity-category/get-category-with-translation';
import { GetCategoryWithTranslation$Params } from '../fn/bof-entity-category/get-category-with-translation';
import { PageDataCategoryWithTranslationDto } from '../models/page-data-category-with-translation-dto';
import { updateCategory } from '../fn/bof-entity-category/update-category';
import { UpdateCategory$Params } from '../fn/bof-entity-category/update-category';
import { updateCategoryTranslations } from '../fn/bof-entity-category/update-category-translations';
import { UpdateCategoryTranslations$Params } from '../fn/bof-entity-category/update-category-translations';

@Injectable({ providedIn: 'root' })
export class BofEntityCategoryMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateCategory()` */
  static readonly UpdateCategoryPath = '/bof-entity/category';

  /**
   * Update category.
   *
   * Permission: 'bof-settings-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCategory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCategory$Response(params: UpdateCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<CategoryDto>> {
    return updateCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * Update category.
   *
   * Permission: 'bof-settings-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCategory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCategory(params: UpdateCategory$Params, context?: HttpContext): Observable<CategoryDto> {
    return this.updateCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<CategoryDto>): CategoryDto => r.body)
    );
  }

  /** Path part for operation `addCategory()` */
  static readonly AddCategoryPath = '/bof-entity/category';

  /**
   * Add category.
   *
   * Permission: 'bof-settings-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCategory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addCategory$Response(params: AddCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<CategoryDto>> {
    return addCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * Add category.
   *
   * Permission: 'bof-settings-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addCategory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addCategory(params: AddCategory$Params, context?: HttpContext): Observable<CategoryDto> {
    return this.addCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<CategoryDto>): CategoryDto => r.body)
    );
  }

  /** Path part for operation `updateCategoryTranslations()` */
  static readonly UpdateCategoryTranslationsPath = '/bof-entity/category/translations';

  /**
   * Update category translations.
   *
   * Permission: 'bof-settings-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCategoryTranslations()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCategoryTranslations$Response(params: UpdateCategoryTranslations$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CategoryTranslationDto>>> {
    return updateCategoryTranslations(this.http, this.rootUrl, params, context);
  }

  /**
   * Update category translations.
   *
   * Permission: 'bof-settings-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCategoryTranslations$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCategoryTranslations(params: UpdateCategoryTranslations$Params, context?: HttpContext): Observable<Array<CategoryTranslationDto>> {
    return this.updateCategoryTranslations$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CategoryTranslationDto>>): Array<CategoryTranslationDto> => r.body)
    );
  }

  /** Path part for operation `getCategory()` */
  static readonly GetCategoryPath = '/bof-entity/category/{categoryId}';

  /**
   * Get one category.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCategory$Response(params: GetCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<CategoryDto>> {
    return getCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * Get one category.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCategory(params: GetCategory$Params, context?: HttpContext): Observable<CategoryDto> {
    return this.getCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<CategoryDto>): CategoryDto => r.body)
    );
  }

  /** Path part for operation `deleteCategory()` */
  static readonly DeleteCategoryPath = '/bof-entity/category/{categoryId}';

  /**
   * Delete category.
   *
   * Permission: 'bof-settings-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCategory$Response(params: DeleteCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete category.
   *
   * Permission: 'bof-settings-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCategory(params: DeleteCategory$Params, context?: HttpContext): Observable<void> {
    return this.deleteCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getCategoryWithTranslation()` */
  static readonly GetCategoryWithTranslationPath = '/bof-entity/category/with-translation/{languageId}/{categoryId}';

  /**
   * Get one category with translation.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCategoryWithTranslation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCategoryWithTranslation$Response(params: GetCategoryWithTranslation$Params, context?: HttpContext): Observable<StrictHttpResponse<CategoryWithTranslationDto>> {
    return getCategoryWithTranslation(this.http, this.rootUrl, params, context);
  }

  /**
   * Get one category with translation.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCategoryWithTranslation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCategoryWithTranslation(params: GetCategoryWithTranslation$Params, context?: HttpContext): Observable<CategoryWithTranslationDto> {
    return this.getCategoryWithTranslation$Response(params, context).pipe(
      map((r: StrictHttpResponse<CategoryWithTranslationDto>): CategoryWithTranslationDto => r.body)
    );
  }

  /** Path part for operation `getCategoriesWithTranslation()` */
  static readonly GetCategoriesWithTranslationPath = '/bof-entity/category/all/with-translation/all/{languageId}/{sportId}';

  /**
   * Get list of categories.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCategoriesWithTranslation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCategoriesWithTranslation$Response(params: GetCategoriesWithTranslation$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CategoryWithTranslationDto>>> {
    return getCategoriesWithTranslation(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of categories.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCategoriesWithTranslation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCategoriesWithTranslation(params: GetCategoriesWithTranslation$Params, context?: HttpContext): Observable<Array<CategoryWithTranslationDto>> {
    return this.getCategoriesWithTranslation$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CategoryWithTranslationDto>>): Array<CategoryWithTranslationDto> => r.body)
    );
  }

  /** Path part for operation `getCategoriesWithTranslationWithPaging()` */
  static readonly GetCategoriesWithTranslationWithPagingPath = '/bof-entity/category/all/paging/{languageId}/{sportId}';

  /**
   * Get list of categories, with paging.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCategoriesWithTranslationWithPaging()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCategoriesWithTranslationWithPaging$Response(params: GetCategoriesWithTranslationWithPaging$Params, context?: HttpContext): Observable<StrictHttpResponse<PageDataCategoryWithTranslationDto>> {
    return getCategoriesWithTranslationWithPaging(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of categories, with paging.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCategoriesWithTranslationWithPaging$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCategoriesWithTranslationWithPaging(params: GetCategoriesWithTranslationWithPaging$Params, context?: HttpContext): Observable<PageDataCategoryWithTranslationDto> {
    return this.getCategoriesWithTranslationWithPaging$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageDataCategoryWithTranslationDto>): PageDataCategoryWithTranslationDto => r.body)
    );
  }

  /** Path part for operation `getCategoryAllLanguages()` */
  static readonly GetCategoryAllLanguagesPath = '/bof-entity/category/all-languages/{categoryId}';

  /**
   * Get one category: all languages.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCategoryAllLanguages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCategoryAllLanguages$Response(params: GetCategoryAllLanguages$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CategoryTranslationDto>>> {
    return getCategoryAllLanguages(this.http, this.rootUrl, params, context);
  }

  /**
   * Get one category: all languages.
   *
   * Permission: 'bof-settings-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCategoryAllLanguages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCategoryAllLanguages(params: GetCategoryAllLanguages$Params, context?: HttpContext): Observable<Array<CategoryTranslationDto>> {
    return this.getCategoryAllLanguages$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CategoryTranslationDto>>): Array<CategoryTranslationDto> => r.body)
    );
  }

}
