/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { getAgentPermissionsAsTree } from '../fn/permission/get-agent-permissions-as-tree';
import { GetAgentPermissionsAsTree$Params } from '../fn/permission/get-agent-permissions-as-tree';
import { getUserPermissionsAsTree } from '../fn/permission/get-user-permissions-as-tree';
import { GetUserPermissionsAsTree$Params } from '../fn/permission/get-user-permissions-as-tree';
import { PermissionsTreeDto } from '../models/permissions-tree-dto';
import { updateAgentPermissions } from '../fn/permission/update-agent-permissions';
import { UpdateAgentPermissions$Params } from '../fn/permission/update-agent-permissions';
import { updateUserPermissions } from '../fn/permission/update-user-permissions';
import { UpdateUserPermissions$Params } from '../fn/permission/update-user-permissions';

@Injectable({ providedIn: 'root' })
export class PermissionMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateUserPermissions()` */
  static readonly UpdateUserPermissionsPath = '/permission/user/{username}';

  /**
   * Update all permissions for one user as tree.
   *
   * Permission: 'users-edit-permissions'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserPermissions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserPermissions$Response(params: UpdateUserPermissions$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateUserPermissions(this.http, this.rootUrl, params, context);
  }

  /**
   * Update all permissions for one user as tree.
   *
   * Permission: 'users-edit-permissions'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUserPermissions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserPermissions(params: UpdateUserPermissions$Params, context?: HttpContext): Observable<void> {
    return this.updateUserPermissions$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateAgentPermissions()` */
  static readonly UpdateAgentPermissionsPath = '/permission/agent/{agentId}';

  /**
   * Update all permissions for one agent as tree.
   *
   * Permission: 'agents-edit-permissions'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAgentPermissions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAgentPermissions$Response(params: UpdateAgentPermissions$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateAgentPermissions(this.http, this.rootUrl, params, context);
  }

  /**
   * Update all permissions for one agent as tree.
   *
   * Permission: 'agents-edit-permissions'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAgentPermissions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAgentPermissions(params: UpdateAgentPermissions$Params, context?: HttpContext): Observable<void> {
    return this.updateAgentPermissions$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getUserPermissionsAsTree()` */
  static readonly GetUserPermissionsAsTreePath = '/permission/user/all/tree/{username}';

  /**
   * Get all permissions for one user as tree.
   *
   * Permission: 'users-edit-permissions'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserPermissionsAsTree()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserPermissionsAsTree$Response(params: GetUserPermissionsAsTree$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PermissionsTreeDto>>> {
    return getUserPermissionsAsTree(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all permissions for one user as tree.
   *
   * Permission: 'users-edit-permissions'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserPermissionsAsTree$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserPermissionsAsTree(params: GetUserPermissionsAsTree$Params, context?: HttpContext): Observable<Array<PermissionsTreeDto>> {
    return this.getUserPermissionsAsTree$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PermissionsTreeDto>>): Array<PermissionsTreeDto> => r.body)
    );
  }

  /** Path part for operation `getAgentPermissionsAsTree()` */
  static readonly GetAgentPermissionsAsTreePath = '/permission/agent/all/tree/{agentId}';

  /**
   * Get all permissions for one agent as tree.
   *
   * Permission: 'agents-edit-permissions'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAgentPermissionsAsTree()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgentPermissionsAsTree$Response(params: GetAgentPermissionsAsTree$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PermissionsTreeDto>>> {
    return getAgentPermissionsAsTree(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all permissions for one agent as tree.
   *
   * Permission: 'agents-edit-permissions'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAgentPermissionsAsTree$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgentPermissionsAsTree(params: GetAgentPermissionsAsTree$Params, context?: HttpContext): Observable<Array<PermissionsTreeDto>> {
    return this.getAgentPermissionsAsTree$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PermissionsTreeDto>>): Array<PermissionsTreeDto> => r.body)
    );
  }

}
