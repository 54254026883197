/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteImageById } from '../fn/sbmedia-images/delete-image-by-id';
import { DeleteImageById$Params } from '../fn/sbmedia-images/delete-image-by-id';
import { deleteImageByIdForUser } from '../fn/sbmedia-images/delete-image-by-id-for-user';
import { DeleteImageByIdForUser$Params } from '../fn/sbmedia-images/delete-image-by-id-for-user';
import { deleteImageByName } from '../fn/sbmedia-images/delete-image-by-name';
import { DeleteImageByName$Params } from '../fn/sbmedia-images/delete-image-by-name';
import { downloadImageById } from '../fn/sbmedia-images/download-image-by-id';
import { DownloadImageById$Params } from '../fn/sbmedia-images/download-image-by-id';
import { downloadImageByName } from '../fn/sbmedia-images/download-image-by-name';
import { DownloadImageByName$Params } from '../fn/sbmedia-images/download-image-by-name';
import { getImagesList } from '../fn/sbmedia-images/get-images-list';
import { GetImagesList$Params } from '../fn/sbmedia-images/get-images-list';
import { ImageInfoDto } from '../models/image-info-dto';
import { uploadImage } from '../fn/sbmedia-images/upload-image';
import { UploadImage$Params } from '../fn/sbmedia-images/upload-image';

@Injectable({ providedIn: 'root' })
export class SbmediaImagesMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `uploadImage()` */
  static readonly UploadImagePath = '/sb-media/images/{operationId}';

  /**
   * Upload selected image.
   *
   * Permission: 'sb-media-edit-pages'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadImage()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadImage$Response(params: UploadImage$Params, context?: HttpContext): Observable<StrictHttpResponse<ImageInfoDto>> {
    return uploadImage(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload selected image.
   *
   * Permission: 'sb-media-edit-pages'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadImage$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadImage(params: UploadImage$Params, context?: HttpContext): Observable<ImageInfoDto> {
    return this.uploadImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<ImageInfoDto>): ImageInfoDto => r.body)
    );
  }

  /** Path part for operation `getImagesList()` */
  static readonly GetImagesListPath = '/sb-media/images';

  /**
   * List of uploaded images.
   *
   * Permission: 'sb-media-edit-pages'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getImagesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImagesList$Response(params?: GetImagesList$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ImageInfoDto>>> {
    return getImagesList(this.http, this.rootUrl, params, context);
  }

  /**
   * List of uploaded images.
   *
   * Permission: 'sb-media-edit-pages'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getImagesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImagesList(params?: GetImagesList$Params, context?: HttpContext): Observable<Array<ImageInfoDto>> {
    return this.getImagesList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ImageInfoDto>>): Array<ImageInfoDto> => r.body)
    );
  }

  /** Path part for operation `downloadImageByName()` */
  static readonly DownloadImageByNamePath = '/sb-media/images/{operationId}/name/{imgName}';

  /**
   * Download image by image name.
   *
   * Permission: 'sb-media-edit-pages'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadImageByName()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadImageByName$Response(params: DownloadImageByName$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return downloadImageByName(this.http, this.rootUrl, params, context);
  }

  /**
   * Download image by image name.
   *
   * Permission: 'sb-media-edit-pages'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadImageByName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadImageByName(params: DownloadImageByName$Params, context?: HttpContext): Observable<{
}> {
    return this.downloadImageByName$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `downloadImageById()` */
  static readonly DownloadImageByIdPath = '/sb-media/images/{operationId}/id/{imgId}';

  /**
   * Download image by image id (uid).
   *
   * Permission: 'sb-media-edit-pages'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadImageById()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadImageById$Response(params: DownloadImageById$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return downloadImageById(this.http, this.rootUrl, params, context);
  }

  /**
   * Download image by image id (uid).
   *
   * Permission: 'sb-media-edit-pages'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadImageById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadImageById(params: DownloadImageById$Params, context?: HttpContext): Observable<{
}> {
    return this.downloadImageById$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `deleteImageById()` */
  static readonly DeleteImageByIdPath = '/sb-media/images/{operationId}/id/{imgId}';

  /**
   * Delete image by image id.
   *
   * Permission: 'sb-media-edit-pages'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteImageById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteImageById$Response(params: DeleteImageById$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteImageById(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete image by image id.
   *
   * Permission: 'sb-media-edit-pages'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteImageById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteImageById(params: DeleteImageById$Params, context?: HttpContext): Observable<void> {
    return this.deleteImageById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteImageByIdForUser()` */
  static readonly DeleteImageByIdForUserPath = '/sb-media/images/{operationId}/id/{imgId}/{user}';

  /**
   * Delete image by image id.
   *
   * Permission: 'sb-media-edit-pages'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteImageByIdForUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteImageByIdForUser$Response(params: DeleteImageByIdForUser$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteImageByIdForUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete image by image id.
   *
   * Permission: 'sb-media-edit-pages'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteImageByIdForUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteImageByIdForUser(params: DeleteImageByIdForUser$Params, context?: HttpContext): Observable<void> {
    return this.deleteImageByIdForUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteImageByName()` */
  static readonly DeleteImageByNamePath = '/sb-media/images/images/{operationId}/name/{imgName}';

  /**
   * Delete image by image name.
   *
   * Permission: 'sb-media-edit-pages'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteImageByName()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteImageByName$Response(params: DeleteImageByName$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteImageByName(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete image by image name.
   *
   * Permission: 'sb-media-edit-pages'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteImageByName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteImageByName(params: DeleteImageByName$Params, context?: HttpContext): Observable<void> {
    return this.deleteImageByName$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
