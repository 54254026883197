import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { ErrorComponent } from './components/error/error.component'
import { LoginComponent } from './components/login/login.component'
import { PermissionsTreeComponent } from './components/permissions-tree/permissions-tree.component'
import { ChangePasswordComponent } from './components/change-password/change-password.component'
import { AuthGuard } from './security/auth.guard'
import { RoleGuard } from './security/role.guard'

const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent,
        title: 'LOGIN'
    },
    {
        path: 'agents',
        canActivate: [],
        loadChildren: () => import('./components/agents/agent-module').then(m => m.AgentModule),
        title: 'AGENT'
    },
    {
        path: '',
        redirectTo: 'overview',
        pathMatch: 'full',
        title: 'BET_OVERVIEW'
    },
    {
        path: 'permissions',
        canActivate: [AuthGuard, RoleGuard],
        component: PermissionsTreeComponent,
        title: 'PERMISSIONS'
    },
    // {
    //     path: 'recordSync',
    //     canActivate: [AuthGuard, RoleGuard],
    //     component: ExtRecordSyncComponent,
    //     title: 'RECORD_SYNC'
    // },
    // {
    //     path: 'players',
    //     // canActivate: [AuthGuard, RoleGuard],
    //     component: PlayersComponent,
    //     title: 'PLAYERS'
    // },
    {
        path: 'change-password',
        canActivate: [AuthGuard],
        component: ChangePasswordComponent,
        title: 'CHANGE_PASS'
    },
    {
        path: 'settings',
        canActivate: [],
        loadChildren: () => import('./components/settings/settings-module').then(m => m.SettingsModule),
        title: 'SETTINGS'
    },
    {
        path: 'bof',
        canActivate: [],
        loadChildren: () => import('./components/bof/bof-module').then(m => m.BofModule),
        title: 'BETTING_OFFER'
    },
    {
        path: 'ext-feed',
        canActivate: [],
        loadChildren: () => import('./components/ext-feed/ext-feed-module').then(m => m.ExtFeedModule),
        title: 'EXT_FEED'
    },
    {
        path: 'bof-templates',
        canActivate: [],
        loadChildren: () => import('./components/bof-templates/bof-tmpl-module').then(m => m.BofTmplModule),
        title: 'BOF_TEMPLATES'
    },
    {
        path: 'perf',
        canActivate: [],
        // canActivateChild: [(childRoute, state) => console.log(childRoute, state)],
        loadChildren: () => import('./components/perf/perf-module').then(m => m.PerfModule),
        title: 'PERFORMANCE'
    },
    {
        path: '403',
        component: ErrorComponent,
        title: 'ERROR'
    }
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
