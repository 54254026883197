/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { createWebGroup } from '../fn/web-group/create-web-group';
import { CreateWebGroup$Params } from '../fn/web-group/create-web-group';
import { deleteWebGroup } from '../fn/web-group/delete-web-group';
import { DeleteWebGroup$Params } from '../fn/web-group/delete-web-group';
import { getWebGroup } from '../fn/web-group/get-web-group';
import { GetWebGroup$Params } from '../fn/web-group/get-web-group';
import { getWebGroups } from '../fn/web-group/get-web-groups';
import { GetWebGroups$Params } from '../fn/web-group/get-web-groups';
import { updateWebGroup } from '../fn/web-group/update-web-group';
import { UpdateWebGroup$Params } from '../fn/web-group/update-web-group';
import { WebGroupDto } from '../models/web-group-dto';
import { WebGroupOverviewDto } from '../models/web-group-overview-dto';

@Injectable({ providedIn: 'root' })
export class WebGroupMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getWebGroup()` */
  static readonly GetWebGroupPath = '/web-group/{webGroupId}';

  /**
   * Get web group.
   *
   * Permission: 'web-sites'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWebGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWebGroup$Response(params: GetWebGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<WebGroupDto>> {
    return getWebGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * Get web group.
   *
   * Permission: 'web-sites'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWebGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWebGroup(params: GetWebGroup$Params, context?: HttpContext): Observable<WebGroupDto> {
    return this.getWebGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<WebGroupDto>): WebGroupDto => r.body)
    );
  }

  /** Path part for operation `updateWebGroup()` */
  static readonly UpdateWebGroupPath = '/web-group/{webGroupId}';

  /**
   * Update web group.
   *
   * Permission: 'web-sites'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateWebGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWebGroup$Response(params: UpdateWebGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<WebGroupDto>> {
    return updateWebGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * Update web group.
   *
   * Permission: 'web-sites'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateWebGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWebGroup(params: UpdateWebGroup$Params, context?: HttpContext): Observable<WebGroupDto> {
    return this.updateWebGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<WebGroupDto>): WebGroupDto => r.body)
    );
  }

  /** Path part for operation `deleteWebGroup()` */
  static readonly DeleteWebGroupPath = '/web-group/{webGroupId}';

  /**
   * Delete web group.
   *
   * Permission: 'web-sites'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteWebGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteWebGroup$Response(params: DeleteWebGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteWebGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete web group.
   *
   * Permission: 'web-sites'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteWebGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteWebGroup(params: DeleteWebGroup$Params, context?: HttpContext): Observable<void> {
    return this.deleteWebGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `createWebGroup()` */
  static readonly CreateWebGroupPath = '/web-group';

  /**
   * Create new web group.
   *
   * Permission: 'web-sites'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createWebGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createWebGroup$Response(params: CreateWebGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<WebGroupDto>> {
    return createWebGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new web group.
   *
   * Permission: 'web-sites'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createWebGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createWebGroup(params: CreateWebGroup$Params, context?: HttpContext): Observable<WebGroupDto> {
    return this.createWebGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<WebGroupDto>): WebGroupDto => r.body)
    );
  }

  /** Path part for operation `getWebGroups()` */
  static readonly GetWebGroupsPath = '/web-group/all';

  /**
   * Get list of all web groups.
   *
   * Permission: 'web-sites'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWebGroups()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWebGroups$Response(params?: GetWebGroups$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<WebGroupOverviewDto>>> {
    return getWebGroups(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of all web groups.
   *
   * Permission: 'web-sites'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWebGroups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWebGroups(params?: GetWebGroups$Params, context?: HttpContext): Observable<Array<WebGroupOverviewDto>> {
    return this.getWebGroups$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WebGroupOverviewDto>>): Array<WebGroupOverviewDto> => r.body)
    );
  }

}
