import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { SessionStorageService } from '../session/session-storage-service'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    // private refreshingInProgress = false
    // private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null)
    // za konstruktor private injector: Injector
    constructor(readonly router: Router,
                private sessionStorageService: SessionStorageService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError(err => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        this.sessionStorageService.clearUserSession()
                        this.router.navigate(['login'])
                    }
                }
                return throwError(() => err)
            })
            /*
            catchError((err: unknown, caught) => {
                if (err instanceof HttpErrorResponse && err.status === 401) {
                    return this.handle401Error(request, next)
                }
                return throwError(err)
            })
             */
        )
    }

    /*
    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.refreshingInProgress) {
            this.refreshingInProgress = true
            this.refreshTokenSubject.next(null)
            console.debug('Trying to refresh jwt tokens')
            // AuthService instancu uzimamo preko injector-a, jer ako se stavi u konstruktor
            // dobijamo cyclic dependency, jer AuthService koristi http klijenta
            const authService = this.injector.get(AuthService)
            return authService.refreshJwtTokens().pipe(
                switchMap(() => {
                    console.debug('Refresh of jwt tokens successful')
                    this.refreshingInProgress = false
                    this.refreshTokenSubject.next(authService.getAccessToken())
                    return next.handle(this.addToken(request, authService.getAccessToken()))
                })
            )
        } else {
            return this.refreshTokenSubject.pipe(
                filter(token => token),
                take(1),
                switchMap(token => next.handle(this.addToken(request, token)))
            )
        }
    }

    private addToken(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        })
    }
     */
}
