/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { addHighlightedMatchesTemplate } from '../fn/bof-tmpl-min-highlighted-matches/add-highlighted-matches-template';
import { AddHighlightedMatchesTemplate$Params } from '../fn/bof-tmpl-min-highlighted-matches/add-highlighted-matches-template';
import { BofTmplDto } from '../models/bof-tmpl-dto';
import { deleteHighlightedMatchesTemplate } from '../fn/bof-tmpl-min-highlighted-matches/delete-highlighted-matches-template';
import { DeleteHighlightedMatchesTemplate$Params } from '../fn/bof-tmpl-min-highlighted-matches/delete-highlighted-matches-template';
import { getHighlightedMatchesTemplates } from '../fn/bof-tmpl-min-highlighted-matches/get-highlighted-matches-templates';
import { GetHighlightedMatchesTemplates$Params } from '../fn/bof-tmpl-min-highlighted-matches/get-highlighted-matches-templates';
import { renameHighlightedMatchesTemplate } from '../fn/bof-tmpl-min-highlighted-matches/rename-highlighted-matches-template';
import { RenameHighlightedMatchesTemplate$Params } from '../fn/bof-tmpl-min-highlighted-matches/rename-highlighted-matches-template';

@Injectable({ providedIn: 'root' })
export class BofTmplMinHighlightedMatchesMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `renameHighlightedMatchesTemplate()` */
  static readonly RenameHighlightedMatchesTemplatePath = '/bof-tmpl/highlighted-matches';

  /**
   * Rename template.
   *
   * Permission: 'bof-tmpl-highlighted-matches-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `renameHighlightedMatchesTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  renameHighlightedMatchesTemplate$Response(params: RenameHighlightedMatchesTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<BofTmplDto>> {
    return renameHighlightedMatchesTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Rename template.
   *
   * Permission: 'bof-tmpl-highlighted-matches-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `renameHighlightedMatchesTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  renameHighlightedMatchesTemplate(params: RenameHighlightedMatchesTemplate$Params, context?: HttpContext): Observable<BofTmplDto> {
    return this.renameHighlightedMatchesTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<BofTmplDto>): BofTmplDto => r.body)
    );
  }

  /** Path part for operation `addHighlightedMatchesTemplate()` */
  static readonly AddHighlightedMatchesTemplatePath = '/bof-tmpl/highlighted-matches';

  /**
   * Add new template.
   *
   * Permission: 'bof-tmpl-highlighted-matches-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addHighlightedMatchesTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addHighlightedMatchesTemplate$Response(params: AddHighlightedMatchesTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<BofTmplDto>> {
    return addHighlightedMatchesTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Add new template.
   *
   * Permission: 'bof-tmpl-highlighted-matches-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addHighlightedMatchesTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addHighlightedMatchesTemplate(params: AddHighlightedMatchesTemplate$Params, context?: HttpContext): Observable<BofTmplDto> {
    return this.addHighlightedMatchesTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<BofTmplDto>): BofTmplDto => r.body)
    );
  }

  /** Path part for operation `getHighlightedMatchesTemplates()` */
  static readonly GetHighlightedMatchesTemplatesPath = '/bof-tmpl/highlighted-matches/all';

  /**
   * Get list of all templates.
   *
   * Permission: 'bof-tmpl-highlighted-matches-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHighlightedMatchesTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHighlightedMatchesTemplates$Response(params?: GetHighlightedMatchesTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BofTmplDto>>> {
    return getHighlightedMatchesTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of all templates.
   *
   * Permission: 'bof-tmpl-highlighted-matches-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getHighlightedMatchesTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHighlightedMatchesTemplates(params?: GetHighlightedMatchesTemplates$Params, context?: HttpContext): Observable<Array<BofTmplDto>> {
    return this.getHighlightedMatchesTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BofTmplDto>>): Array<BofTmplDto> => r.body)
    );
  }

  /** Path part for operation `deleteHighlightedMatchesTemplate()` */
  static readonly DeleteHighlightedMatchesTemplatePath = '/bof-tmpl/highlighted-matches/{id}';

  /**
   * Delete template.
   *
   * Permission: 'bof-tmpl-highlighted-matches-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteHighlightedMatchesTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHighlightedMatchesTemplate$Response(params: DeleteHighlightedMatchesTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteHighlightedMatchesTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete template.
   *
   * Permission: 'bof-tmpl-highlighted-matches-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteHighlightedMatchesTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHighlightedMatchesTemplate(params: DeleteHighlightedMatchesTemplate$Params, context?: HttpContext): Observable<void> {
    return this.deleteHighlightedMatchesTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
