import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { TranslocoService } from '@ngneat/transloco'
import { ApiErrorService } from 'src/app/services/api-error.service'
import { AuthenticationMwApiService } from 'src/app/api/mw-api/MwApiServices'
import { MessageService } from 'primeng/api'
import { MainMenuLinksService } from 'src/app/services/main-menu-links.service'
import { Subscription } from 'rxjs'
import { AuthService, LoginError } from '../../session/auth.service'

/**
 * Problem with autofill and focus for forms
 * https://github.com/angular/angular/issues/30616
 */

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    loading = false
    forgotPasswordMode = false
    loginAttempt = 0

    loginForm = new FormGroup({
        username: new FormControl<string>(null, [Validators.required]),
        password: new FormControl<string>(null, [Validators.required])
    })

    forgotPasswordForm = new FormGroup({
        username: new FormControl<string>(null, [Validators.required])
    })

    defaultLinkSubscription: Subscription

    constructor(
        readonly router: Router,
        private auth: AuthService,
        private apiErrorService: ApiErrorService,
        private messageService: MessageService,
        private anonymousService: AuthenticationMwApiService,
        private transloco: TranslocoService,
        private mainMenuLinksService: MainMenuLinksService
    ) {}

    ngOnInit() {
        // Prilikom uspesnog logovanja promena logovanog usera aktivira promenu prava pristupa
        // a promena prava okida preracun default stranice koja treba da se prikaze korisniku
        this.defaultLinkSubscription = this.mainMenuLinksService.defaultLink$.subscribe(data => {
            if (data !== null) {
                void this.router.navigate([data])
                this.defaultLinkSubscription.unsubscribe()
            }
        })
    }

    login() {
        this.loginAttempt++
        if (this.loginForm.value.username && this.loginForm.value.password) {
            this.loading = true

            this.auth.login(this.loginForm.value.username, this.loginForm.value.password).subscribe({
                next: () => {
                    // this.loading = false
                },
                error: error => {
                    this.loading = false
                    this.onLoginError(error)
                }
            })
        } else {
            this.loginForm.updateValueAndValidity()
            this.messageService.add({
                severity: 'warn',
                sticky: true,
                closable: true,
                summary: this.transloco.translate('WARN'),
                detail: this.transloco.translate('username_password_mandatory')
            })
        }
    }

    switchToForgotPassword() {
        this.messageService.add({
            severity: 'warn',
            sticky: true,
            closable: true,
            summary: this.transloco.translate('WARN'),
            detail: this.transloco.translate('contact_admin_for_password_reset')
        })
        // Kada se promeni workflow, onda vratiti prikaz panela za reset
        //this.forgotPasswordMode = !this.forgotPasswordMode
    }

    forgotPassword() {
        // TODO Potreban je nov workflow za password reset
        // this.loading = true
        //
        // const userInfo = {
        //     username: this.forgotPasswordForm.value.username
        // }
        //
        // this.anonymousService.forgotPassword({ body: userInfo }).subscribe({
        //     next: () => {
        //         // Successful reset password
        //         this.loading = false
        //         this.messageService.add({
        //             severity: 'success',
        //             closable: true,
        //             summary: this.transloco.translate('SUCCESS'),
        //             detail: this.transloco.translate('NEW_PASSWORD_SENT_TO_EMAIL')
        //         })
        //     },
        //     error: err => {
        //         this.loading = false
        //         this.messageService.add({
        //             severity: 'error',
        //             sticky: true,
        //             closable: true,
        //             summary: this.apiErrorService.parse(err).displayError,
        //             detail: this.transloco.translate('ERROR_CHANGE_PASSWORD')
        //         })
        //     }
        // })
    }

    private onLoginError(loginError: LoginError) {
        if (loginError) {
            this.messageService.add({
                severity: 'error',
                sticky: true,
                closable: true,
                summary: 'Error',
                detail: `${this.transloco.translate('LOGIN_ERROR')}: ${loginError.error}`
            })
        } else {
            // this.languageService.setActiveLangFromStoreOrSetDefault()
        }
    }
}
