/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { BofTmplsConfigDto } from '../models/bof-tmpls-config-dto';
import { getAgentConfig } from '../fn/agent-bof-tmpls-config/get-agent-config';
import { GetAgentConfig$Params } from '../fn/agent-bof-tmpls-config/get-agent-config';
import { updateAgentConfig } from '../fn/agent-bof-tmpls-config/update-agent-config';
import { UpdateAgentConfig$Params } from '../fn/agent-bof-tmpls-config/update-agent-config';

@Injectable({ providedIn: 'root' })
export class AgentBofTmplsConfigMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAgentConfig()` */
  static readonly GetAgentConfigPath = '/agent/bof-tmpls/config/{agentId}';

  /**
   * Get bof template config.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAgentConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgentConfig$Response(params: GetAgentConfig$Params, context?: HttpContext): Observable<StrictHttpResponse<BofTmplsConfigDto>> {
    return getAgentConfig(this.http, this.rootUrl, params, context);
  }

  /**
   * Get bof template config.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAgentConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgentConfig(params: GetAgentConfig$Params, context?: HttpContext): Observable<BofTmplsConfigDto> {
    return this.getAgentConfig$Response(params, context).pipe(
      map((r: StrictHttpResponse<BofTmplsConfigDto>): BofTmplsConfigDto => r.body)
    );
  }

  /** Path part for operation `updateAgentConfig()` */
  static readonly UpdateAgentConfigPath = '/agent/bof-tmpls/config/{agentId}';

  /**
   * Update agent bof template configs.
   *
   * Permission: 'bof-tmpl-sorting-edit-config'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAgentConfig()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAgentConfig$Response(params: UpdateAgentConfig$Params, context?: HttpContext): Observable<StrictHttpResponse<BofTmplsConfigDto>> {
    return updateAgentConfig(this.http, this.rootUrl, params, context);
  }

  /**
   * Update agent bof template configs.
   *
   * Permission: 'bof-tmpl-sorting-edit-config'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAgentConfig$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAgentConfig(params: UpdateAgentConfig$Params, context?: HttpContext): Observable<BofTmplsConfigDto> {
    return this.updateAgentConfig$Response(params, context).pipe(
      map((r: StrictHttpResponse<BofTmplsConfigDto>): BofTmplsConfigDto => r.body)
    );
  }

}
