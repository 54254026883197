/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { addLanguage } from '../fn/locale/add-language';
import { AddLanguage$Params } from '../fn/locale/add-language';
import { CountryDto } from '../models/country-dto';
import { getCountries } from '../fn/locale/get-countries';
import { GetCountries$Params } from '../fn/locale/get-countries';
import { getLanguages } from '../fn/locale/get-languages';
import { GetLanguages$Params } from '../fn/locale/get-languages';
import { getTimeZones } from '../fn/locale/get-time-zones';
import { GetTimeZones$Params } from '../fn/locale/get-time-zones';
import { LanguageDto } from '../models/language-dto';
import { LanguageSortIndexDto } from '../models/language-sort-index-dto';
import { TimeZoneDto } from '../models/time-zone-dto';
import { updateLanguage } from '../fn/locale/update-language';
import { UpdateLanguage$Params } from '../fn/locale/update-language';
import { updateLanguageSortIndex } from '../fn/locale/update-language-sort-index';
import { UpdateLanguageSortIndex$Params } from '../fn/locale/update-language-sort-index';

@Injectable({ providedIn: 'root' })
export class LocaleMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateLanguage()` */
  static readonly UpdateLanguagePath = '/locale/language';

  /**
   * Update language.
   *
   * Permission: 'language-edit''
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateLanguage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateLanguage$Response(params: UpdateLanguage$Params, context?: HttpContext): Observable<StrictHttpResponse<LanguageDto>> {
    return updateLanguage(this.http, this.rootUrl, params, context);
  }

  /**
   * Update language.
   *
   * Permission: 'language-edit''
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateLanguage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateLanguage(params: UpdateLanguage$Params, context?: HttpContext): Observable<LanguageDto> {
    return this.updateLanguage$Response(params, context).pipe(
      map((r: StrictHttpResponse<LanguageDto>): LanguageDto => r.body)
    );
  }

  /** Path part for operation `addLanguage()` */
  static readonly AddLanguagePath = '/locale/language';

  /**
   * Add new language.
   *
   * Permission: 'language-edit''
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addLanguage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addLanguage$Response(params: AddLanguage$Params, context?: HttpContext): Observable<StrictHttpResponse<LanguageDto>> {
    return addLanguage(this.http, this.rootUrl, params, context);
  }

  /**
   * Add new language.
   *
   * Permission: 'language-edit''
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addLanguage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addLanguage(params: AddLanguage$Params, context?: HttpContext): Observable<LanguageDto> {
    return this.addLanguage$Response(params, context).pipe(
      map((r: StrictHttpResponse<LanguageDto>): LanguageDto => r.body)
    );
  }

  /** Path part for operation `updateLanguageSortIndex()` */
  static readonly UpdateLanguageSortIndexPath = '/locale/language/sort/{languageId}/{moveUp}';

  /**
   * Update sort index.
   *
   * Permission: 'language-edit''
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateLanguageSortIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateLanguageSortIndex$Response(params: UpdateLanguageSortIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LanguageSortIndexDto>>> {
    return updateLanguageSortIndex(this.http, this.rootUrl, params, context);
  }

  /**
   * Update sort index.
   *
   * Permission: 'language-edit''
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateLanguageSortIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateLanguageSortIndex(params: UpdateLanguageSortIndex$Params, context?: HttpContext): Observable<Array<LanguageSortIndexDto>> {
    return this.updateLanguageSortIndex$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LanguageSortIndexDto>>): Array<LanguageSortIndexDto> => r.body)
    );
  }

  /** Path part for operation `getTimeZones()` */
  static readonly GetTimeZonesPath = '/locale/time-zone/all';

  /**
   * Get time zone list.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTimeZones()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimeZones$Response(params?: GetTimeZones$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TimeZoneDto>>> {
    return getTimeZones(this.http, this.rootUrl, params, context);
  }

  /**
   * Get time zone list.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTimeZones$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimeZones(params?: GetTimeZones$Params, context?: HttpContext): Observable<Array<TimeZoneDto>> {
    return this.getTimeZones$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TimeZoneDto>>): Array<TimeZoneDto> => r.body)
    );
  }

  /** Path part for operation `getLanguages()` */
  static readonly GetLanguagesPath = '/locale/language/all';

  /**
   * Get all supported languages.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLanguages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLanguages$Response(params?: GetLanguages$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LanguageDto>>> {
    return getLanguages(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all supported languages.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLanguages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLanguages(params?: GetLanguages$Params, context?: HttpContext): Observable<Array<LanguageDto>> {
    return this.getLanguages$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LanguageDto>>): Array<LanguageDto> => r.body)
    );
  }

  /** Path part for operation `getCountries()` */
  static readonly GetCountriesPath = '/locale/country/all';

  /**
   * Get countries list.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCountries()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountries$Response(params?: GetCountries$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CountryDto>>> {
    return getCountries(this.http, this.rootUrl, params, context);
  }

  /**
   * Get countries list.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCountries$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountries(params?: GetCountries$Params, context?: HttpContext): Observable<Array<CountryDto>> {
    return this.getCountries$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CountryDto>>): Array<CountryDto> => r.body)
    );
  }

}
