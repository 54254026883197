import { HttpClient, provideHttpClient } from '@angular/common/http'
import { provideTransloco, Translation, TranslocoLoader, TranslocoModule } from '@ngneat/transloco'
import { Injectable, NgModule } from '@angular/core'

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    constructor(private http: HttpClient) {
    }

    getTranslation(lang: string) {
        return this.http.get<Translation>(`/assets/i18n/${lang}.json`)
    }
}

@NgModule({
    exports: [TranslocoModule],
    providers: [
        provideHttpClient(),
        provideTransloco({
            config: {
                availableLangs: [
                    { id: 'en', label: 'English' },
                    { id: 'sr', label: 'Srpski' },
                    { id: 'es', label: 'Espagnol' }
                ],
                defaultLang: 'en',
                fallbackLang: 'en',
                reRenderOnLangChange: true
            },
            loader: TranslocoHttpLoader
        })
    ]
})
export class TranslocoRootModule {
}
