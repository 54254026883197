import { NgModule } from '@angular/core'
import { MenubarModule } from 'primeng/menubar'
import { MenuModule } from 'primeng/menu'
import { ToastModule } from 'primeng/toast'
import { ConfirmationService, MessageService } from 'primeng/api'
import { ButtonModule } from 'primeng/button'
import { DialogModule } from 'primeng/dialog'
import { InputTextModule } from 'primeng/inputtext'
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog'
import { PasswordModule } from 'primeng/password'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { AccordionModule } from 'primeng/accordion'
import { PanelModule } from 'primeng/panel'
import { ProgressBarModule } from 'primeng/progressbar'
import { TableModule } from 'primeng/table'
import { PaginatorModule } from 'primeng/paginator'
import { TreeModule } from 'primeng/tree'
import { CardModule } from 'primeng/card'
import { CalendarModule } from 'primeng/calendar'
import { DropdownModule } from 'primeng/dropdown'
import { DividerModule } from 'primeng/divider'
import { CheckboxModule } from 'primeng/checkbox'
import { ProgressSpinnerModule } from 'primeng/progressspinner'
import { InputTextareaModule } from 'primeng/inputtextarea'
import { TabViewModule } from 'primeng/tabview'
import { RadioButtonModule } from 'primeng/radiobutton'
import { SidebarModule } from 'primeng/sidebar'
import { InputSwitchModule } from 'primeng/inputswitch'
import { MultiSelectModule } from 'primeng/multiselect'
import { InplaceModule } from 'primeng/inplace'
import { TooltipModule } from 'primeng/tooltip'
import { ContextMenuModule } from 'primeng/contextmenu'
import { FileUploadModule } from 'primeng/fileupload'
import { InputNumberModule } from 'primeng/inputnumber'
import { TagModule } from 'primeng/tag'
import { SkeletonModule } from 'primeng/skeleton'
import { SelectButtonModule } from 'primeng/selectbutton'
import { BlockUIModule } from 'primeng/blockui'
import { RippleModule } from 'primeng/ripple'
import { MessageModule } from 'primeng/message'
import { TreeTableModule } from 'primeng/treetable'
import { InputGroupModule } from 'primeng/inputgroup'
import { InputGroupAddonModule } from 'primeng/inputgroupaddon'
import { AutoFocusModule } from 'primeng/autofocus'
import { AutoCompleteModule } from 'primeng/autocomplete'

@NgModule({
    exports: [
        AutoCompleteModule,
        RippleModule,
        MenuModule,
        MenubarModule,
        ToastModule,
        ButtonModule,
        DialogModule,
        InputTextModule,
        DynamicDialogModule,
        PasswordModule,
        ConfirmDialogModule,
        AccordionModule,
        PanelModule,
        ProgressBarModule,
        CheckboxModule,
        TableModule,
        PaginatorModule,
        TreeModule,
        CardModule,
        CalendarModule,
        DropdownModule,
        DividerModule,
        ProgressSpinnerModule,
        InputTextareaModule,
        TabViewModule,
        RadioButtonModule,
        SidebarModule,
        InputSwitchModule,
        MultiSelectModule,
        InplaceModule,
        TooltipModule,
        ContextMenuModule,
        FileUploadModule,
        InputNumberModule,
        SelectButtonModule,
        TagModule,
        SkeletonModule,
        BlockUIModule,
        MessageModule,
        TreeTableModule,
        InputGroupModule,
        InputGroupAddonModule,
        AutoFocusModule
    ],
    providers: [MessageService, DialogService, ConfirmationService]
})
export class PrimeNgModule {}
