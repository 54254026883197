/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { GroupTranslationDto } from '../../models/group-translation-dto';

export interface UpdateGroupTranslation$Params {
      body: Array<GroupTranslationDto>
}

export function updateGroupTranslation(http: HttpClient, rootUrl: string, params: UpdateGroupTranslation$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GroupTranslationDto>>> {
  const rb = new RequestBuilder(rootUrl, updateGroupTranslation.PATH, 'put');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<GroupTranslationDto>>;
    })
  );
}

updateGroupTranslation.PATH = '/market-def/group/translation';
